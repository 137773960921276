import { useQuery } from 'react-query'
import { getRequestedDetectGroupItems, getRequestedDetectItems } from '../../../../lib/api/QueryApis/Common'
import { getUseQueryOptions } from '../../Utils'
import React, { useEffect } from 'react'
import { Col, Empty, List, notification, Row, Spin } from 'antd'
import { KPICard } from '../../KPI/KPICard'
import './index.css'
import '../../index.css'
import { sendNotification } from '../../../../lib/utils/common'
import { CollectionsCard } from '../CollectionsDashboard/CollectionsCard'
import { ItemType } from '../../../../lib/api/RequestTypes/Common'

interface DetectModerateCollectionItemListProps {
    limit: number,
    offset: number,
    onCardClick: Function,
    refresh?: boolean
}

const DetectModerateCollectionItemList = (
    {
        limit,
        offset,
        onCardClick,
        refresh
    }: DetectModerateCollectionItemListProps) => {

    const requestedItemsQuery = useQuery([
            {
                limit: limit,
                offset: offset,
                groupType: ItemType.COLLECTION
            }],
        getRequestedDetectGroupItems,
        getUseQueryOptions(3)
    )

    useEffect(() => {
        if (requestedItemsQuery.isSuccess) {
            requestedItemsQuery.refetch()
        }
    }, [refresh])

    if (requestedItemsQuery.isSuccess) {
        if (requestedItemsQuery.data.length===0) {
            return (
                <div className='detect-full-center'>
                    <Empty description={<span>No Pending Requests</span>} />
                </div>)
        } else {
            return (
                <Row gutter={12}
                     style={{ overflowY: 'scroll', maxHeight: '650px' }} className='detect-list-scroll'>
                    {requestedItemsQuery.data.map((item, idx) => (
                        <Col key={idx} span={12}>
                            <CollectionsCard itemSummary={item} hideUpdatedAt={true} showItemStatus={true}
                                             onClickCallback={onCardClick} />
                        </Col>
                    ))}
                </Row>
            )
        }
    } else if (requestedItemsQuery.isError) {
        sendNotification(
            notification, 'error', 'Can\'t fetch items', ''
        )
        return (
            <div className='detect-full-center'>
                <Empty description={<span>Error Occurred. No Data</span>} />
            </div>
        )
    } else {
        return (
            <div className='detect-full-center'>
                <Spin tip='Loading' />
            </div>
        )
    }
}

export default DetectModerateCollectionItemList
