import { Cookies } from 'react-cookie'
import { Cookie } from './constants'
import {
    AppVersionError,
    AuthError,
    ClientError,
    NetworkError,
    ServerError,
    SessionError
} from './errors'
import { googleLogout } from '@react-oauth/google'

export const BASE_REQUEST: RequestInit = {
    mode: 'cors',
    cache: 'no-store'
}

type ParamFunc<P, R> = (params: P) => R

export interface NodeConfig<P, R> {
    // Given the input params, returns the path to request.
    path: ParamFunc<P, string> | string
    // Given the input params, returns the request config.
    request?: ParamFunc<P, RequestInit> | RequestInit
    // Transform response to something else.
    response?: (responseJson: any) => R
}

/**
 * Handles a network error.
 */
function handleNetworkError<R = Response>(error: Error): Promise<R> {
    throw new NetworkError(error)
}

/**
 * Handles various status codes.
 * @param response HTTP response object.
 */
export function handleStatusCode(response: Response): Response {
    //console.log(response)
    if (response.status >= 300) {
        if (
            response.status===401
            || response.status===403) {
            throw new AuthError(response)
        } else if (
            response.status >= 400 &&
            response.status < 500
        ) {
            throw new ClientError(response)
        } else {
            throw new ServerError(response)
        }
    }
    return response
}

/**
 * Parses the response body based on `Content-Type`.
 * @param response
 */
export function parseResponseBody(response: Response): any {
    const contentType = response.headers.get('Content-Type')
    if (contentType) {
        if (contentType.match(/^application\/json/)) {
            return response.json()
        } else if (contentType.match(/^text/)) {
            return response.text()
        } else {
            return response.blob()
        }
    } else {
        return response.blob()
    }
}

/**
 * Handles various application response errors.
 * @param response Parsed response object (not HTTP).
 */
export function handleApplicationError(response: any) {
    if (typeof response!=='object') {
        return response
    }
    if (!response.result__) {
        return response
    }
    switch (response.result__) {
        case 1:
            return response // ok
        case -1:
            throw new ServerError(response.error_msg__) // generic error
        case -2:
            throw new AppVersionError(response.error_msg__)
        case -10:
            throw new SessionError(response.error_msg__)
        case -11:
            throw new AuthError(response.error_msg__)
        case -20:
            throw new ClientError(response.error_msg__)
        default:
            throw new ServerError(response.error_msg__)
    }
}

export default class ApiClient {
    cookies: Cookies
    prefix: string

    constructor(prefix: string = '') {
        this.cookies = new Cookies()
        this.prefix = prefix
    }

    createRequest<P, R>(config: NodeConfig<P, R>) {
        return (params: P): Promise<R> => {
            try {
                const req = this.createRequestInner(config, params)
                return req()
                    .then(
                        (response) =>
                            this.handleInvalidCredentials<P, R>(
                                response,
                                config,
                                params
                            ),
                        handleNetworkError
                    )
                    .then(handleStatusCode)
                    .then(parseResponseBody)
                    .then(handleApplicationError)
                    .then(config.response || ((r) => r))
            } catch (e: any) {
                throw new ServerError(e)
            }
        }
    }

    createRequestInner<P, R>(
        config: NodeConfig<P, R>,
        params: P
    ): () => Promise<Response> {
        const { path, request } = config

        // Prefix is the protocol + host + path.
        const prefix = this.prefix
        // Construct URL.
        const url = prefix + (typeof path==='function' ? path(params):path)
        // Construct request.
        const clientRequest = request
            ? typeof request==='function'
                ? request(params)
                :request
            :{ headers: {} }

        const token = this.cookies.get(Cookie.id_token) || ''

        const NEXT_HEADER = localStorage.getItem(Cookie.next) || ''

        // This creates the whole request object.
        const finalRequest = {
            ...clientRequest,
            ...BASE_REQUEST,
            headers: {
                ...clientRequest.headers,
                ...(NEXT_HEADER.length > 0 ? { 'X-UD-Next': NEXT_HEADER }:{}),
                Authorization: `Bearer ${token}`
            }
        }
        return () => fetch(url, finalRequest)
    }

    async handleInvalidCredentials<P, R>(
        response: Response,
        config: NodeConfig<P, R>,
        params: P
    ) {
        if (response.status===401) {
            this.cookies.remove(Cookie.id_token)
            this.cookies.remove(Cookie.refresh_token) // Handle release gracefully
            googleLogout()
            window.location.href = '/login'
        }
        return response
    }
}
