import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Typography } from 'antd'
import { HomeOutlined } from '@ant-design/icons'

export default function NoMatch() {
    const history = useHistory()

    const { Title } = Typography

    const [height, setHeight] = React.useState(window.innerHeight)

    const updateHeight = () => {
        setHeight(window.innerHeight)
    }

    React.useEffect(() => {
        window.addEventListener('resize', updateHeight)
        return () => {
            window.removeEventListener('resize', updateHeight)
        }
    }, [])

    return (
        <>
            <Title level={3}>Oops! Page not found.</Title>
            <Title level={1}>404</Title>
            <Title level={5}>We can't find the page you're looking for</Title>
            <Button
                icon={(<HomeOutlined />)}
                type='primary'
                shape='round'
                onClick={() => {
                    history.push('/')
                }}
            >
                Go back home
            </Button>
        </>
    )
}
