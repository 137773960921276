import React, { memo, useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { DetectGroupItem } from '../../../../lib/api/RequestTypes/Common'
import { getDetectCollectionsKPIsData } from '../../../../lib/api/QueryApis/Common'
import { useHistory } from 'react-router-dom'
import ReactFlow, {
    addEdge,
    Background,
    Controls,
    Handle,
    MarkerType,
    Position,
    useEdgesState,
    useNodesState
} from 'reactflow'

import 'reactflow/dist/style.css'
import ELK from 'elkjs'
import { Spin } from 'antd'
import { DetectItemSummary } from '../../../../lib/api/ResponseTypes/Common'
import { KPICard } from '../../KPI/KPICard'

export interface KPINodeProp {
    data: { itemSummary: DetectItemSummary, isLeader?: boolean }
    isConnectable: boolean
}

const KPINode = memo(({ data, isConnectable }: KPINodeProp) => {
    return (
        <>
            <Handle
                type='source'
                position={Position.Bottom}
                id='a'
                style={{ background: 'rgba(85,85,85,0)' }}
                onConnect={(params) => {
                }}
                isConnectable={isConnectable}
            />
            <KPICard
                itemSummary={data.itemSummary}
                isLeader={data.isLeader}
                mini={true}
                showItemStatus={false}
            />
            <Handle
                type='target'
                position={Position.Top}
                id='b'
                style={{ background: 'rgba(85,85,85,0)' }}
                isConnectable={true}
                onConnect={(params) => {
                }}
            />
        </>

    )
})


export interface CollectionGraphComponentInfo {
    collectionItemId: string
    collectionItemInfo: DetectGroupItem
}

const nodeTypes = {
    KPINode: KPINode
}

const CollectionGraphComponent = (
    {
        collectionItemId,
        collectionItemInfo
    }: CollectionGraphComponentInfo) => {

    const history = useHistory()

    const onInit = (reactFlowInstance: any) => {
    }

    const [nodes, setNodes, onNodesChange] = useNodesState([])
    const [edges, setEdges, onEdgesChange] = useEdgesState([])
    const [nodePositions, setNodePositions] = useState<any>()
    const [nodeLayoutError, setNodeLayoutError] = useState<any>(false)

    let width = 400
    let height = 150

    const onConnect = useCallback((params) => setEdges(
        (eds) => addEdge(params, eds)), [])

    const kpiData = useQuery(
        [{ itemExtId: collectionItemId, configId: null }],
        getDetectCollectionsKPIsData,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: 1000 * 60,
            retry: 1
        }
    )
    useEffect(() => {
        if (nodePositions===undefined || nodePositions===null) return
        setNodes(nodes.map((it) => {
            it['position'] = nodePositions[it.id]
            return it
        }))
    }, [nodePositions])

    useEffect(() => {
        if (kpiData.isSuccess) {
            const nodesInfo = kpiData.data.kpiItemSummaryList
            const undeletedNodes = kpiData.data.kpiItemSummaryList.map(it => {
                return it.itemExternalId
            })
            let nodesList: any[] = []
            let edgesList: any[] = []
            nodesInfo.forEach((nodeInfo, index) => {
                nodesList.push({
                    id: nodeInfo.itemExternalId,
                    position: { x: index * width, y: index * height },
                    width: width,
                    height: height,
                    label: nodeInfo.itemName,
                    type: 'KPINode',
                    data: {
                        itemSummary: nodeInfo,
                        isLeader: nodeInfo.itemExternalId===collectionItemInfo.leaderKPI
                    }
                })
            })
            Object.entries(collectionItemInfo.graph).forEach((value, index) => {
                value[1].children.filter((it: string) => {
                    return undeletedNodes.includes(it) && (undeletedNodes.includes(value[0]))
                }).forEach((childItemId: string, indexTwo: number) =>
                    edgesList.push({
                        source: value[0],
                        target: childItemId,
                        sources: [value[0]],
                        targets: [childItemId],
                        id: index * 1000 + indexTwo,
                        style: {
                            strokeWidth: '3px'
                        },
                        markerEnd: {
                            type: MarkerType.ArrowClosed
                        }
                    })
                )
            })
            setEdges(edgesList)
            setNodes(nodesList)

            let elk = new ELK()
            elk.layout({
                id: 'root',
                layoutOptions: {
                    'elk.algorithm': 'mrtree',
                    'elk.direction':'DOWN',
                    'elk.spacing.edgeNode': '10',
                    'elk.spacing.edgeEdge': '10',
                    'elk.spacing.nodeNode': '60'
                },
                children: nodesList,
                edges: edgesList
            })
                .then(it => {
                    let pos = it.children?.map(it => {
                        return [it.id, { x: it.x, y: it.y }]
                    })

                    setNodePositions(Object.fromEntries(pos!!))
                })
                .catch(() => {
                    setNodeLayoutError(true)
                })
        }
    }, [kpiData.isSuccess])


    if (nodePositions!==undefined || nodeLayoutError) {
        return (
            <div style={{
                minHeight: '80vh',
                height:'100%',
                width: '100%'
            }}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onInit={onInit}
                    nodeTypes={nodeTypes}
                    fitView
                    attributionPosition='top-right'
                >
                    <Controls />
                    <Background color='#aaa' gap={16} />
                </ReactFlow>
            </div>
        )
    } else {
        return <Spin tip={'Loading'}></Spin>
    }
}

export default CollectionGraphComponent
