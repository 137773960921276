import { useQuery } from 'react-query'
import { getUserDetectItems } from '../../../../lib/api/QueryApis/Common'
import React, { useEffect } from 'react'
import { Col, Empty, notification, Row, Spin } from 'antd'
import { KPICard } from '../KPICard'
import { ItemStatus, sendNotification } from '../../../../lib/utils/common'
import { OrderBy, OrderType } from '../../../../lib/api/RequestTypes/Common'
import { getUseQueryOptions } from '../../Utils'
import './index.css'

interface DetectItemListProps {
    filterText: string
    orderBy: OrderBy
    orderType: OrderType
    itemType: ItemStatus
    dataSourceType: string,
    limit: number
    offset: number
    showPublic: boolean
    showProblematic: boolean
    refresh?: boolean
    openCallback: Function | null
}

const KPIList = (
    {
        filterText,
        orderBy,
        orderType,
        itemType,
        dataSourceType,
        limit,
        offset,
        showPublic,
        showProblematic,
        refresh,
        openCallback
    }: DetectItemListProps) => {
    const query = useQuery(
        [
            {
                configStatus: itemType,
                filterText: filterText,
                orderBy: orderBy,
                orderType: orderType,
                dataSourceType: dataSourceType,
                limit: limit,
                offset: offset,
                showPublic: showPublic,
                showProblematic: showProblematic
            }
        ],
        getUserDetectItems,
        getUseQueryOptions(2)
    )
    useEffect(() => {
        if (refresh && query.isSuccess) {
            query.clear()
            query.refetch()
        }
    }, [refresh])

    if (query.isSuccess) {
        return (
            <Row gutter={12}
                 style={{ overflowY: 'scroll', maxHeight: '650px' }} className='detect-list-scroll'>
                {query.data.map((item, idx) => (
                    <Col key={idx} span={12}>
                        {itemType===ItemStatus.REQUESTED && (
                            <KPICard
                                itemSummary={item}
                                ignoreClick={false}
                                onClickCallback={() => {
                                    if (openCallback!==null) {
                                        openCallback(item.itemExternalId, item.configId)
                                    }
                                }}
                            />
                        )}
                        {itemType!==ItemStatus.REQUESTED && (
                            <KPICard
                                itemSummary={item}
                                ignoreClick={itemType===ItemStatus.REJECTED}
                            />
                        )}
                    </Col>
                ))}
            </Row>
        )
    } else if (query.isError) {
        sendNotification(notification, 'error', 'Can\'t fetch items', '')
        return (
            <div className='detect-full-center'>
                <Empty description={<span>Error Occurred. No Data</span>} />
            </div>
        )
    } else {
        return (
            <div className='detect-full-center'>
                <Spin tip='Loading' />
            </div>
        )
    }
}
export default KPIList
