import {
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    WarningOutlined,
} from '@ant-design/icons'
import { List, Tag, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { getKPIAlertStatus } from '../../lib/api/QueryApis/Common'
import {
    DataSourceType,
    SeverityLevel,
} from '../../lib/api/RequestTypes/Common'
import { PrometheusRule } from '../../lib/api/ResponseTypes/Common'

const { Text } = Typography

interface NonFunctionalAlertTableProp {
    firingRules: PrometheusRule[]
}

const NonFunctionalAlertTable = ({
    firingRules,
}: NonFunctionalAlertTableProp) => {
    const criticalAlerts = firingRules.filter(
        (rule) =>
            'critical' == (rule.labels['severity'] as string).toLowerCase()
    )
    const warningAlerts = firingRules.filter(
        (rule) => 'warning' == (rule.labels['severity'] as string).toLowerCase()
    )
    const infoAlerts = firingRules.filter(
        (rule) => 'info' == (rule.labels['severity'] as string).toLowerCase()
    )

    return (
        <div
            style={{
                color: 'black',
                backgroundColor: 'white',
                textAlign: 'center',
            }}
        >
            Critical: <Text code>{criticalAlerts.length}</Text> | Warning:{' '}
            <Text code>{warningAlerts.length}</Text> | Info:{' '}
            <Text code>{infoAlerts.length}</Text>
            <List
                style={{
                    maxHeight: '200px',
                    marginTop: '5px',
                    overflow: 'scroll',
                }}
            >
                {firingRules.map((rule, idx) => {
                    return (
                        <List.Item key={idx} title={rule.name}>
                            <Text code>{rule.name}</Text>
                        </List.Item>
                    )
                })}
            </List>
        </div>
    )
}

interface KPIHealthProps {
    itemExtId: string
    dataSourceType: DataSourceType
}

export const KPIHealthTag = ({ itemExtId, dataSourceType }: KPIHealthProps) => {
    const history = useHistory()
    const [incident, setIncident] = useState<any | null>(null)
    const [nonFunctionalRules, setNonFunctionalRules] = useState<
        PrometheusRule[]
    >([])

    const [alerts] = useMutation(getKPIAlertStatus, {
        onSuccess: (resp) => {
            setNonFunctionalRules(resp.rules)
            if (resp.rules.length > 0) {
                let criticalAlertCount = resp.rules.filter(
                    (rule) =>
                        'critical' ==
                        (rule.labels['severity'] as string).toLowerCase()
                ).length
                const warningAlertsCount = resp.rules.filter(
                    (rule) =>
                        'warning' ==
                        (rule.labels['severity'] as string).toLowerCase()
                ).length
                const infoAlertsCount = resp.rules.filter(
                    (rule) =>
                        'info' ==
                        (rule.labels['severity'] as string).toLowerCase()
                ).length
                const maxSeverity =
                    criticalAlertCount > 0
                        ? 'CRITICAL'
                        : warningAlertsCount > 0
                        ? 'WARNING'
                        : infoAlertsCount > 0
                        ? 'INFO'
                        : ''
                if (maxSeverity != '') {
                    setIncident({ level: maxSeverity, data: null })
                }
            }
        },
        onError: () => {
            console.log(
                'There has been an error while fetching active alerts for ' +
                    itemExtId
            )
        },
    })

    useEffect(() => {
        if (
            itemExtId &&
            dataSourceType &&
            dataSourceType == DataSourceType.PROMETHEUS
        ) {
            alerts({
                itemExtId: itemExtId,
                configId: null,
            })
            setInterval(() => {
                alerts({
                    itemExtId: itemExtId,
                    configId: null,
                })
            }, 60000)
        }
    }, [itemExtId])

    if (incident != null)
        return (
            <Tooltip
                color={'white'}
                overlayStyle={{ maxWidth: '500px' }}
                title={
                    dataSourceType != DataSourceType.PROMETHEUS ? (
                        <></>
                    ) : (
                        <NonFunctionalAlertTable
                            firingRules={nonFunctionalRules}
                        />
                    )
                }
            >
                <Tag
                    style={{
                        borderRadius: '10px',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                    color={
                        incident.level === SeverityLevel.CRITICAL
                            ? 'red'
                            : incident.level === SeverityLevel.WARNING
                            ? 'yellow'
                            : 'blue'
                    }
                    icon={
                        incident.level === SeverityLevel.CRITICAL ? (
                            <ExclamationCircleOutlined />
                        ) : incident.level === SeverityLevel.WARNING ? (
                            <WarningOutlined />
                        ) : (
                            <InfoCircleOutlined />
                        )
                    }
                >
                    {'Needs Attention: '}
                    {incident.level}
                </Tag>
            </Tooltip>
        )
    else return <></>
}
