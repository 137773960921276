import React from 'react'
import { Empty, Spin } from 'antd'
import { getUseQueryOptions } from '../Detect/Utils'
import { Line, Plot } from '@ant-design/charts'
import { LineOptions } from '@antv/g2plot';
import { useQuery } from 'react-query'
import { thanosQuery } from '../../lib/api/QueryApis/Common'

interface PromQLChartProps {
    query: string[] | null,
    step: number | null
    range: number | null,
    autoRefresh?: boolean,
    onReady?: ((chart: Plot<LineOptions>) => void) | undefined
}
const PromQLChart = ({ query, step, range, autoRefresh=true, onReady }: PromQLChartProps) => {

    if (query===null || query===undefined) {
        return <Empty style={{ width: '100%', height: '100%' }} />
    }
    const refreshInterval = autoRefresh ? 60 * 1000 : 0

    const thanosRequest = useQuery(
        [
            {
                range: range==null ? 60 * 60:range,
                query: query!![1],
                step: step==null ? 30:step,
                label: query!![0]
            }
        ],
        thanosQuery,
        getUseQueryOptions(1, refreshInterval)
    )


    if (thanosRequest.isLoading) {
        return <Spin style={{ width: '100%', height: '100%' }} />
    } else if (thanosRequest.isSuccess) {
        let data = thanosRequest.data.data
        let minVal = thanosRequest.data.min
        const config = {
            data,
            autoFit: true,
            xField: 'date',
            yField: 'value',
            yAxis: {
                min: minVal,
                label: {
                    formatter: (v: string) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
                }
            },
            slider: {
                start: 0.75,
                end: 1,
            },
        }
        const line = onReady!=undefined ? (<Line {...config} onReady={onReady}/>) : (<Line {...config}/>)
        
        return (
            <>{line}</>
        )
    } else {
        return <div />
    }


}


interface FullPromQLChartProps {
    query: string[] | null,
    autoRefresh: boolean,
    range: number,
    step: number,
    onReady?: ((chart: Plot<LineOptions>) => void) | undefined
}

export const FullPromQLChart = ({ query, autoRefresh, range, step, onReady }: FullPromQLChartProps) => {
    return (<div>
        <div style={{ height: '180px' }}>
            <PromQLChart query={query} step={step} range={range} autoRefresh={autoRefresh} onReady={onReady}/>
        </div>
    </div>)
}

export default PromQLChart


