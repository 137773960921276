import ApiClient from './client'

import { APIEndpoints } from './Endpoints'
import { EmptyRequestParams, NullableFormData } from './RequestTypes/Common'
import { APIResponse } from './ResponseTypes/Common'

export const DataPlatformService = new ApiClient('/api/dps')
export const apiClient = new ApiClient()

export const fetchDPAPIResponse = (
    url: APIEndpoints,
    params: NullableFormData = null,
    method = 'POST',
    API: ApiClient = DataPlatformService
) => {
    return method==='POST' || method==='PATCH'
        ? API.createRequest({
            path: url,
            request: {
                method: method,
                body: params ? params:new FormData()
            },
            response: (r: any) => {
                return typeof r==='string' ? JSON.parse(r):r
            }
        })({})
        :API.createRequest({
            path: url,
            request: {
                method: 'GET'
            },
            response: (r: any) => {
                return typeof r==='string' ? JSON.parse(r):r
            }
        })({})
}

function isObjLiteral(_obj: any) {
    var _test = _obj
    return typeof _obj!=='object' || _obj===null
        ? false
        :(function() {
            while (!false) {
                if (
                    Object.getPrototypeOf(
                        (_test = Object.getPrototypeOf(_test))
                    )===null
                ) {
                    break
                }
            }
            return Object.getPrototypeOf(_obj)===_test
        })()
}

export const fetchDPEndpoint = async (
    url: APIEndpoints,
    requestParams: EmptyRequestParams = {},
    method = 'POST'
) => {
    let params = new FormData()
    if (method==='POST') {
        // Generate key value pairs of request parameters
        Object.entries(requestParams).forEach((value: [string, any]) => {
            let bodyVal = value[1]
            if (isObjLiteral(bodyVal)) bodyVal = JSON.stringify(bodyVal)
            params.append(value[0], bodyVal)
        })
    } else if (method==='GET') {
        Object.values(requestParams).forEach((value: string, index) => {
            url = url.replace(`{${index}}`, value) as any
        })
    }
    // console.log('REQUEST: ', requestParams, Array.from(params.entries()));
    const data: APIResponse = await fetchDPAPIResponse(url, params, method)
    if (
        (data.status as string)==='AUTHORIZATION_FAILURE' ||
        (data.status as string)==='FAILURE'
    ) {
        throw new Error(data.response)
    }
    if ((data as unknown as any).code===403) {
        throw new Error((data as unknown as any).message)
    }
    // console.log('RESPONSE: ', data);
    return data
}

export const fetchDPEndpointV2 = async (
    url: APIEndpoints,
    requestParams: EmptyRequestParams = {},
    method = 'POST'
) => {
    let params = new FormData()
    // Generate key value pairs of request parameters
    Object.entries(requestParams).forEach((value: [string, any]) => {
        let bodyVal = value[1]
        let isArray = false
        if (typeof Array.isArray==='undefined')
            isArray =
                Object.prototype.toString.call(bodyVal)==='[object Array]'
        else isArray = Array.isArray(bodyVal)
        if (isObjLiteral(bodyVal)) bodyVal = JSON.stringify(bodyVal)
        if (isArray) {
            bodyVal.map((item: string) =>
                params.append(value[0] + '[]', JSON.stringify(item))
            )
        } else {
            params.append(value[0], bodyVal)
        }
    })
    // console.log('REQUEST: ', requestParams, Array.from(params.entries()));
    const data: APIResponse = await fetchDPAPIResponse(url, params, method)
    if (
        (data.status as string)==='AUTHORIZATION_FAILURE' ||
        (data.status as string)==='FAILURE'
    ) {
        throw new Error(data.response)
    }
    // console.log('RESPONSE: ', data);
    return data
}

export const fetchEndpointFile = async (
    url: APIEndpoints,
    requestParams: EmptyRequestParams = {},
    method = 'GET'
) => {
    // let params = new FormData()
    let params = new FormData()
    // Generate key value pairs of request parameters
    Object.entries(requestParams).forEach((value: [string, any]) => {
        let bodyVal = value[1]
        let isArray = false
        if (typeof Array.isArray==='undefined')
            isArray =
                Object.prototype.toString.call(bodyVal)==='[object Array]'
        else isArray = Array.isArray(bodyVal)
        if (isObjLiteral(bodyVal)) bodyVal = JSON.stringify(bodyVal)
        if (isArray) {
            bodyVal.map((item: string) =>
                params.append(value[0] + '[]', JSON.stringify(item))
            )
        } else {
            params.append(value[0], bodyVal)
        }
    })
    // console.log('REQUEST: ', requestParams, Array.from(params.entries()));
    let headers: Headers = new Headers()
    // headers.append('Content-Type', 'application/octet-stream')
    headers.append('Accept', 'application/json')
    const data: APIResponse = await apiClient.createRequest({
        path: url,
        request: {
            method: 'POST',
            body: params ? params:new FormData(),
            headers: headers
        },
        response: (r: any) => {
            return typeof r==='string' ? JSON.parse(r):r
        }
    })({})
    if (
        (data.status as string)==='AUTHORIZATION_FAILURE' ||
        (data.status as string)==='FAILURE'
    ) {
        throw new Error(data.response)
    }
    // console.log('RESPONSE: ', data);
    return data
}
