import React, { useEffect, useState } from 'react'
import {
    AnomalyMode,
    ComputeType,
    DataSourceType,
    DetectAlgorithmInput,
    DetectItem,
    SeverityLevel,
    TaskTriggerMode,
    TaskType
} from '../../../../lib/api/RequestTypes/Common'
import IntervalPicker from '../../../Common/IntervalPicker'
import { useMutation, useQuery } from 'react-query'
import {
    addDataSource,
    addDetectItem,
    updateDetectItemConfig
} from '../../../../lib/api/MutationApis/Common'
import { ItemScope } from '../../../../types/DataPlatformTypes/CommonTypes'
import {
    Button,
    Col,
    Empty,
    Form,
    FormInstance,
    Input,
    InputNumber,
    List,
    notification,
    Row,
    Select,
    Spin,
    Steps,
    Switch
} from 'antd'
import {
    getDataSourceAlerts,
    getDataSourceColumns,
    getDataSourceQueries,
    getFormInitData,
    getUdaanResourceList,
    getUdaanServiceList
} from '../../../../lib/api/QueryApis/Common'
import { getUseQueryOptions, humanize, preventEnterToSubmit } from '../../Utils'
import { ValidateStatus } from 'antd/es/form/FormItem'
import {
    DataSourceColumn,
    DetectAlgorithm,
    DynamicFormFieldDefinition,
    DataSourceQuery
} from '../../../../lib/api/ResponseTypes/Common'
import './index.css'
import { InfoCircleOutlined } from '@ant-design/icons'
import cronstrue from 'cronstrue'
import { sendNotification } from '../../../../lib/utils/common'
import { nextKey } from '../../../../lib/api/utils'
import AlgorithmInput from './AlgorithmInput'

const { Step } = Steps
const { Option } = Select

export enum ItemMode {
    Create = 'Create',
    Update = 'Update',
    View = 'View',
}

export interface PrometheusMetricFieldInputProp {
    fieldName: string
    fieldDefinition: DynamicFormFieldDefinition
    callback: Function | null
    form: FormInstance
}

const PrometheusMetricFieldInput = ({
                                        fieldName,
                                        fieldDefinition,
                                        callback,
                                        form
                                    }: PrometheusMetricFieldInputProp) => {
    if (fieldName==='service_name') {
        const serviceListQuery = useQuery(
            [
                {
                    namespace: form.getFieldValue('namespace'),
                    cluster: form.getFieldValue('cluster')
                }
            ],
            getUdaanServiceList,
            getUseQueryOptions(1)
        )
        return (
            <Form.Item name={fieldName} label={humanize(fieldName)}>
                <Select
                    showSearch
                    onClick={() => {
                    }}
                    onChange={(value) => {
                        if (callback!==null) {
                            callback(fieldName, value)
                        }
                    }}
                    loading={serviceListQuery.isLoading}
                    style={{ minWidth: '150px', marginBottom: '10px' }}
                    placeholder={humanize(fieldName)}
                    options={serviceListQuery.data?.map((it) => {
                        return { value: it, label: it }
                    })}
                    dropdownMatchSelectWidth={false}
                />
            </Form.Item>
        )
    }
    if (fieldName==='resource_name') {
        const namespace = Form.useWatch('namespace', form)
        const cluster = Form.useWatch('cluster', form)
        const serviceName = Form.useWatch('service_name', form)

        const [selectedResourceName, setSelectedResourceName] = useState<
            string | null
        >(null)
        const [resourceList, setResourceList] = useState<string[]>([])
        const [resourceListStatus, setResourceListStatus] =
            useState<boolean>(false)
        const [isLoading, setIsLoading] = useState<boolean>(false)

        const [resourceListRequest] = useMutation(getUdaanResourceList, {
            onSuccess: (resp) => {
                setResourceList(resp)
                setIsLoading(false)
                setResourceListStatus(false)
            },
            onError: (error) => {
                setIsLoading(false)
                setResourceList([])
                setResourceListStatus(true)
            }
        })

        useEffect(() => {
            setResourceList([])
            setSelectedResourceName(null)
            if (namespace && cluster && serviceName) {
                setIsLoading(true)
                resourceListRequest({
                    namespace: namespace,
                    cluster: cluster,
                    serviceName: serviceName
                })
            }
        }, [namespace, cluster, serviceName])

        useEffect(() => {
            if (callback!==null) {
                callback(fieldName, selectedResourceName)
            }
        }, [selectedResourceName])

        return (
            <Form.Item name={fieldName} label={humanize(fieldName)}>
                <Select
                    showSearch
                    onClick={() => {
                    }}
                    onChange={(value) => {
                        setSelectedResourceName(value)
                    }}
                    value={selectedResourceName}
                    status={resourceListStatus ? 'error':''}
                    loading={isLoading}
                    style={{ minWidth: '150px', marginBottom: '10px' }}
                    placeholder={fieldName}
                    options={resourceList.map((it) => {
                        return { value: it, label: it }
                    })}
                    dropdownMatchSelectWidth={false}
                />
            </Form.Item>
        )
    } else {
        let innerContent = <div />
        switch (fieldDefinition.type) {
            case 'float':
            case 'int': {
                innerContent = (
                    <InputNumber
                        onChange={(value) => {
                            if (callback!==null) {
                                callback(fieldName, value)
                            }
                        }}
                        style={{ minWidth: '150px', marginBottom: '10px' }}
                        placeholder={humanize(fieldName)}
                        type='number'
                        {...(fieldDefinition.range
                            ? {
                                min: fieldDefinition.range[0],
                                max: fieldDefinition.range[1],
                                step:
                                    (fieldDefinition.range[1] -
                                        fieldDefinition.range[0]) /
                                    10
                            }
                            :{})}
                    />
                )
                break
            }
            case 'boolean':
            case 'string': {
                innerContent = (
                    <Select
                        onClick={() => {
                        }}
                        onChange={(value) => {
                            if (callback!==null) {
                                callback(fieldName, value)
                            }
                        }}
                        style={{ minWidth: '150px', marginBottom: '10px' }}
                        placeholder={humanize(fieldName)}
                        options={fieldDefinition.options?.map((it) => {
                            return {
                                label: it===null ? 'None':it.toString(),
                                value: it
                            }
                        })}
                    />
                )
                break
            }
            case 'input': {
                innerContent = (
                    <Input
                        onChange={(value) => {
                            if (callback!==null) {
                                callback(fieldName, value)
                            }
                        }}
                        style={{ minWidth: '150px', marginBottom: '10px' }}
                        placeholder={humanize(fieldName)}
                    />
                )
                break
            }
            default:
                break
        }
        return (
            <Form.Item name={fieldName} label={humanize(fieldName)}>
                {innerContent}
            </Form.Item>
        )
    }
}

export interface PrometheusMetricFormProp {
    metric: DataSourceQuery
    form: FormInstance
    disabled: boolean
    callback: Function | null
    defaultValues: { [key: string]: any } | null
}

const PrometheusMetricForm = ({
                                  metric,
                                  form,
                                  disabled,
                                  callback,
                                  defaultValues
                              }: PrometheusMetricFormProp) => {
    const { TextArea } = Input
    const [metricQueryString, setMetricQueryString] = useState<string>()

    let fields = metric.queryParameterDefinition
    let defaults = { ...fields }
    Object.keys(defaults).forEach(
        (key) => (defaults[key] = defaults[key].default)
    )
    if (defaultValues!==null) {
        Object.keys(defaultValues).forEach((key) => {
            defaults[key] = defaultValues[key]
        })
    }

    const updateQueryString = () => {
        let queryString = metric.queryTemplateString
        Object.keys(fields).forEach((value: string) => {
            queryString = queryString.replaceAll(
                `<${value}>`,
                form.getFieldValue(value)
            )
        })
        setMetricQueryString(queryString)
    }

    function tempCallback(fieldName: string, value: any) {
        if (callback!==null) {
            callback(fieldName, value)
            updateQueryString()
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form
                        size='middle'
                        form={form}
                        layout='inline'
                        disabled={disabled}
                        initialValues={defaults}
                    >
                        {Object.keys(fields).map((it) => (
                            <Col key={it} span={12}>
                                <PrometheusMetricFieldInput
                                    fieldName={it}
                                    fieldDefinition={fields[it]}
                                    callback={tempCallback}
                                    form={form}
                                ></PrometheusMetricFieldInput>
                            </Col>
                        ))}
                    </Form>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={24}>
                    <TextArea
                        placeholder='Metric PromQL Query String'
                        contentEditable={false}
                        style={{ width: '100%' }}
                        value={metricQueryString}
                        autoSize={{ minRows: 2 }}
                    />
                </Col>
            </Row>
        </>
    )
}

export interface DetectItemConfigProps {
    itemConfig: DetectItem | null
    itemExtId: string | null
    mode: ItemMode
    callback?: Function
}

const isCronValid = (cron: string) => {
    try {
        cronstrue.toString(cron)
        return true
    } catch (e) {
        return false
    }
}

const cronParser = (cron: string) => {
    try {
        return cronstrue.toString(cron)
    } catch (e) {
        return 'Invalid Cron Expression'
    }
}

const KPIConfig = (props: DetectItemConfigProps) => {
    const [form] = Form.useForm()
    const taskType = Form.useWatch('taskType', form)
    const granularity = Form.useWatch('granularity', form)
    const cron = Form.useWatch('cron', form)
    const serviceName = Form.useWatch('service_name', form)
    const resourceName = Form.useWatch('resource_name', form)
    const namespace = Form.useWatch('namespace', form)
    const cluster = Form.useWatch('cluster', form)
    const [current, setCurrent] = useState(0)

    const [problemAreas, setProblemAreas] = useState<string[]>(['netra'])
    const [algorithms, setAlgorithms] = useState<{
        [key: string]: DetectAlgorithmInput
    }>({})
    const [dataPlatformAlgorithms, setDataPlatformAlgorithms] = useState<{
        [key: string]: DetectAlgorithm
    }>({})
    const [prometheusAlgorithms, setPrometheusAlgorithms] = useState<{
        [key: string]: DetectAlgorithm
    }>({})
    const [isLoading, setIsLoading] = useState(false)
    const [dataSourceStatus, setDataSourceStatus] = useState<ValidateStatus>('')
    const [dataSourceColumns, setDataSourceColumns] = useState<
        DataSourceColumn[]
    >([])
    const [usableColumns, setUsableColumns] = useState<DataSourceColumn[]>([])
    const [dataSourceId, setDataSourceId] = useState('')
    const [isFraudAndAbuse, setIsFraudAndAbuse] = useState(false)

    const selectedDataSource = Form.useWatch('dataSourceType', form)

    const [dataSourceQueries, setDataSourceQueries] = useState<
        DataSourceQuery[] | null
    >(null)
    const [selectedQueryType, setSelectedQueryType] =
        useState<DataSourceQuery | null>(null)
    const [queryFieldsValue, setQueryFieldsValue] = useState<{
        [key: string]: any
    }>({})
    const [
        fetchingDataSourceSupportedAlerts,
        setFetchingDataSourceSupportedAlerts
    ] = useState<boolean>(true)

    const updateUsableColumns = () => {
        setUsableColumns(
            dataSourceColumns.filter((it) => {
                return !(
                    form
                        .getFieldValue('continuousDimensionColumns')
                        .includes(it.columnName) ||
                    form
                        .getFieldValue('categoricalDimensionColumns')
                        .includes(it.columnName) ||
                    it.columnName===form.getFieldValue('timestampColumn') ||
                    it.columnName===
                    form.getFieldValue('mainDimensionColumn') ||
                    it.columnName===form.getFieldValue('measureColumn')
                )
            })
        )
    }

    useEffect(() => {
        if (props.itemConfig?.algorithms.length===0) {
            setAlgorithms({})
        }

        if (selectedDataSource===DataSourceType.PROMETHEUS && selectedQueryType?.queryType!==undefined) {
            dataSourceAlertsRequest({
                dataSourceType: DataSourceType.PROMETHEUS,
                queryType: selectedQueryType?.queryType
            })
            form.setFieldsValue({
                computeType: ComputeType.NATIVE,
                sensitivity: 5,
                anomalyMode: AnomalyMode.UPPER_AND_LOWER
            })
        } else {
            form.setFieldsValue({ computeType: ComputeType.KUBERNETES })
        }
    }, [selectedDataSource, selectedQueryType])

    useEffect(() => {
        updateUsableColumns()
    }, [dataSourceColumns])

    const [addDataSourceRequest] = useMutation(addDataSource, {
        onSuccess: (resp) => {
            setDataSourceStatus('success')
            setDataSourceId(resp.dataSourceId)
            form.setFieldsValue({ dataSourceId: resp.dataSourceId })
        },
        onError: (e) => {
            setDataSourceStatus('error')
        }
    })

    const [dataSourceFieldsRequest] = useMutation(getDataSourceQueries, {
        onSuccess: (resp) => {
            setDataSourceStatus('success')
            setDataSourceQueries(resp)
            let queryId = form.getFieldValue('prometheusMetricTypeId')
            if (queryId!==undefined && queryId!==null) {
                const queryTypeObj = resp?.find(
                    (value) => value.queryId===queryId
                )
                if (queryTypeObj!==undefined) {
                    setSelectedQueryType(queryTypeObj)
                }
            }
        },
        onError: (e) => {
            setDataSourceStatus('error')
        }
    })

    const [dataSourceAlertsRequest] = useMutation(getDataSourceAlerts, {
        onSuccess: (resp) => {
            setPrometheusAlgorithms(resp)
            if (Object.keys(resp).length!=0) {
                let fields =
                    resp[Object.keys(resp)[0] as string]
                        .hyperParameterDefinition
                let defaults = { ...fields }
                Object.keys(defaults).forEach(
                    (key) => (defaults[key] = defaults[key].default)
                )
                defaults['service_name'] = serviceName
                defaults['resource_name'] = resourceName
                if (selectedDataSource===DataSourceType.PROMETHEUS && Object.keys(algorithms).length===0) {
                    let newKey = nextKey()
                    setAlgorithms({
                        [newKey]: {
                            algorithm: Object.keys(resp)[0] as string,
                            configAlgorithmId: newKey,
                            squadcastSlug: 'detect-netra-service',
                            severity: SeverityLevel.WARNING,
                            sensitivity: 5,
                            hyperParameters: defaults,
                            anomalyMode: AnomalyMode.UPPER_AND_LOWER
                        }
                    })
                }
            }
            setFetchingDataSourceSupportedAlerts(false)
        },
        onError: (e) => {
            setFetchingDataSourceSupportedAlerts(false)
            sendNotification(
                notification,
                'error',
                `Failed to fetch supported algorithms. ${
                    (e as any).message
                } 😓\nPlease contact Netra Admins. Do not move ahead.`,
                ''
            )
        }
    })

    const [addItem] = useMutation(addDetectItem, {
        onSuccess: (data) => {
            if (props.mode===ItemMode.Create) {
                form.resetFields()
                setCurrent(0)
            }
            sendNotification(
                notification,
                'success',
                'New Item created: ' + data.itemExternalId,
                ''
            )
            setIsLoading(false)
            if (props.callback) {
                props.callback()
            }
        },
        onError: (e) => {
            setIsLoading(false)
            sendNotification(
                notification,
                'error',
                `Failed to add Item. ${(e as any).message} 😓`,
                ''
            )
        }
    })

    const [updateItem] = useMutation(updateDetectItemConfig, {
        onSuccess: (data) => {
            let message =
                data.needPermission==='true'
                    ? 'Update requested for item ' +
                    props.itemExtId +
                    '. Changes will be live once approved.'
                    :'Updated item ' +
                    props.itemExtId +
                    '. Will be live in the next run.'

            sendNotification(notification, 'success', message, '')
            setIsLoading(false)
            if (props.callback) {
                props.callback()
            }
        },
        onError: (e) => {
            setIsLoading(false)
            sendNotification(
                notification,
                'error',
                `Failed to update item. ${(e as any).message}`,
                ''
            )
        }
    })

    if (props.itemConfig!==null) {
        const dataSourceColumnsQuery = useQuery(
            [{ dataSourceId: props.itemConfig.dataSourceId }],
            getDataSourceColumns,
            getUseQueryOptions(1)
        )
        useEffect(() => {
            if (dataSourceColumnsQuery.isSuccess) {
                setDataSourceColumns(dataSourceColumnsQuery.data)
            }
        }, [dataSourceColumnsQuery])
    }

    const initFormData = useQuery([{}], getFormInitData, getUseQueryOptions(1))
    useEffect(() => {
        if (initFormData.isSuccess) {
            setProblemAreas(initFormData.data.problemAreas)

            setDataPlatformAlgorithms(initFormData.data.algorithms)

            let fields =
                initFormData.data.algorithms['prophet'].hyperParameterDefinition
            let defaults = { ...fields }
            Object.keys(defaults).forEach(
                (key) => (defaults[key] = defaults[key].default)
            )

            if (
                Object.keys(algorithms).length===0 &&
                selectedDataSource===DataSourceType.DATA_PLATFORM
            ) {
                let newKey = nextKey()
                setAlgorithms({
                    [newKey]: {
                        algorithm: 'prophet',
                        configAlgorithmId: newKey,
                        squadcastSlug: 'detect-netra-service',
                        severity: SeverityLevel.WARNING,
                        sensitivity: 5,
                        hyperParameters: defaults,
                        anomalyMode: AnomalyMode.UPPER_AND_LOWER
                    }
                })
            }
        }
    }, [initFormData.isSuccess])

    useEffect(() => {
        if (selectedDataSource===DataSourceType.PROMETHEUS) {
            dataSourceFieldsRequest({
                dataSourceType: DataSourceType.PROMETHEUS
            })
            if (props.itemConfig===null) {
                setAlgorithms({})
            }
        }
    }, [selectedDataSource])

    useEffect(() => {
        form.setFieldsValue({
            granularity: 60 * 60 * 24,
            categoricalDimensionColumns: [],
            continuousDimensionColumns: [],
            ignoreLatest: 0
        })

        if (props.itemConfig!==null) {
            setDataSourceId(props.itemConfig.dataSourceId)
            form.setFieldsValue(props.itemConfig)
            let algorithmsNew = {}

            for (let algorithm in props.itemConfig.algorithms) {
                let newKey =
                    props.itemConfig.algorithms[algorithm].configAlgorithmId!!
                algorithmsNew = {
                    ...algorithmsNew,
                    [newKey]: props.itemConfig.algorithms[algorithm]
                }
            }

            setAlgorithms(algorithmsNew)
            if (props.itemConfig.taskType===TaskType.SCHEDULE) {
                form.setFieldsValue({
                    cron: props.itemConfig.taskConfig['cron']
                })
            }
        }
    }, [])

    const dataSourceColumnsQuery = useQuery(
        [{ dataSourceId: dataSourceId }],
        getDataSourceColumns,
        getUseQueryOptions(1, 60 * 1000, false)
    )

    useEffect(() => {
        if (dataSourceColumnsQuery.isSuccess) {
            setDataSourceColumns(dataSourceColumnsQuery.data)
        }
    }, [dataSourceColumnsQuery])

    useEffect(() => {
        if (dataSourceId!=='' && dataSourceColumnsQuery.isIdle) {
            setDataSourceColumns([])
            dataSourceColumnsQuery.refetch()
        }
    }, [dataSourceId])

    let itemEditable = props.mode===ItemMode.Create
    let configEditable =
        props.mode===ItemMode.Create || props.mode===ItemMode.Update
    let marks: any = {}
    for (let i = 0; i <= 10; i++) {
        marks[i] = i
    }

    const submit = (event: any) => {
        form.validateFields().catch((error) => {
            console.log(error)
            return
        })
        if (form.getFieldValue('dataSourceId')==='') {
            sendNotification(
                notification,
                'warning',
                'Add a datasource first',
                ''
            )
            return
        }
        let areFieldsValid = true
        selectedQueryType &&
        Object.keys(selectedQueryType?.queryParameterDefinition).forEach(
            (fieldName: string) => {
                if (
                    !form.getFieldValue(fieldName) ||
                    form.getFieldValue(fieldName)===''
                ) {
                    sendNotification(
                        notification,
                        'warning',
                        `${humanize(fieldName)} is a required field`,
                        ''
                    )
                    areFieldsValid = false
                    return
                }
            }
        )

        if (!areFieldsValid) return

        setIsLoading(true)
        if (props.mode===ItemMode.Create) {
            let item = form.getFieldsValue(true)
            if (taskType===TaskType.SCHEDULE) {
                item.taskTriggerMode = null
                item.taskConfig = { cron: item.cron }
            } else {
                item.taskConfig = {}
            }
            item.algorithms = Object.values(algorithms)
            item.triggerEndpoint = ''
            item.dataSourceItemPath = ''
            item.configId = ''
            item.itemExternalId = ''
            addItem(item)
        } else if (props.mode===ItemMode.Update) {
            if (props.itemExtId===null) return
            let item = {
                ...form.getFieldsValue(true),
                algorithms: Object.values(algorithms),
                dataSourceItemPath: '',
                itemExternalId: props.itemExtId,
                configId: ''
            }
            if (taskType===TaskType.SCHEDULE) {
                item.taskConfig = { cron: item.cron }
                item.taskTriggerMode = null
            } else {
                item.taskConfig = {}
            }
            updateItem({
                itemExtId: props.itemExtId,
                item: item
            })
        }
    }

    if (isLoading) {
        let loadingMessage = ''
        if (props.mode===ItemMode.Update) loadingMessage = 'Updating Item...'
        else loadingMessage = 'Creating Item...'
        return (
            <div className='detect-item__loading'>
                <Spin tip={loadingMessage} />
            </div>
        )
    }
    return (
        <Form
            onSubmitCapture={submit}
            layout='vertical'
            form={form}
            className='detect-item__form'
            initialValues={{
                problemAreaName: 'netra',
                itemScope: ItemScope.PUBLIC,
                algorithm: 'prophet',
                algorithms: [],
                sensitivity: 5,
                squadcastSlug: 'detect-netra-service',
                dataSourceType: DataSourceType.DATA_PLATFORM,
                taskType: TaskType.EVENT_HOOK,
                taskTriggerMode: TaskTriggerMode.POST_INGEST,
                cron: '0 * * * *',
                aggregate: true,
                fillMissingDates: false,
                aggregationRule: 'sum'
            }}
        >
            <Steps
                size='small'
                style={{ marginBottom: '20px' }}
                current={current}
                onChange={(key) => {
                    setCurrent(key)
                }}
            >
                <Step key={0} title='Basic Details' />
                <Step key={1} title='Data Source Configuration' />
                <Step key={2} title='Anomaly Definition' />
                {selectedDataSource===DataSourceType.DATA_PLATFORM && (
                    <Step key={3} title='Anomaly Configuration' />
                )}
            </Steps>
            <div hidden={current!==0} className='detect-item__body'>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item
                            name='itemName'
                            rules={[{ required: true, message: '' }]}
                            label='KPI Name'
                        >
                            <Input
                                disabled={!configEditable}
                                placeholder='Name of KPI'
                                onKeyDown={preventEnterToSubmit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name='problemAreaName'
                            rules={[{ required: true, message: '' }]}
                            label='Problem Area'
                        >
                            <Select
                                value='netra'
                                disabled={!configEditable}
                                onClick={() => {
                                    if (
                                        form.getFieldValue(
                                            'problemAreaName'
                                        )==='fraud-and-abuse'
                                    ) {
                                        setIsFraudAndAbuse(true)
                                    } else {
                                        setIsFraudAndAbuse(false)
                                    }
                                }}
                                placeholder='Problem Area'
                            >
                                {problemAreas.map((value, idx) => {
                                    return (
                                        <Option value={value} key={idx}>
                                            {value}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name='itemScope'
                            rules={[{ required: true, message: '' }]}
                            label='KPI Scope'
                        >
                            <Select disabled={!itemEditable}>
                                {isFraudAndAbuse && (
                                    <Option value={ItemScope.PRIVATE} key={0}>
                                        Private
                                    </Option>
                                )}
                                <Option value={ItemScope.PUBLIC} key={1}>
                                    Public
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name='dataSourceType'
                            rules={[{ required: true, message: '' }]}
                            label='Data Source Type'
                        >
                            <Select disabled={!configEditable}>
                                <Option value='DATA_PLATFORM' key={0}>
                                    Data Platform - Probe
                                </Option>
                                <Option value='PROMETHEUS' key={1}>
                                    Non-Functional - Prometheus
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <div hidden={current!==1} className='detect-item__body'>
                {selectedDataSource===DataSourceType.PROMETHEUS && (
                    <div>
                        <Row gutter={12}>
                            <Col span={24}>
                                <Form.Item
                                    rules={[{ required: true, message: '' }]}
                                    hasFeedback
                                    label='Metric Type'
                                    name='prometheusMetricTypeId'
                                >
                                    <Select
                                        disabled={!configEditable}
                                        loading={dataSourceQueries==null}
                                        onChange={(queryId: string) => {
                                            const queryTypeObj =
                                                dataSourceQueries?.find(
                                                    (value) =>
                                                        value.queryId===
                                                        queryId
                                                )
                                            if (queryTypeObj!==undefined) {
                                                setSelectedQueryType(
                                                    queryTypeObj
                                                )
                                            }
                                        }}
                                    >
                                        {dataSourceQueries?.map(
                                            (value, index) => {
                                                return (
                                                    <Option
                                                        value={value.queryId}
                                                        key={index}
                                                    >
                                                        {value.queryFullName}
                                                    </Option>
                                                )
                                            }
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            <Row gutter={12}>
                                <Col span={24}>
                                    <div
                                        style={{
                                            fontSize: '10pt',
                                            marginBottom: '10px',
                                            ...(!selectedQueryType && {
                                                display: 'none'
                                            })
                                        }}
                                    >
                                        {selectedQueryType && (
                                            <PrometheusMetricForm
                                                metric={selectedQueryType}
                                                form={form}
                                                disabled={!configEditable}
                                                callback={(
                                                    fieldName: string,
                                                    value: any
                                                ) => {
                                                    let tempQueryFieldVal =
                                                        queryFieldsValue
                                                    tempQueryFieldVal[
                                                        fieldName
                                                        ] = value
                                                    setQueryFieldsValue(
                                                        tempQueryFieldVal
                                                    )
                                                }}
                                                defaultValues={null}
                                            ></PrometheusMetricForm>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        }
                    </div>
                )}
                {selectedDataSource===DataSourceType.DATA_PLATFORM && (
                    <div>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item
                                    rules={[{ required: true, message: '' }]}
                                    hasFeedback
                                    label='Probe Id'
                                    validateStatus={dataSourceStatus}
                                    name='dataSourceItemExtId'
                                >
                                    <Input
                                        onKeyDown={preventEnterToSubmit}
                                        disabled={!configEditable}
                                        placeholder='Probe Id'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name='aggregate'
                                    valuePropName='checked'
                                    rules={[{ required: true, message: '' }]}
                                    label='Aggregate'
                                    tooltip={{
                                        title: 'Aggregate to `Others` if a lot of distinct values',
                                        icon: <InfoCircleOutlined />
                                    }}
                                >
                                    <Switch
                                        disabled={!configEditable}
                                        defaultChecked={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name='fillMissingDates'
                                    valuePropName='checked'
                                    rules={[{ required: true, message: '' }]}
                                    label='Fill Missing Dates'
                                    tooltip={{
                                        title: 'Fill missing dates with zeros',
                                        icon: <InfoCircleOutlined />
                                    }}
                                >
                                    <Switch
                                        disabled={!configEditable}
                                        defaultChecked={false}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={16}>
                                <Form.Item
                                    rules={[{ required: true, message: '' }]}
                                    label='Data Granularity'
                                    tooltip={{
                                        title: 'The granularity of the data. Hourly, daily, weekly etc.',
                                        icon: <InfoCircleOutlined />
                                    }}
                                >
                                    <IntervalPicker
                                        timeInterval={form.getFieldValue(
                                            'granularity'
                                        )}
                                        onChange={(granularity) => {
                                            form.setFieldsValue({
                                                granularity: granularity
                                            })
                                        }}
                                        disabled={!configEditable}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name='timePeriod'
                                    rules={[{ required: true, message: '' }]}
                                    label='Time Window'
                                    tooltip={{
                                        title: 'The time-period to perform anomaly detection on. Granularity of 1 day & a time-period of 30 means past 30 days.',
                                        icon: <InfoCircleOutlined />
                                    }}
                                >
                                    <InputNumber
                                        min={1}
                                        style={{ width: '100%' }}
                                        onKeyDown={preventEnterToSubmit}
                                        disabled={!configEditable}
                                        placeholder='Multiple Of Granularity'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={8}>
                                <Form.Item
                                    name='ignoreLatest'
                                    rules={[{ required: false, message: '' }]}
                                    label='Ignore Latest'
                                    tooltip={{
                                        title: 'Number of most recent points to ignore. Useful when last data points are incomplete',
                                        icon: <InfoCircleOutlined />
                                    }}
                                >
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        min={0}
                                        onKeyDown={preventEnterToSubmit}
                                        disabled={!configEditable}
                                        placeholder='Multiple Of Granularity'
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    tooltip={{
                                        icon: <InfoCircleOutlined />,
                                        title: 'Decides how to aggregate the data. Use sum for items such as GMV, mean for items such as error rate etc.'
                                    }}
                                    name='aggregationRule'
                                    rules={[{ required: true, message: '' }]}
                                    label='Aggregation Rule'
                                >
                                    <Select disabled={!configEditable}>
                                        <Option value='sum' key={0}>
                                            Sum
                                        </Option>
                                        <Option value='mean' key={1}>
                                            Mean
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
            <div hidden={current!==2} className='detect-item__body'>
                <Row>
                    <Col>
                        <Button
                            style={{ margin: 'auto' }}
                            type='primary'
                            onClick={() => {
                                const algorithmName =
                                    selectedDataSource==
                                    DataSourceType.DATA_PLATFORM
                                        ? 'prophet'
                                        :(Object.keys(
                                            dataPlatformAlgorithms
                                        )[0] as string)
                                if (!initFormData.isSuccess) {
                                    return
                                }
                                let fields =
                                    selectedDataSource==
                                    DataSourceType.DATA_PLATFORM
                                        ? initFormData.data.algorithms[
                                            algorithmName
                                            ].hyperParameterDefinition
                                        :dataPlatformAlgorithms[algorithmName]
                                            .hyperParameterDefinition
                                let defaults = { ...fields }
                                let newKey = nextKey()
                                Object.keys(defaults).forEach(
                                    (key) =>
                                        (defaults[key] = defaults[key].default)
                                )
                                setAlgorithms({
                                    ...algorithms,
                                    [newKey]: {
                                        algorithm: algorithmName,
                                        hyperParameters: defaults,
                                        severity: SeverityLevel.WARNING,
                                        squadcastSlug: 'detect-netra-service',
                                        sensitivity: 5,
                                        configAlgorithmId: newKey,
                                        anomalyMode:
                                        AnomalyMode.UPPER_AND_LOWER
                                    }
                                })
                            }}
                        >
                            Add
                        </Button>
                    </Col>
                    <Col span={24}>
                        <List style={{ overflow: 'scroll' }}>
                            {((initFormData.isSuccess &&
                                        selectedDataSource==
                                        DataSourceType.DATA_PLATFORM) ||
                                    (selectedDataSource==
                                        DataSourceType.PROMETHEUS &&
                                        !fetchingDataSourceSupportedAlerts)) &&
                                Object.keys(algorithms).map((it: string) => {
                                    return (
                                        <List.Item
                                            style={{ width: '100%' }}
                                            key={it}
                                        >
                                            <AlgorithmInput
                                                datasourceType={
                                                    selectedDataSource
                                                }
                                                algorithmInput={algorithms[it]}
                                                disabled={!configEditable}
                                                supportedAlgorithms={
                                                    selectedDataSource===DataSourceType.DATA_PLATFORM ?
                                                        dataPlatformAlgorithms:prometheusAlgorithms
                                                }
                                                callback={(
                                                    algorithm: DetectAlgorithmInput | null,
                                                    key: string
                                                ) => {
                                                    if (key===undefined) {
                                                        return
                                                    }
                                                    let algorithmsNew = {
                                                        ...algorithms
                                                    }
                                                    if (algorithm===null) {
                                                        if (
                                                            Object.keys(
                                                                algorithms
                                                            ).length===1
                                                        ) {
                                                            return
                                                        }
                                                        delete algorithmsNew[
                                                            key
                                                            ]
                                                        setAlgorithms(
                                                            algorithmsNew
                                                        )
                                                    } else if (
                                                        algorithm?.algorithm===
                                                        undefined
                                                    ) {
                                                        return
                                                    } else {
                                                        algorithmsNew[key] = {
                                                            ...algorithm,
                                                            hyperParameters: {
                                                                ...algorithm.hyperParameters,
                                                                service_name:
                                                                serviceName
                                                            }
                                                        }
                                                        setAlgorithms(
                                                            algorithmsNew
                                                        )
                                                    }
                                                }}
                                                defaultValues={
                                                    granularity < 60 * 60 * 24
                                                        ? {
                                                            daily_seasonality:
                                                                true,
                                                            service_name:
                                                            serviceName,
                                                            resource_name:
                                                            resourceName,
                                                            namespace:
                                                            namespace,
                                                            cluster:
                                                            cluster
                                                        }
                                                        :{
                                                            service_name:
                                                            serviceName,
                                                            resource_name:
                                                            resourceName,
                                                            namespace:
                                                            namespace,
                                                            cluster:
                                                            cluster
                                                        }
                                                }
                                                squadcastSlugs={
                                                    initFormData.isSuccess
                                                        ? initFormData.data
                                                            .squadSlugs
                                                        :null
                                                }
                                            />
                                        </List.Item>
                                    )
                                })}
                        </List>
                    </Col>
                </Row>
            </div>
            {selectedDataSource===DataSourceType.DATA_PLATFORM && (
                <div hidden={current!==3} className='detect-item__body'>
                    <Row gutter={12}>
                        <Col span={8}>
                            <Form.Item
                                name='measureColumn'
                                rules={[{ required: true, message: '' }]}
                                label='Measure Column'
                                tooltip={{
                                    title: 'The column containing the KPI to detect anomalies in',
                                    icon: <InfoCircleOutlined />
                                }}
                            >
                                <Select
                                    notFoundContent={
                                        dataSourceColumnsQuery.isLoading ? (
                                            <Spin
                                                className='detect-full-width-center--no-margin'
                                                size='small'
                                            />
                                        ):(
                                            <Empty
                                                image={
                                                    Empty.PRESENTED_IMAGE_SIMPLE
                                                }
                                            />
                                        )
                                    }
                                    onKeyDown={preventEnterToSubmit}
                                    disabled={!configEditable}
                                    placeholder='Measure Column'
                                    onChange={() => updateUsableColumns()}
                                    options={usableColumns
                                        .filter((value, index) => {
                                            return [
                                                'int',
                                                'bigint',
                                                'double'
                                            ].includes(value.columnType)
                                        })
                                        .map((value, index) => {
                                            return {
                                                label: value.columnName,
                                                value: value.columnName
                                            }
                                        })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name='timestampColumn'
                                rules={[{ required: true, message: '' }]}
                                label='Timestamp Column'
                            >
                                <Select
                                    notFoundContent={
                                        dataSourceColumnsQuery.isLoading ? (
                                            <Spin
                                                className='detect-full-width-center--no-margin'
                                                size='small'
                                            />
                                        ):(
                                            <Empty
                                                image={
                                                    Empty.PRESENTED_IMAGE_SIMPLE
                                                }
                                            />
                                        )
                                    }
                                    onKeyDown={preventEnterToSubmit}
                                    disabled={!configEditable}
                                    placeholder='Timestamp Column'
                                    onChange={() => updateUsableColumns()}
                                    options={usableColumns
                                        .filter((value, index) => {
                                            return [
                                                'date',
                                                'timestamp',
                                                'string'
                                            ].includes(value.columnType)
                                        })
                                        .map((value, index) => {
                                            return {
                                                value: value.columnName,
                                                label: value.columnName
                                            }
                                        })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name='mainDimensionColumn'
                                rules={[{ required: false }]}
                                label='Main Dimension column'
                                tooltip={{
                                    title: 'Main dimension for root cause analysis (RCA)',
                                    icon: <InfoCircleOutlined />
                                }}
                            >
                                <Select
                                    allowClear={true}
                                    onClear={() => {
                                        form.setFieldsValue({
                                            categoricalDimensionColumns: []
                                        })
                                    }}
                                    notFoundContent={
                                        dataSourceColumnsQuery.isLoading ? (
                                            <Spin
                                                className='detect-full-width-center--no-margin'
                                                size='small'
                                            />
                                        ):(
                                            <Empty
                                                image={
                                                    Empty.PRESENTED_IMAGE_SIMPLE
                                                }
                                            />
                                        )
                                    }
                                    onKeyDown={preventEnterToSubmit}
                                    disabled={!configEditable}
                                    placeholder='Main Dimension'
                                    onChange={() => updateUsableColumns()}
                                    options={usableColumns
                                        .filter((value, index) => {
                                            return ['string'].includes(
                                                value.columnType
                                            )
                                        })
                                        .map((value, index) => {
                                            return {
                                                value: value.columnName,
                                                label: value.columnName
                                            }
                                        })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <div>
                                <Form.Item
                                    name='categoricalDimensionColumns'
                                    rules={[{ required: false }]}
                                    label='Categorical columns'
                                    tooltip={{
                                        title: 'Categorical columns used for RCA, excluding the main dimension',
                                        icon: <InfoCircleOutlined />
                                    }}
                                >
                                    <Select
                                        notFoundContent={
                                            dataSourceColumnsQuery.isLoading ? (
                                                <Spin
                                                    className='detect-full-width-center--no-margin'
                                                    size='small'
                                                />
                                            ):(
                                                <Empty
                                                    image={
                                                        Empty.PRESENTED_IMAGE_SIMPLE
                                                    }
                                                />
                                            )
                                        }
                                        mode='multiple'
                                        onKeyDown={preventEnterToSubmit}
                                        disabled={
                                            !configEditable ||
                                            [undefined, null, ''].includes(
                                                form.getFieldValue(
                                                    'mainDimensionColumn'
                                                )
                                            )
                                        }
                                        onChange={() => updateUsableColumns()}
                                        placeholder='Categorical columns'
                                        options={usableColumns
                                            .filter((value, index) => {
                                                return ['string'].includes(
                                                    value.columnType
                                                )
                                            })
                                            .map((value, index) => {
                                                return {
                                                    value: value.columnName,
                                                    label: value.columnName
                                                }
                                            })}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <Form.Item
                                    name='continuousDimensionColumns'
                                    rules={[{ required: false }]}
                                    label='Continuous columns'
                                    tooltip={{
                                        title: 'Continuous variables that can be used as a predictor for the KPI',
                                        icon: <InfoCircleOutlined />
                                    }}
                                >
                                    <Select
                                        notFoundContent={
                                            dataSourceColumnsQuery.isLoading ? (
                                                <Spin
                                                    className='detect-full-width-center--no-margin'
                                                    size='small'
                                                />
                                            ):(
                                                <Empty
                                                    image={
                                                        Empty.PRESENTED_IMAGE_SIMPLE
                                                    }
                                                />
                                            )
                                        }
                                        mode='multiple'
                                        onKeyDown={preventEnterToSubmit}
                                        onChange={() => updateUsableColumns()}
                                        disabled={!configEditable}
                                        placeholder='Continuous columns'
                                        options={usableColumns
                                            .filter((value, index) => {
                                                return [
                                                    'double',
                                                    'bigint',
                                                    'int'
                                                ].includes(value.columnType)
                                            })
                                            .map((value, index) => {
                                                return {
                                                    value: value.columnName,
                                                    label: value.columnName
                                                }
                                            })}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                name='mainDimensionExceptions'
                                rules={[{ required: true, message: '' }]}
                                label='Main Dimension Blacklist'
                                tooltip={{
                                    title: 'Main Dimension Blacklist For Alerting',
                                    icon: <InfoCircleOutlined />
                                }}
                            >
                                <Select
                                    mode={'tags'}
                                    onKeyDown={preventEnterToSubmit}
                                    disabled={!configEditable}
                                    placeholder='Main Dimension Exceptions'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col span={initFormData.data?.moderator ? 8:12}>
                            <Form.Item
                                name='taskType'
                                rules={[{ required: true, message: '' }]}
                                label='Job Task Type'
                            >
                                <Select disabled={!configEditable}>
                                    <Option value={TaskType.EVENT_HOOK} key={0}>
                                        Event Hook
                                    </Option>
                                    {initFormData.isSuccess &&
                                        initFormData.data.moderator && (
                                            <Option
                                                value={TaskType.SCHEDULE}
                                                key={1}
                                            >
                                                Cron
                                            </Option>
                                        )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={initFormData.data?.moderator ? 8:12}>
                            {taskType===TaskType.EVENT_HOOK && (
                                <Form.Item
                                    name='taskTriggerMode'
                                    rules={[{ required: true, message: '' }]}
                                    label='Job Trigger Mode'
                                >
                                    <Select disabled={!configEditable}>
                                        <Option
                                            value={TaskTriggerMode.POST_INGEST}
                                            key={0}
                                        >
                                            Post Ingest
                                        </Option>
                                    </Select>
                                </Form.Item>
                            )}
                            {taskType===TaskType.SCHEDULE && (
                                <Form.Item
                                    name='cron'
                                    rules={[{ required: true, message: '' }]}
                                    label='Cron Schedule'
                                    validateStatus={
                                        isCronValid(cron) ? 'success':'error'
                                    }
                                    help={cronParser(cron)}
                                >
                                    <Input
                                        type='string'
                                        onKeyDown={preventEnterToSubmit}
                                        disabled={!configEditable}
                                        placeholder='Cron Schedule'
                                    />
                                </Form.Item>
                            )}
                        </Col>

                        {initFormData.isSuccess && initFormData.data.moderator && (
                            <Col span={8}>
                                <Form.Item
                                    name='computeType'
                                    rules={[{ required: false, message: '' }]}
                                    label='Compute Type'
                                >
                                    <Select disabled={!configEditable}>
                                        <Option
                                            value={ComputeType.DATABRICKS}
                                            key={0}
                                        >
                                            Databricks
                                        </Option>
                                        <Option
                                            value={ComputeType.KUBERNETES}
                                            key={1}
                                        >
                                            Kubernetes
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </div>
            )}
            <div className='detect-item__footer'>
                <Row gutter={5}>
                    <Col span={12}>
                        {current!==0 && (
                            <Button
                                style={{ width: '100%' }}
                                className='detect-button--primary'
                                onClick={() => setCurrent(current - 1)}
                            >
                                Previous
                            </Button>
                        )}
                    </Col>

                    {((current!==3 &&
                            selectedDataSource===DataSourceType.DATA_PLATFORM) ||
                        (current!==2 &&
                            selectedDataSource===
                            DataSourceType.PROMETHEUS)) && (
                        <Col span={12}>
                            <Button
                                style={{ width: '100%' }}
                                className='detect-button--primary'
                                disabled={
                                    current==1 &&
                                    selectedDataSource===
                                    DataSourceType.PROMETHEUS &&
                                    selectedQueryType==null
                                }
                                onClick={(event) => {
                                    if (current===1) {
                                        if (
                                            selectedDataSource===
                                            DataSourceType.PROMETHEUS &&
                                            selectedQueryType
                                        ) {
                                            const dataSourceParameters: {
                                                [key: string]: any
                                            } = {}
                                            Object.keys(
                                                selectedQueryType.queryParameterDefinition
                                            ).forEach((fieldName: string) => {
                                                dataSourceParameters[
                                                    fieldName
                                                    ] =
                                                    form.getFieldValue(
                                                        fieldName
                                                    )
                                            })
                                            dataSourceParameters['query_id'] = selectedQueryType.queryId
                                            addDataSourceRequest({
                                                dataSourceType:
                                                DataSourceType.PROMETHEUS,
                                                dataSourceParameters:
                                                dataSourceParameters
                                            })
                                            setFetchingDataSourceSupportedAlerts(
                                                true
                                            )
                                            dataSourceAlertsRequest({
                                                dataSourceType:
                                                selectedDataSource,
                                                queryType:
                                                selectedQueryType?.queryType
                                            })
                                        } else if (
                                            selectedDataSource===
                                            DataSourceType.DATA_PLATFORM
                                        ) {
                                            setDataSourceStatus('validating')
                                            addDataSourceRequest({
                                                dataSourceType:
                                                DataSourceType.DATA_PLATFORM,
                                                dataSourceParameters: {
                                                    dataSourceItemExtId:
                                                        form.getFieldValue(
                                                            'dataSourceItemExtId'
                                                        ),
                                                    dataSourceItemPath: ''
                                                }
                                            })
                                        }
                                    }
                                    setCurrent(current + 1)
                                }}
                            >
                                Next
                            </Button>
                        </Col>
                    )}

                    {((current===3 &&
                                selectedDataSource===DataSourceType.DATA_PLATFORM) ||
                            (current===2 &&
                                selectedDataSource===
                                DataSourceType.PROMETHEUS)) &&
                        props.mode!==ItemMode.View && (
                            <Col span={12}>
                                <Button
                                    style={{ width: '100%' }}
                                    type='primary'
                                    htmlType='submit'
                                >
                                    {props.mode}
                                </Button>
                            </Col>
                        )}
                </Row>
            </div>
        </Form>
    )
}

export default KPIConfig
