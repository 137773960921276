import G6, { Graph } from '@antv/g6'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
    getResourceDependency,
    getUdaanResourceList,
} from '../../../../lib/api/QueryApis/Common'
import cosmos from '../../../../assets/icons/cosmos.svg'
import redis from '../../../../assets/icons/redis.svg'
import sql from '../../../../assets/icons/sql.svg'
import goodIcon from '../../../../assets/icons/good-icon.svg'
import goodIconService from '../../../../assets/icons/good-icon-service.svg'
import { Col, Row, Select, Spin } from 'antd'

export interface ResourceDependencyGraphProps {
    cluster: string
    namespace: string
    serviceName: string
    resourceName: string | null
}

const ResourceDependencyGraph = ({
    cluster,
    namespace,
    serviceName,
    resourceName,
}: ResourceDependencyGraphProps) => {
    const history = useHistory()
    const container = useRef<null | HTMLDivElement>(null)

    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [nodes, setNodes] = useState<any[]>([])
    const [edges, setEdges] = useState<any[]>([])

    const [graphObject, setGraphObject] = useState<Graph | null>(null)

    const [selectedResourceName, setSelectedResourceName] = useState<
        string | null
    >(resourceName)
    const [resourceList, setResourceList] = useState<string[]>([])
    const [resourceListStatus, setResourceListStatus] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [resourceListIsLoading, setResourceListIsLoading] =
        useState<boolean>(false)

    const [dependencyDataQuery] = useMutation(getResourceDependency, {
        onSuccess: (data) => {
            const processesInfo = data?.dependentServices.processes
            let nodesList: any[] = []
            let edgesList: { source: string; target: string }[] = []
            Object.keys(processesInfo).forEach((processName: string) => {
                nodesList.push({
                    id: processName,
                    img: getServiceImagePath(processName, processName == serviceName),
                    label: processName,
                    latency: processesInfo[processName].latency
                        ? `${processesInfo[processName].latency}ms`
                        : '',
                    availability: `${processesInfo[processName].availability}%`,
                })
            })
            setNodes(nodesList)
            Object.keys(data.dependentServices.adjacencyList).forEach(
                (processName: string) => {
                    const children: string[] =
                        data.dependentServices.adjacencyList[processName]
                    children.forEach((childProcessName: string) => {
                        edgesList.push({
                            source: processName,
                            target: childProcessName,
                        })
                    })
                }
            )
            setEdges(edgesList)
            setIsLoading(false)
        },
        onError: (error) => {
            console.log(
                'There has been an error while fetching dependency graph: ',
                error
            )
            setIsLoading(false)
        },
    })

    const [resourceListRequest] = useMutation(getUdaanResourceList, {
        onSuccess: (resp) => {
            setResourceList(resp)
            setResourceListIsLoading(false)
            setResourceListStatus(false)
        },
        onError: (error) => {
            setResourceList([])
            setResourceListStatus(true)
            setResourceListIsLoading(false)
        },
    })

    useEffect(() => {
        if (selectedResourceName != null) {
            setIsLoading(true)
            dependencyDataQuery({
                cluster: cluster,
                namespace: namespace,
                serviceName: serviceName,
                resourceName: selectedResourceName,
            })
        }
        if (resourceList.length == 0) {
            resourceListRequest({
                cluster: cluster,
                namespace: namespace,
                serviceName: serviceName,
            })
            setResourceListIsLoading(true)
        }
    }, [selectedResourceName])

    useEffect(() => {
        if (
            resourceList &&
            !(selectedResourceName || selectedResourceName == '') &&
            resourceList.length > 0
        ) {
            setSelectedResourceName(resourceList[0])
        }
    }, [resourceList])

    const getServiceImagePath = (serviceName: string, isCurrentService: boolean): string => {
        switch (serviceName) {
            case 'redis':
                return redis
            case 'sql':
                return sql
            case 'cosmos':
                return cosmos
            default:
                if (isCurrentService) return goodIconService
                else return goodIcon
        }
    }

    const dagData = {
        nodes: nodes,
        edges: edges,
    }

    useLayoutEffect(() => {
        if (!isLoading && container.current && dagData.nodes.length > 0) {
            setWidth(container.current.scrollWidth)
            setHeight(container.current.scrollHeight)

            if (graphObject != null) graphObject.destroy()

            const graph = new G6.Graph({
                fitView: true,
                fitViewPadding: 5,
                container: container.current ? container.current : 'container',
                width,
                height,
                modes: {
                    default: [
                        'drag-canvas',
                        'zoom-canvas',
                        'drag-node',
                        {
                            type: 'tooltip',
                            formatText(model) {
                                const text = []
                                if (model.availability && model.availability !== '') {
                                    text.push(`Availability: ${parseFloat(model.availability as string).toFixed(2)}%`)
                                }
                                if (model.latency !== '') {
                                    text.push(`Latency: ${parseFloat(model.latency as string).toFixed(2)}ms`)
                                }
                                return text.join('\n\n')
                            },
                            offset: 30,
                        },
                    ],
                },
                defaultNode: {
                    type: 'image',
                    size: [120, 120],
                    clipCfg: {
                        show: false,
                        type: 'circle',
                    },
                },
                defaultEdge: {
                    type: 'cubic',
                    style: {
                        endArrow: true,
                        lineWidth: 4
                    },
                },
                animate: true,
                layout: {
                    type: 'dagre',
                },
            })
            graph.data(dagData)
            graph.render()

            graph.on('node:click', (evt) => {
                if (evt.target.cfg.name == 'kpi-name') {
                    const kpiId = evt.item?._cfg?.id
                    history.push({ pathname: `/kpi/${kpiId}` })
                }
            })

            setGraphObject(graph)
        }
    }, [dagData.nodes, dagData.edges, isLoading])

    return (
        <div style={{ backgroundColor: 'white', marginTop: '10px' }}>
            <Row>
                <Col span={12}>
                    <h3
                        style={{
                            marginLeft: '10px',
                            marginTop: '5px',
                        }}
                    >
                        Resource Dependency Graph
                    </h3>
                </Col>
                <Col
                    offset={4}
                    span={4}
                    style={{
                        marginRight: '10px',
                        marginTop: '5px',
                    }}
                >
                    <Select
                        showSearch
                        onClick={() => {}}
                        value={selectedResourceName}
                        onChange={(value) => setSelectedResourceName(value)}
                        status={resourceListStatus ? 'error' : ''}
                        loading={resourceListIsLoading}
                        style={{
                            minWidth: '200px',
                        }}
                        placeholder="Select a resource name"
                        options={resourceList.map((it) => {
                            return { value: it, label: it }
                        })}
                        dropdownMatchSelectWidth={false}
                    />
                </Col>
            </Row>
            {isLoading ? (
                <div
                    style={{
                        height: '60vh',
                        width: '86vw',
                        justifyItems: 'center',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <Spin
                        tip="  Loading..."
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            verticalAlign: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    />
                </div>
            ) : (
                <div
                    id="original"
                    style={{ height: '60vh', width: '86vw' }}
                    ref={container}
                ></div>
            )}
        </div>
    )
}

export default ResourceDependencyGraph
