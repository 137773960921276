export enum ItemAccessLevel {
    OWNER = 'OWNER',
    CONTRIBUTOR = 'CONTRIBUTOR',
    READER = 'READER',
}

export enum ItemScope {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
}

export interface LoginResponse {
    access_token: string
    expires_in: number
    refresh_token: string
    scope: string
    token_type: string
    id_token: string
}


export interface RefreshTokenResponse {
    access_token: string
    expires_in: number
    scope: string
    token_type: string
    id_token: string
}

export interface WebWorkerData {
    file_path: string
    authToken: any
    prefix: string
    path: string
    sample: boolean
}
