import { Empty, List, notification, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
    getDetectItemSummary,
    getMultipleItemSummaries
} from '../../../../lib/api/QueryApis/Common'
import { DetectCollectionGraphNode } from '../../../../lib/api/RequestTypes/Common'
import { DetectItemSummary } from '../../../../lib/api/ResponseTypes/Common'
import { sendNotification } from '../../../../lib/utils/common'
import { getUseQueryOptions, humanize } from '../../Utils'
import { CreateGraphCard } from './CreateGraphCard'
import { Card, Divider, Select, Typography } from 'antd'
import {
    DatabaseOutlined,
    IdcardOutlined,
    UserOutlined
} from '@ant-design/icons'
import { DefaultOptionType } from 'antd/lib/select'

const { Text, Title } = Typography
const { Option } = Select

interface DetectCreateCollectionGraphProps {
    value?: Map<string, string[]>
    onChange?: (parentMappings: Map<string, string[]>) => void
    kpiItemIds: Array<string>
}

export const CreateCollectionGraph = (
    {
        kpiItemIds,
        // getGraphInfo,
        value,
        onChange
    }: DetectCreateCollectionGraphProps) => {
    const [parentMappings, setParentMappings] = useState<Map<string, string[]>>(
        value || new Map<string, string[]>()
    )
    const [possibleParents, setPossibleParents] = useState<
        Map<string, string[]>
    >(new Map<string, string[]>())
    const [kpiItemSummaryList, setKPIItemSummaryList] = useState<
        Map<string, DetectItemSummary>
    >(new Map())

    const kpiSummaries = useQuery(
        [
            {
                itemExtIds: kpiItemIds
            }
        ],
        getMultipleItemSummaries,
        getUseQueryOptions(1)
    )

    useEffect(() => {
        let itemSummaries = new Map<string, DetectItemSummary>()
        kpiSummaries.data?.forEach((kpiItemSummary) => {
            itemSummaries.set(kpiItemSummary.itemExternalId, kpiItemSummary)
        })
        setKPIItemSummaryList(itemSummaries)
    }, [kpiSummaries.isSuccess])

    const onParentListUpdate = (kpiItemId: string, parent: string[]) => {
        let tempparentMapping = parentMappings
        tempparentMapping.set(kpiItemId, parent)
        setParentMappings(tempparentMapping)
        if (typeof onChange==='function') onChange(tempparentMapping)
        updatePossibleParents()
    }

    // const getAllowedKPISummaries = (
    // 	kpiItemId: string
    // ): Array<DetectItemSummary> => {
    // 	const filteredList = kpiSummaries.data?.filter((itemSummary) =>
    // 		parentMappings.has(kpiItemId)
    // 			? itemSummary.itemExternalId !== kpiItemId &&
    // 			  !parentMappings
    // 					.get(itemSummary.itemExternalId)
    // 					?.includes(kpiItemId)
    // 			: itemSummary.itemExternalId !== kpiItemId
    // 	)
    // 	if (filteredList) return Array.from(filteredList)
    // 	else return Array.from([])
    // }

    const updatePossibleParents = () => {
        if (parentMappings && kpiSummaries.isSuccess) {
            const tempPossibleParents = new Map<string, string[]>()
            kpiItemIds.forEach((itemId) => {
                tempPossibleParents.set(
                    itemId,
                    kpiItemIds.filter((tempItemId) => itemId!=tempItemId)
                )
            })
            tempPossibleParents.forEach(
                (possibleParentList, kpiIdInContext) => {
                    tempPossibleParents.set(
                        kpiIdInContext,
                        kpiItemIds.filter((itemId) => {
                            return (
                                itemId!=kpiIdInContext &&
                                !parentMappings
                                    .get(itemId)
                                    ?.includes(kpiIdInContext)
                            )
                        })
                    )
                }
            )
            setPossibleParents(tempPossibleParents)
        }
    }

    useEffect(() => {
        updatePossibleParents()
    }, [kpiSummaries.isSuccess, value])

    const getParentOptionsList = (kpiItemId: string): DefaultOptionType[] => {
        const kpiSummaries = possibleParents
            .get(kpiItemId)
            ?.map(
                (itemId) => kpiItemSummaryList.get(itemId)!
            ).filter((it) => {
                return it!==undefined
            })
        let options = kpiSummaries?.map((value) => {
            return {
                value: value.itemExternalId,
                label: '[' + value.itemExternalId + '] ' + value.itemName
            }
        })
        return options ? options:[]
    }

    if (kpiSummaries.isSuccess) {
        return (
            <List className='detect-list-scroll'>
                {kpiItemIds.map((kpiItemId, idx) => (
                    <List.Item key={idx}>
                        {kpiItemSummaryList.has(kpiItemId) && (
                            <Card
                                hoverable
                                size='small'
                                className='detect-item-card'
                            >
                                <div
                                    className='detect-item-card__body'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className='detect-item-card__title'>
                                        <Title
                                            level={4}
                                            style={{ margin: 0, width: '100%' }}
                                        >
                                            {kpiItemSummaryList.get(kpiItemId)!.itemName}
                                        </Title>
                                        <Text type='secondary'>
                                            Problem Area:{' '}
                                            {kpiItemSummaryList.get(kpiItemId)!.problemArea}
                                        </Text>
                                    </div>
                                    <div style={{ minWidth: '280px' }}>
                                        <Select
                                            mode='multiple'
                                            allowClear
                                            showArrow
                                            dropdownMatchSelectWidth
                                            style={{ minWidth: '260px' }}
                                            placeholder='Select immediate parents'
                                            value={parentMappings.get(kpiItemId)!}
                                            onChange={(selectedValues: string[]) => {
                                                onParentListUpdate(kpiItemId, selectedValues)
                                            }}
                                            size='large'
                                            maxTagCount='responsive'
                                        >
                                            {getParentOptionsList(
                                                kpiItemId
                                            )!.map((value) => {
                                                return (
                                                    <Option
                                                        style={{ wdith: '100%' }}
                                                        value={value.value}
                                                        key={value.value}
                                                    >
                                                        {value.label}
                                                        {/* {'[' +
																value.itemExternalId +
																'] ' +
																value.itemName} */}
                                                    </Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <Divider
                                    style={{ margin: '0px 0px 5px 0px' }}
                                />
                                <div className='detect-item-card__footer'>
									<span>
										<span className='detect-card-footer__key'>
											<IdcardOutlined /> ID:
										</span>
										<span className='detect-card-footer__value'>
											{' '}
                                            {kpiItemSummaryList.get(kpiItemId)!.itemExternalId}
										</span>
									</span>
                                    <span>
										<span className='detect-card-footer__key'>
											<UserOutlined /> Created By:
										</span>
										<span className='detect-card-footer__value'>
											{' '}
                                            {kpiItemSummaryList.get(kpiItemId)!.createdBy}
										</span>
									</span>
                                    <span>
										<span className='detect-card-footer__key'>
											<DatabaseOutlined /> Data Source:
										</span>
										<span className='detect-card-footer__value'>
											{' '}
                                            {humanize(
                                                kpiItemSummaryList.get(kpiItemId)
                                                    ? kpiItemSummaryList.get(kpiItemId)!.dataSourceType:''
                                            )}
										</span>
									</span>
                                </div>
                            </Card>
                        )}
                    </List.Item>
                ))}
            </List>
        )
    } else if (kpiSummaries.isError) {
        sendNotification(notification, 'error', 'Can\'t fetch items', '')
        return (
            <div className='detect-full-center'>
                <Empty description={<span>Error Occurred. No Data</span>} />
            </div>
        )
    } else {
        return (
            <div className='detect-full-center'>
                <Spin tip='Loading' />
            </div>
        )
    }
}
