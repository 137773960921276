import {
    createStyles,
    LinearProgress,
    Theme,
    withStyles,
} from "@material-ui/core";

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            backgroundColor: '#1a90ff',
        },
    }),
)(LinearProgress);

export default BorderLinearProgress
