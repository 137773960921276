import React from 'react'
import { Button } from 'antd'

export default class ErrorBoundary extends React.Component {
  state = { error: { message: '' } }
  static getDerivedStateFromError(error: any) {
    return { error }
  }
  componentDidCatch() {
    // log the error to the server
  }
  tryAgain = () => {
    window.location.reload() // Hack Alert should be handled within the app
  }

  render() {
    return this.state.error.message !== '' ? (
      <div>
        There was an error. <Button onClick={this.tryAgain}>Try again</Button>
        <pre style={{ whiteSpace: 'normal' }}>{this.state.error.message}</pre>
      </div>
    ) : (
      this.props.children
    )
  }
}
