import { useQuery } from 'react-query'
import React, { useEffect, useState } from 'react'
import { getUseQueryOptions } from '../../Utils'
import { Breadcrumb, Modal, Pagination, Typography } from 'antd'
import KPISummary from './KPISummary'
import { ItemStatus } from '../../../../lib/utils/common'
import DetectModerateItemsList from './ModerateKPIList'
import './index.css'
import { getDetectRequestedCount } from '../../../../lib/api/QueryApis/Common'

const { Title } = Typography


const AdminDashboard = () => {
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)
    const [total, setTotal] = useState(10)
    const [refresh, setRefresh] = useState(false)
    const [showModerate, setShowModerate] = useState(false)
    const [currItemAndConfig, setCurrItemAndConfig] = useState<any[]>(['', '', ItemStatus.REQUESTED])

    const itemCountQuery = useQuery([
            { key: 'value' }],
        getDetectRequestedCount,
        getUseQueryOptions(1)
    )
    useEffect(() => {
        if (currItemAndConfig[0]!=='' && currItemAndConfig[1]!=='') setShowModerate(true)
    }, [currItemAndConfig])

    useEffect(() => {
        if (itemCountQuery.isSuccess) setTotal(itemCountQuery.data.count)
    }, [itemCountQuery])

    return (
        <>
            <div>
                <div className='detect-admin__body'>
                    <DetectModerateItemsList
                        limit={limit} offset={offset} refresh={refresh}
                        onCardClick={(itemExtId: string, configId: string, itemStatus: ItemStatus) => {
                            setCurrItemAndConfig([itemExtId, configId, itemStatus])
                        }} />
                </div>
                <div className='detect-admin__footer'>
                    <Pagination
                        style={{ paddingTop: '10px' }}
                        showSizeChanger
                        showQuickJumper
                        total={total} onChange={(page, pageSize) => {
                        setLimit(pageSize)
                        setOffset(pageSize * (page - 1))
                    }} />
                </div>
            </div>
            <Modal
                title='Item Summary'
                visible={showModerate}
                footer={null}
                width='fit-content'
                onCancel={() => setShowModerate(false)}
                bodyStyle={{ padding: '0px' }}>
                <KPISummary
                    itemExtId={currItemAndConfig[0]}
                    configId={currItemAndConfig[1]}
                    itemStatus={currItemAndConfig[2]}
                    callback={() => {
                        setRefresh(!refresh)
                        setCurrItemAndConfig(['', '', ItemStatus.REQUESTED])
                        setShowModerate(false)
                    }} />
            </Modal>
        </>
    )
}

export default AdminDashboard
