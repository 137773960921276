import {
    ItemScope,
    ItemAccessLevel,
} from '../../../types/DataPlatformTypes/CommonTypes'
import { ItemStatus } from '../../utils/common'
import {
    DataSourceType,
    DetectAlgorithmInput,
    DetectCollectionGraphNode,
    SeverityLevel,
} from '../RequestTypes/Common'

/* Common */

export type APIState = 'SUCCESS' | 'FAILURE'

export interface APIResponse {
    status: APIState
    response: any
}

/* DP: User Info */

export interface DetectUserProfile {
    emailId: string
    fullName: string
    iconUrl: string
    createdAt: string
    lastUpdatedAt: string
}

export interface GetUserScopeResponse extends APIResponse {
    response: Array<string>
}

export interface GetUserInfoAPIResponse extends APIResponse {
    response: Array<UserInfo>
}

export interface ListUsersLikeAPIResponse extends APIResponse {
    response: Array<ListUsersLikeAPIResponseData>
}

export interface ListUsersLikeAPIResponseData {
    user_id: string
    email: string
}

export interface UserInfo {
    email: string
    user_type: string
    team_name: string
    privilege_level: number
    features_list: string
}

export interface Tag {
    versionId: string
    tagsId: string
    itemId: string
    itemType: string
    createdAt: string
    createdBy: string
    tagExternalId: string
    tagName: string
}

export enum ExecutionStatus {
    RUN_TRIGGERED = 'RUN_TRIGGERED',
    RUN_SUBMITTED = 'RUN_SUBMITTED',
    RUN_SUBMIT_FAILED = 'RUN_SUBMIT_FAILED',
    RUN_STARTED = 'RUN_STARTED',
    RUN_SUCCESSFUL = 'RUN_SUCCESSFUL',
    RUN_FAILURE = 'RUN_FAILURE',
}

export interface DetectItemSummary {
    itemExternalId: string
    itemName: string
    problemArea: string
    createdBy: string
    createdAt: number
    configId: string
    resultCreatedAt: number | null
    resultSummary: Summary | null
    dataSourceType: DataSourceType
    configStatus: ItemStatus
    itemStatus: ItemStatus
    itemScope: ItemScope
    result: any | null
    incidents: any | null
    permissions: UserPermission[] | null
    promQLQuery: string[]|null
}

export interface DetectGroupItemSummary {
    itemExternalId: string
    itemName: string
    problemArea: string
    createdBy: string
    createdAt: number
    groupVersionId: string
    configStatus: ItemStatus
    itemStatus: ItemStatus
    itemScope: ItemScope
    description: string
    graph: { [key: string]: DetectCollectionGraphNode }
    enclosingGroupIds: string[]
    leaderKPI: string
    creatorIcon: string
    creatorName: string
    enclosingGroupNames: any[] | null
    permissions: UserPermission[] | null
}

export interface DetectConfigDataSourceSummary {
    itemExternalId: string
    configId: string
    dataSourceId: string
    dataSourceType: DataSourceType
    configStatus: ItemStatus
    dataSourceParameters: { [key: string]: any }
}

export interface CollectionKPIData {
    itemExternalId: string
    groupVersionId: string
    kpiItemIdList: string[]
    kpiItemSummaryList: DetectItemSummary[]
}

export interface ProjectCollectionData {
    itemExternalId: string
    groupVersionId: string
    collectionItemIdList: string[]
    collectionItemSummaryList: DetectGroupItemSummary[]
}

export interface DetectExecutionLog {
    anomalyId: string | null
    runId: string
    itemId: string
    configId: string
    updatedAt: number
    executionStatus: ExecutionStatus
    errorMessage: string
}

export interface DataSourceAddResponse {
    dataSourceId: string
}

export interface DataSourceColumn {
    columnName: string
    columnType: string
}

export interface DataSourceQuery {
    dataSourceType: DataSourceType
    queryFullName: string
    queryId: string
    queryParameterDefinition: { [key: string]: DynamicFormFieldDefinition }
    queryTemplateString: string
    queryType: string
}

export interface DetectUpdateItemConfigResponse {
    configId: string
    needPermission: string
}

export interface DetectUpdateGroupItemConfigResponse {
    groupVersionId: string
    needPermission: string
}

export interface Drilldown {
    [key: string]: {
        main: string
        drilldown: {
            [key: string]: {
                [key: string]: string
            }
        }
        covariates: {
            [key: string]: string
        }
    }
}

export interface Summary {
    start: number
    end: number
    startDate: number
    endDate: number
}

export interface DetectResultResponse {
    itemId: string
    configId: string
    anomalyId: string
    runId: string
    drilldown: Drilldown
    summary: Summary
    createdAt: number
    measureColumn: string
    timestampColumn: string
    granularity: number
    categoricalDimensionColumns: string[]
    continuousDimensionColumns: string[]
    mainDimensionColumn: string | null
    algorithms: DetectAlgorithmInput[]
}

export interface CanModerateResponse {
    canModerate: boolean
}

export interface PendingRequestsResponse {
    pendingRequests: boolean
}

export interface CountResponse {
    count: number
}

export interface UserPermission {
    createdBy: string
    createdAt: string
    updatedAt: string
    accessLevel: ItemAccessLevel
    username: string | null
    email: string
    avatar: string
    itemId: string
}

export interface ForecastResponse {
    lastAnomaly: number | null
    forecasts: any[]
}

export interface LastDataResponse {
    itemName: string
    forecasts: any[]
    measureColumn: string
    timestampColumn: string
    mainDimensionColumn: string
}

export interface DynamicFormFieldDefinition {
    type: string
    default: any
    options?: any[]
    range?: any[]
}

export interface DetectAlgorithm {
    algorithm: string
    algorithmFullName: String
    hyperParameterDefinition: { [key: string]: DynamicFormFieldDefinition }
    sensitivityMapping: { [key: number]: any } | null
    alertQueryTemplateString: string
}

export interface PrometheusMetric {
    metric: string
    metricFullName: string
    metricParameterDefinition: { [key: string]: DynamicFormFieldDefinition }
}

export interface DetectInitFormData {
    problemAreas: string[]
    algorithms: { [key: string]: DetectAlgorithm }
    moderator: boolean
    squadSlugs: any[]
}

export interface UserInfo {
    name: string
    icon: string | null
    emailId: string
}

export interface ResourceDependenciesResponse {
    availability: string
    latencyInMillis: number
    issueCount: number
    cluster: string
    namespace: string
    resourceName: string
    serviceName: string
    dependentServices: {
        adjacencyList: any
        processes: { [key: string]: any }
        problematicAdjacencyList: any
    }
    status: string
}

export interface KPIFiringAlertsResponse {
    kpiItemExternalId: string
    configId: string
    rules: PrometheusRule[]
}

export interface CollectionHealthResponse {
    itemExternalId: string,
    groupVersionId: string,
    incidents: KPIIncidentInfo[],
    rules: PrometheusRule,
    severity: SeverityLevel,
    criticalFunctionalIncidentsCount: number,
    warningFunctionalIncidentsCount: number,
    infoFunctionalIncidentsCount: number,
    criticalNonFunctionalIncidentsCount: number,
    warningNonFunctionalIncidentsCount: number,
    infoNonFunctionalIncidentsCount: number
}

export interface ProjectHealthResponse {
    itemExternalId: string,
    groupVersionId: string,
    collectionHealthList: CollectionHealthResponse[],
    severity: SeverityLevel,
    criticalNonFunctionalRulesCount: number,
    criticalFunctionalIncidentsCount: number,
    warningNonFunctionalRulesCount: number,
    warningFunctionalIncidentsCount: number,
    infoNonFunctionalRulesCount: number,
    infoFunctionalIncidentsCount: number
}

export interface KPIIncidentInfo {
    itemExternalId: string,
    configId: string,
    itemName: String,
    incidents: any | null,
    severity: SeverityLevel
}

export interface PrometheusRule {
    name: string
    query: string
    state: string
    type: string
    annotations: { [key: string]: any }
    evaluationTime: string
    health: string
    labels: { [key: string]: any }
    lastEvaluation: string
    alerts: PrometheusRuleAlert[]
}

export interface PrometheusRuleAlert {
    activeAt: string
    annotations: { [key: string]: any }
    labels: { [key: string]: any }
    state: string
    value: number
}
/* Tracer Interfaces */
export interface Measures {
    [name: string]: number;
}
export interface HttpRequestProperties {
    telemetry_type?: string;
    loc?: string;
    userPrincipal?: string;
    req_container?: string;
    req_pod?: string;
    parent_id_for_children?: string;
    [name: string]: any;
}
export interface HttpRequest {
    serviceName: string;
    parentId: string;
    method: string;
    path: string;
    host: string;
    statusCode: number;
    properties: HttpRequestProperties;
    timestamp: string;
    duration: number;
    namespace: string;
    pod: string;
    container: string;
    traceId: string;
    queryParams?: {[key: string]: string} | null;
    clientIp: string;
    userAgent: string;
    measures: Measures;
}
export interface HttpRequestNode {
    request: HttpRequest;
    children: HttpRequestNode[];
}
export interface ExceptionProperties {
    telemetry_type?: string;
    loc?: string;
    parent_id_for_children?: string;
    [name: string]: any;
}

export interface Exception {
    serviceName: string;
    traceId: string;
    parentId: string;
    message: string;
    type: string;
    outerType: string;
    outerMethod: string;
    innerType: string;
    innerMethod: string;
    stackTrace: string;
    properties: ExceptionProperties;
    timestamp: string;
    measures: Measures;
}

export interface ExceptionCount {
    exceptionMsg: string,
    count: number,
    statusCode: number,
    key? : number
}
export interface ExceptionTraces {
    traceId: string,
    timestamp: string
}
/* Tracer Interfaces */