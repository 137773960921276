import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
// import DetectChartCard from './DetectChartCard'
import {
    Button,
    Card,
    Col,
    Empty,
    Modal,
    notification,
    Popconfirm,
    Row,
    Select,
    Space,
    Spin,
    Tabs,
    Typography
} from 'antd'
import { useMutation, useQuery } from 'react-query'
import {
    getDetectGroupItem,
    getDetectGroupLeaderData,
    getItemUserPermissions
} from '../../../../lib/api/QueryApis/Common'
import ItemPermissions from '../../../Common/ItemPermissions'
import { deleteDetectGroupItem } from '../../../../lib/api/MutationApis/Common'
import {
    AreaChartOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    ShareAltOutlined
} from '@ant-design/icons'
import '../../index.css'
import { MLP_DETECT_CREATE, sendNotification } from '../../../../lib/utils/common'
import useScopes from '../../../../hooks/useScopes'
import NoMatch from '../../../Common/NoMatch'
import CollectionGraphComponent from './CollectionGraphComponent'
import { DetectGroupItem } from '../../../../lib/api/RequestTypes/Common'
import DetectCollectionConfig, { ItemMode } from '../CollectionConfig'
import { getUseQueryOptions } from '../../Utils'
import LastDataTable from '../../../Common/LastDataTable'
import { CustomAvatarGroup } from '../../../Common/CustomAvatarGroup'

const { Text } = Typography
const { Option } = Select
const { TabPane } = Tabs

const CollectionPage = () => {
    const { itemExtId } = useParams<{ itemExtId: string }>()

    const [showItemPermissions, setShowItemPermissions] =
        useState<boolean>(false)
    const [showUpdateItem, setShowUpdateItem] = useState<boolean>(false)
    const [logModal, setLogModal] = useState([false, <span />, <span />])
    const [hidden, setHidden] = useState<boolean>(false)
    const history = useHistory()
    const userPermissions = useScopes()

    const permissionsQuery = useQuery(
        [{ itemExtId: itemExtId }],
        getItemUserPermissions,
        getUseQueryOptions(1)
    )

    const [deleteItemRequest] = useMutation(deleteDetectGroupItem, {
        onSuccess: () => {
            sendNotification(notification, 'success', `Item ${itemExtId} deleted`, '')
            history.push('/')
        },
        onError: () => {
            sendNotification(notification, 'error', `Failed to delete ${itemExtId}`, '')
        }
    })

    const itemQuery = useQuery(
        [
            {
                itemExtId: itemExtId,
                groupVersionId: 'latest'
            }
        ],
        getDetectGroupItem,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: 1000 * 60,
            retry: 1
        }
    )

    const lastDataQuery = useQuery(
        [{
            itemExtId: itemExtId,
            groupVersionId: 'latest',
            'key': 'collectionLeaderData'
        }],
        getDetectGroupLeaderData,
        getUseQueryOptions(1)
    )


    let updateItemComponent = <></>
    let lastDataComponent = <></>
    let collectionGraphComponent = <></>

    if (lastDataQuery.isSuccess && lastDataQuery.data.forecasts.length>0) {
        lastDataComponent =
            <Card
                size={'small'}
                hidden={hidden}
                style={{ width: '100%', height: '100%' }}
                title={lastDataQuery.data.itemName + ' | '+ lastDataQuery.data.forecasts[0][lastDataQuery.data.timestampColumn]}
                bordered={true}
                extra={
                    <CloseCircleOutlined onClick={() => {
                        setHidden(!hidden)
                    }} />
                }

            >
            <LastDataTable
                itemName={lastDataQuery.data.itemName}
                forecasts={lastDataQuery.data.forecasts}
                measureColumn={lastDataQuery.data.measureColumn}
                timestampColumn={lastDataQuery.data.timestampColumn}
                mainDimensionColumn={lastDataQuery.data.mainDimensionColumn}
            /></Card>
    }

    if (itemQuery.isError || lastDataQuery?.data?.forecasts.length==0) {
        updateItemComponent = (
            <div className='detect-full-width-center'>
                <Empty />
            </div>
        )
    } else if (itemQuery.isLoading) {
        updateItemComponent = (
            <div className='detect-full-width-center'>
                <Spin tip='loading'></Spin>
            </div>
        )
    } else if (itemQuery.isSuccess) {
        updateItemComponent = (
            <DetectCollectionConfig
                itemConfig={itemQuery.data}
                itemExtId={itemExtId}
                mode={ItemMode.Update}
                callback={() => setShowUpdateItem(false)}
            />
        )
    }


    collectionGraphComponent = (
        <div className='detect-item-dashboard-card' style={{ width: '100%', height: '100%' }}>
            {itemQuery.isSuccess && (
                <CollectionGraphComponent
                    collectionItemId={itemExtId}
                    collectionItemInfo={itemQuery.data as DetectGroupItem}
                ></CollectionGraphComponent>
            )}
        </div>
    )


    if (itemQuery.isError && itemQuery.error.message==='Request Error') {
        return <NoMatch />
    } else if (itemQuery.isLoading) {
        return (
            <div
                className='detect-full-width-center'
                style={{ height: '100%' }}
            >
                <Spin tip='loading'></Spin>
            </div>
        )
    }

    return (
        <>
            <div className='detect-item-dashboard'>
                <div
                    className='detect-item-dashboard-card'
                    style={{
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    {itemQuery.isSuccess && (
                        <span style={{ float: 'left' }}>
							<span>
								<AreaChartOutlined
                                    style={{
                                        color: '#722ED1',
                                        fontSize: '30px',
                                        marginLeft: '10px',
                                        marginRight: '5px'
                                    }}
                                />
							</span>
							<span className='detect-item-name'>
								{itemQuery.data.itemName}{' '}
							</span>
						</span>
                    )}
                    {userPermissions?.data?.includes(MLP_DETECT_CREATE) && (
                        <span style={{ float: 'right' }}>
							<Space>

								<CustomAvatarGroup
                                    permissions={null}
                                    itemExternalId={itemExtId}
                                    isGroupItem={true}
                                />

								<Button
                                    className='detect-button--primary'
                                    onClick={() => setShowItemPermissions(true)}
                                >
									<ShareAltOutlined />
									Share
								</Button>
								<Button
                                    className='detect-button--primary'
                                    onClick={() => {
                                        setShowUpdateItem(true)
                                    }}
                                >
									<EditOutlined /> Edit
								</Button>
								<Popconfirm
                                    title='Are you sure to delete this item?'
                                    onConfirm={() => {
                                        deleteItemRequest({
                                            itemExtId: itemExtId
                                        })
                                    }}
                                    placement='bottom'
                                    okText='Yes'
                                    cancelText='No'
                                >
									<Button
                                        className='detect-button--danger'
                                        danger
                                    >
										<DeleteOutlined /> Delete
									</Button>
								</Popconfirm>
							</Space>
						</span>
                    )}
                </div>
                <Row style={{ width: '100%', height: '100%' }}>
                    <Col span={(lastDataQuery.isSuccess && lastDataQuery.data.forecasts.length > 0) ? 16:24}
                         className='detect-item-dashboard-card'
                         style={{
                             width: '100%',
                             height: '100%',
                             padding: '5px',
                             display: 'flex',
                             flexDirection: 'row',
                             justifyContent: 'space-between'
                         }}
                    >
                        {collectionGraphComponent}
                    </Col>
                    <Col
                        hidden={!(lastDataQuery.isSuccess && lastDataQuery.data.forecasts.length > 0)}
                        span={(lastDataQuery.isSuccess && lastDataQuery.data.forecasts.length > 0) ? 8:0}
                        style={{
                            height: '100%',
                            backgroundColor: 'white',
                        }}>
                        {lastDataComponent}
                    </Col>
                </Row>
            </div>

            <Modal
                title='Item Permissions'
                visible={showItemPermissions}
                width='fit-content'
                footer={null}
                bodyStyle={{ padding: '0px' }}
                onCancel={() => setShowItemPermissions(false)}
            >
                <ItemPermissions itemExtId={itemExtId} isGroupItem={true} />
            </Modal>

            <Modal
                title='Update Item'
                visible={showUpdateItem}
                footer={null}
                width='fit-content'
                onCancel={() => setShowUpdateItem(false)}
                bodyStyle={{ padding: '0px' }}
            >
                {updateItemComponent}
            </Modal>
            <Modal
                onOk={() => setLogModal([false, <span />, <span />])}
                visible={logModal[0] as boolean}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={() => setLogModal([false, <span />, <span />])}
                title={logModal[1]}
            >
                {logModal[2]}
            </Modal>
        </>
    )
}

export default CollectionPage
