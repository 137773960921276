import React, { useEffect, useState } from 'react'
import { Card, Divider, Popover, Row, Tag, Tooltip, Typography } from 'antd'
import { useHistory } from 'react-router'
import {
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    StarFilled,
    WarningOutlined,
} from '@ant-design/icons'
import {
    CollectionHealthResponse,
    DetectGroupItemSummary,
    KPIIncidentInfo,
    PrometheusRule,
} from '../../../../lib/api/ResponseTypes/Common'
import { ItemStatus } from '../../../../lib/utils/common'
import { CustomAvatarGroup } from '../../../Common/CustomAvatarGroup'
import { useMutation } from 'react-query'
import { getCollectionHealth } from '../../../../lib/api/QueryApis/Common'
import { SeverityLevel } from '../../../../lib/api/RequestTypes/Common'

const { Text } = Typography

export interface AlertTableProp {
    collectionHealth: CollectionHealthResponse
}

export const AlertTable = ({ collectionHealth }: AlertTableProp) => {
    return (
        <>
            <div style={{ color: 'black', backgroundColor: 'white' }}>
                <Text strong>Non-Functional - </Text>
                Critical:{' '}
                <Text code>
                    {collectionHealth.criticalNonFunctionalIncidentsCount}
                </Text>{' '}
                | Warning:{' '}
                <Text code>
                    {collectionHealth.warningNonFunctionalIncidentsCount}
                </Text>{' '}
                | Info:{' '}
                <Text code>
                    {collectionHealth.infoNonFunctionalIncidentsCount}
                </Text>
            </div>
            <div style={{ color: 'black', backgroundColor: 'white' }}>
                <Text strong>Functional - </Text>
                Critical:{' '}
                <Text code>
                    {collectionHealth.criticalFunctionalIncidentsCount}
                </Text>{' '}
                | Warning:{' '}
                <Text code>
                    {collectionHealth.warningFunctionalIncidentsCount}
                </Text>{' '}
                | Info:{' '}
                <Text code>
                    {collectionHealth.infoFunctionalIncidentsCount}
                </Text>
            </div>
        </>
    )
}

export interface DetectCollectionCardProps {
    itemSummary: DetectGroupItemSummary
    ignoreClick?: boolean
    showItemStatus?: boolean
    hideUpdatedAt?: boolean
    onClickCallback?: Function
    mini?: boolean
    isLeader?: boolean
}

export const CollectionsCard = ({
    itemSummary,
    ignoreClick,
    showItemStatus,
    onClickCallback,
    mini,
    isLeader,
}: DetectCollectionCardProps) => {
    const history = useHistory()
    const { Meta } = Card
    const [incident, setIncident] = useState<any | null>(null)

    const [health] = useMutation(getCollectionHealth, {
        onSuccess: (resp) => {
            if (resp.severity != SeverityLevel.OK)
                setIncident({
                    level: resp.severity,
                    collectionHealth: resp,
                })
        },
        onError: (error) => {
            console.log('Collection Health Error Response: ', error)
        },
    })

    useEffect(() => {
        if (itemSummary) {
            health({
                itemExtId: itemSummary.itemExternalId,
                groupVersionId: itemSummary.groupVersionId,
            })
            setInterval(() => {
                health({
                    itemExtId: itemSummary.itemExternalId,
                    groupVersionId: itemSummary.groupVersionId,
                })
            }, 60000)
        }
    }, [itemSummary])

    const handleOnclick = () => {
        if (ignoreClick) return
        if (onClickCallback) {
            onClickCallback(
                itemSummary.itemExternalId,
                itemSummary.groupVersionId,
                itemSummary.itemStatus
            )
        } else {
            history.push({
                pathname: `/collection/${itemSummary.itemExternalId}`,
                state: itemSummary,
            })
        }
    }

    let cardStyle: any
    if (mini) {
        cardStyle = {
            padding: '0px',
            minWidth: '200px',
        }
    } else {
        cardStyle = {}
    }

    return (
        <Card
            hoverable
            size={mini ? 'small' : undefined}
            onClick={handleOnclick}
            className={!mini ? 'detect-collections-card' : ''}
            style={cardStyle}
            title={
                <Row
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    {mini && itemSummary.itemName.length > 18 && (
                        <Tooltip title={itemSummary.itemName}>
                            <div
                                style={{ maxWidth: '18ch', overflow: 'hidden' }}
                            >
                                {itemSummary.itemName}
                            </div>
                        </Tooltip>
                    )}
                    {(!mini || !(itemSummary.itemName.length > 18)) && (
                        <div>{itemSummary.itemName}</div>
                    )}

                    {incident !== null && (
                        <Tooltip
                            color={'white'}
                            overlayStyle={{ maxWidth: '500px' }}
                            title={
                                <AlertTable
                                    collectionHealth={incident.collectionHealth}
                                />
                            }
                        >
                            <Tag
                                style={{
                                    borderRadius: '10px',
                                    alignItems: 'center',
                                    display: 'flex',
                                }}
                                color={
                                    incident.level === SeverityLevel.CRITICAL
                                        ? 'red'
                                        : incident.level ===
                                          SeverityLevel.WARNING
                                        ? 'yellow'
                                        : 'blue'
                                }
                                icon={
                                    incident.level ===
                                    SeverityLevel.CRITICAL ? (
                                        <ExclamationCircleOutlined />
                                    ) : incident.level ===
                                      SeverityLevel.WARNING ? (
                                        <WarningOutlined />
                                    ) : (
                                        <InfoCircleOutlined />
                                    )
                                }
                            >
                                {!mini && 'Needs Attention: '}
                                {incident.level}
                            </Tag>
                        </Tooltip>
                    )}
                    <CustomAvatarGroup
                        permissions={itemSummary.permissions}
                        itemExternalId={itemSummary.itemExternalId}
                        isGroupItem={true}
                    />
                </Row>
            }
        >
            <div className="detect-item-card__body">
                <Meta
                    description={
                        <div
                            style={{
                                maxWidth: mini ? '25ch' : '',
                                overflowWrap: 'break-word',
                            }}
                        >
                            {itemSummary.description}
                        </div>
                    }
                />
            </div>
            <Divider style={{ marginBottom: '5px' }} />
            <Tag
                style={{ borderRadius: '10px', marginLeft: '5px' }}
                color={'blue'}
            >
                {itemSummary.itemExternalId}
            </Tag>
            {showItemStatus && (
                <Tag
                    color="blue"
                    style={{
                        borderRadius: '10px',
                        marginLeft: '5px',
                    }}
                    icon={<InfoCircleOutlined />}
                >
                    {itemSummary.itemStatus === ItemStatus.APPROVED
                        ? 'Update'
                        : 'New'}
                </Tag>
            )}
            {isLeader && (
                <Tag
                    key={'leader'}
                    color="gold"
                    style={{
                        borderRadius: '10px',
                        marginLeft: '5px',
                    }}
                    icon={<StarFilled />}
                >
                    Leader
                </Tag>
            )}
        </Card>
    )
}
