import React from 'react'
import { Divider } from 'antd'
import './index.css'

interface StyledDividerProps {
    dividerText: string
}

const StyledDivider = (props: StyledDividerProps) => {
    return (
        <Divider style={{ margin: '5px' }}>
            <div className='custom-divider'>
                {props.dividerText}
            </div>
        </Divider>
    )
}

export default StyledDivider
