import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Typography } from 'antd'
import { WarningOutlined, HomeOutlined } from '@ant-design/icons'

export default function UnauthorizedAccess() {
    const history = useHistory()

    const [height, setHeight] = React.useState(window.innerHeight)

    const updateHeight = () => {
        setHeight(window.innerHeight)
    }

    React.useEffect(() => {
        window.addEventListener('resize', updateHeight)
        return () => {
            window.removeEventListener('resize', updateHeight)
        }
    }, [])

    const { Title } = Typography

    return (
        <>

            <WarningOutlined style={{ fontSize: '24pt' }} />
            <Title level={2}>No Access</Title>
            <Button
                icon={(<HomeOutlined />)}
                type='primary'
                onClick={() => {
                    history.push('/')
                }}
            >
                Go back home
            </Button>
        </>
    )
}
