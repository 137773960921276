import { useQuery } from 'react-query'
import React, { useEffect, useState } from 'react'
import { getUseQueryOptions } from '../../Utils'
import { Modal, Pagination, Typography } from 'antd'
import { ItemStatus } from '../../../../lib/utils/common'
import './index.css'
import { getDetectGroupsRequestedCount, getDetectRequestedCount } from '../../../../lib/api/QueryApis/Common'
import ModerateProjectItemList from './ModerateProjectItemList'
import ProjectItemSummary from './ProjectItemSummary'
import { ItemType } from '../../../../lib/api/RequestTypes/Common'

const { Title } = Typography

const DetectProjectsAdminDashboard = () => {
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)
    const [total, setTotal] = useState(10)
    const [refresh, setRefresh] = useState(false)
    const [showModerate, setShowModerate] = useState(false)
    const [currItemAndConfig, setCurrItemAndConfig] = useState<any[]>([
        '',
        '',
        ItemStatus.REQUESTED
    ])

    const itemCountQuery = useQuery(
        ItemType.PROJECT,
        getDetectGroupsRequestedCount,
        getUseQueryOptions(1)
    )
    useEffect(() => {
        if (currItemAndConfig[0]!=='' && currItemAndConfig[1]!=='')
            setShowModerate(true)
    }, [currItemAndConfig])

    useEffect(() => {
        if (itemCountQuery.isSuccess) setTotal(itemCountQuery.data.count)
    }, [itemCountQuery])

    return (
        <>
            {/*<div>*/}
                <ModerateProjectItemList
                    limit={limit}
                    offset={offset}
                    refresh={refresh}
                    onCardClick={(
                        itemExtId: string,
                        configId: string,
                        itemStatus: ItemStatus
                    ) => {
                        setCurrItemAndConfig([
                            itemExtId,
                            configId,
                            itemStatus
                        ])
                    }}
                />
                <div className='detect-admin__footer'>
                    <Pagination
                        style={{ paddingTop: '10px' }}
                        showSizeChanger
                        showQuickJumper
                        total={total}
                        onChange={(page, pageSize) => {
                            setLimit(pageSize)
                            setOffset(pageSize * (page - 1))
                        }}
                    />
                </div>
            {/*</div>*/}
            <Modal
                title='Item Summary'
                visible={showModerate}
                footer={null}
                width='fit-content'
                onCancel={() => setShowModerate(false)}
                bodyStyle={{ padding: '0px' }}
            >
                <ProjectItemSummary
                    itemExtId={currItemAndConfig[0]}
                    groupVersionId={currItemAndConfig[1]}
                    itemStatus={currItemAndConfig[2]}
                    callback={() => {
                        setRefresh(!refresh)
                        setCurrItemAndConfig(['', '', ItemStatus.REQUESTED])
                        setShowModerate(false)
                    }}
                />
            </Modal>
        </>
    )
}

export default DetectProjectsAdminDashboard
