import { useQuery } from 'react-query'
import { getRequestedDetectGroupItems, getRequestedDetectItems } from '../../../../lib/api/QueryApis/Common'
import { getUseQueryOptions } from '../../Utils'
import React, { useEffect } from 'react'
import { Col, Empty, List, notification, Row, Spin } from 'antd'
import { KPICard } from '../../KPI/KPICard'
import './index.css'
import '../../index.css'
import { sendNotification } from '../../../../lib/utils/common'
import { CollectionsCard } from '../../Collections/CollectionsDashboard/CollectionsCard'
import { ItemType } from '../../../../lib/api/RequestTypes/Common'
import { ProjectsCard } from '../ProjectsDashboard/ProjectsCard'

interface DetectModerateProjectItemListProps {
    limit: number,
    offset: number,
    onCardClick: Function,
    refresh?: boolean
}

const ModerateProjectItemList = ({ limit, offset, onCardClick, refresh }: DetectModerateProjectItemListProps) => {
    const [api, contextHolder] = notification.useNotification()
    const requestedItemsQuery = useQuery([
            {
                limit: limit,
                offset: offset,
                groupType: ItemType.PROJECT
            }],
        getRequestedDetectGroupItems,
        getUseQueryOptions(3)
    )

    useEffect(() => {
        if (requestedItemsQuery.isSuccess) {
            requestedItemsQuery.refetch()
        }
    }, [refresh])

    if (requestedItemsQuery.isSuccess) {
        if (requestedItemsQuery.data.length===0) {
            return (
                <div className='detect-full-center'>
                    <Empty description={<span>No Pending Requests</span>} />
                </div>)
        } else {
            return (
                <Row gutter={12}
                     style={{ overflowY: 'scroll', maxHeight: '650px' }} className='detect-list-scroll'>
                    {requestedItemsQuery.data.map((item, idx) => (
                        <Col key={idx} span={12}>
                            <ProjectsCard
                                itemSummary={item}
                                hideUpdatedAt={true}
                                showItemStatus={true}
                                onClickCallback={onCardClick} />
                        </Col>
                    ))}
                </Row>
            )
        }
    } else if (requestedItemsQuery.isError) {
        sendNotification(
            notification, 'error', 'Can\'t fetch items', ''
        )
        return (
            <div className='detect-full-center'>
                <Empty description={<span>Error Occurred. No Data</span>} />
            </div>
        )
    } else {
        return (
            <div className='detect-full-center'>
                <Spin tip='Loading' />
            </div>
        )
    }
}

export default ModerateProjectItemList
