import React, { useState } from 'react'
import { Button, Col, Descriptions, Empty, notification, Row, Spin } from 'antd'
import { useMutation, useQuery } from 'react-query'
import { getDetectItem } from '../../../../lib/api/QueryApis/Common'
import { getUseQueryOptions } from '../../Utils'
import { useHistory } from 'react-router'
import { moderateConfig, moderateItem } from '../../../../lib/api/MutationApis/Common'
import { ItemStatus, sendNotification } from '../../../../lib/utils/common'
import KPIConfig, { ItemMode } from '../KPIConfig'
import { DataSourceType } from '../../../../lib/api/RequestTypes/Common'

interface DetectItemSummaryProps {
    itemExtId: string
    configId: string
    itemStatus: ItemStatus
    callback: Function
}

const KPISummary = ({ itemExtId, configId, itemStatus, callback }: DetectItemSummaryProps) => {

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [showFullConfig, setShowFullConfig] = useState(false)
    const itemConfigQuery = useQuery(
        [{
            itemExtId: itemExtId,
            configId: configId
        }],
        getDetectItem,
        getUseQueryOptions(1)
    )

    const [moderateItemQuery] = useMutation(
        moderateItem,
        {
            onSuccess: (data) => {
                setIsLoading(false)
                sendNotification(
                    notification, 'success', `Item ${itemExtId} moderated`, ''
                )
                callback()

            },
            onError: (e) => {
                setIsLoading(false)
                sendNotification(
                    notification, 'error', `Failed to moderate ${itemExtId}`, ''
                )
            }
        }
    )


    const [moderateConfigQuery] = useMutation(
        moderateConfig,
        {
            onSuccess: (data) => {
                setIsLoading(false)
                sendNotification(
                    notification, 'success', `Item ${itemExtId} moderated`, ''
                )
                callback()

            },
            onError: (e) => {
                setIsLoading(false)
                sendNotification(
                    notification, 'error', `Failed to moderate ${itemExtId}`, ''
                )
            }
        }
    )


    let configSummary = (<div></div>)
    if (itemConfigQuery.isLoading || isLoading) {
        configSummary = (
            <div className='detect-full-center'>
                <Spin />
            </div>
        )
    } else if (itemConfigQuery.isSuccess) {
        if (showFullConfig) {
            configSummary = (
                <KPIConfig
                    itemConfig={itemConfigQuery.data}
                    itemExtId={itemConfigQuery.data.itemExternalId}
                    mode={ItemMode.View} />
            )
        } else {

            if (itemConfigQuery.data.dataSourceType==DataSourceType.DATA_PLATFORM) {
                configSummary = (
                    <Descriptions bordered>
                        <Descriptions.Item label='Name'>{itemConfigQuery.data.itemName}</Descriptions.Item>
                        <Descriptions.Item label='Scope'>{itemConfigQuery.data.itemScope}</Descriptions.Item>
                        <Descriptions.Item
                            label='Algorithms'>{itemConfigQuery.data.algorithms.map(it => it.algorithm).join(', ')}</Descriptions.Item>
                        <Descriptions.Item
                            label='Time Period'>{itemConfigQuery.data.timePeriod?.toString()}</Descriptions.Item>
                        <Descriptions.Item
                            label='Cat. Cols.'>{itemConfigQuery.data.categoricalDimensionColumns?.length.toString()}</Descriptions.Item>
                        <Descriptions.Item
                            label='Cont. Cols'>{itemConfigQuery.data.continuousDimensionColumns?.length.toString()}</Descriptions.Item>
                        <Descriptions.Item label='Task Type'>{itemConfigQuery.data.taskType}</Descriptions.Item>
                        <Descriptions.Item
                            label='Task Trigger Mode'>{itemConfigQuery.data.taskTriggerMode}</Descriptions.Item>
                    </Descriptions>
                )
            } else {
                let service_name = itemConfigQuery.data.configParameters.get('service_name')
                let namespace = itemConfigQuery.data.configParameters.get('namespace')
                let resource_name = itemConfigQuery.data.configParameters.get('resource_name')
                let cluster = itemConfigQuery.data.configParameters.get('cluster')
                configSummary = (
                    <Descriptions bordered>
                        <Descriptions.Item label='Name'>{itemConfigQuery.data.itemName}</Descriptions.Item>
                        <Descriptions.Item label='Scope'>{itemConfigQuery.data.itemScope}</Descriptions.Item>
                        <Descriptions.Item label='Scope'>{itemConfigQuery.data.itemScope}</Descriptions.Item>
                        {cluster!==undefined && (
                            <Descriptions.Item label='Cluster'>{cluster}</Descriptions.Item>
                        )}
                        {namespace!==undefined && (
                            <Descriptions.Item label='Namespace'>{namespace}</Descriptions.Item>
                        )}
                        {service_name!==undefined && (
                            <Descriptions.Item label='Service Name'>{service_name}</Descriptions.Item>
                        )}
                        {resource_name!==undefined && (
                            <Descriptions.Item label='Resource Name'>{resource_name}</Descriptions.Item>
                        )}
                        <Descriptions.Item
                            label='Algorithms'>{itemConfigQuery.data.algorithms.map(it => it.algorithm).join(', ')}</Descriptions.Item>
                    </Descriptions>
                )
            }

        }
    } else if (itemConfigQuery.isError) {
        configSummary = (
            <div className='detect-full-center'>
                <Empty />
            </div>)
    }

    const handleModerate = (modelStatus: ItemStatus) => {
        setIsLoading(true)
        if (itemStatus===ItemStatus.APPROVED) {
            moderateConfigQuery({
                itemExternalId: itemExtId,
                configId: configId,
                requestStatus: modelStatus
            })
        } else {
            moderateItemQuery({
                itemExternalId: itemExtId,
                configId: configId,
                requestStatus: modelStatus
            })
        }
    }


    return (
        <div style={{ padding: '10px', minWidth: '30vw', maxWidth: '55vw' }}>
            {configSummary}
            <Row gutter={8} style={{ paddingTop: '5px' }}>
                <Col span={8}>
                    <Button
                        className='detect-button--primary--full' style={{ width: '100%' }}
                        onClick={() => setShowFullConfig(!showFullConfig)}>
                        {showFullConfig ? 'Hide Full Config':'Show Full Config'}
                    </Button>
                </Col>
                <Col span={8}>
                    <Button
                        onClick={() => handleModerate(ItemStatus.APPROVED)}
                        className='detect-button--primary--full'>
                        Approve
                    </Button>
                </Col>
                <Col span={8}>
                    <Button
                        className='detect-button--danger--full'
                        onClick={() => handleModerate(ItemStatus.REJECTED)}
                        danger>
                        Reject
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default KPISummary
