import { format } from 'date-fns'
import React from 'react'
import { NotificationInstance, NotificationPlacement } from 'antd/es/notification'

export const getDateStringHumanReadable = (date: Date) => {
    const dtf = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Asia/Kolkata'
    })
    const [
        { value: month },
        ,
        { value: day },
        ,
        { value: year },
        ,
        { value: hour },
        ,
        { value: minute },
        ,
        { value: dayPeriod }
    ] = dtf.formatToParts(date)
    return `${day}-${month}-${year} ${hour}:${minute} ${dayPeriod}`
}

export const getFormattedNumber = (
    value: number | null,
    minFractionDigits: number = 1,
    maxFractionDigits: number = 1
) => {
    if (value==null) return ''
    return value.toLocaleString('en-IN', {
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: maxFractionDigits,
        notation: 'compact',
        compactDisplay: 'short'
    }).replace('T', 'K')
}

export const getDateStringUrlFriendly = (date: Date) => {
    const dtf = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'Asia/Kolkata'
    })
    const [
        { value: month },
        ,
        { value: day },
        ,
        { value: year },
        ,
        { value: hour },
        ,
        { value: minute },
        ,
        { value: second },
        ,
        { value: dayPeriod }
    ] = dtf.formatToParts(date)
    return `${year}_${month}_${day}_${hour}_${minute}_${second}_${dayPeriod}`
}

export const convertToIst = (timestamp: string) => {
    var dateIST = new Date(timestamp)
    dateIST.setHours(dateIST.getHours() + 5)
    dateIST.setMinutes(dateIST.getMinutes() + 30)
    return dateIST
}

export const convert2dToJson = (columns: [], values: []) => {
    let json: any = {}
    columns.forEach((col: any, index: number) => {
        json[col.replace('.', '')] = values[index]
    })
    return json
}

export const getSampleJson = (data: any, headers?: any) => {
    let tableData: any = []
    let sampleLength: number = data.length > 50 ? 50:data.length - 1
    headers = headers ? headers:data[0]
    for (let i = 1; i < sampleLength; i++) {
        tableData.push(convert2dToJson(headers, data[i]))
    }
    return tableData
}

export const getSampleJsonWithoutLimit = (
    data: [][],
    limit: boolean = false
) => {
    let tableData: any = []
    if (data.length===0) {
        return []
    }
    let sampleLength = data.length - 1
    for (let i = 1; i < sampleLength; i++) {
        tableData.push(convert2dToJson(data[0], data[i]))
    }
    return tableData
}

export const getTableColumns = (columns: []) => {
    return columns.map((col: any, index: any) => {
        if (col.includes('.')) {
            return {
                name: `${col.replace('.', '')}`,
                selector: `${col.replace('.', '')}`
            }
        }
        return {
            name: col,
            // wrap: true,
            minWidth: '160px',
            maxWidth: '160px',
            selector: col
        }
    })
}

export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

String.prototype.nthLastIndexOf = function(searchString: string, n: number) {
    var url = this
    if (url===null) {
        return -1
    }
    if (!n || isNaN(n) || n <= 1) {
        return url.lastIndexOf(searchString)
    }
    n--
    return url.lastIndexOf(
        searchString,
        url.nthLastIndexOf(searchString, n) - 1
    )
}

export function getFileNameFromPath(file_path: string) {
    if (file_path.includes('part-')) {
        return file_path.substr(
            file_path.nthLastIndexOf('/', 2),
            file_path
                .substr(file_path.nthLastIndexOf('/', 2), file_path.length)
                .lastIndexOf('/') - 4
        )
    }
    return file_path.substr(file_path.lastIndexOf('/'), file_path.length - 4)
}

export default function getRndInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export const getArrayFromCommaSeparatedString = (
    commaSeparatedStr: string
): Array<string> => {
    const values = commaSeparatedStr.split(',').map((value: string) => {
        return value.trim()
    })
    return values
}

export function rangeFilled(start: number, end: number, step = 1) {
    const len = Math.floor((end - start) / step) + 1
    return Array(len)
        .fill('00')
        .map((_, idx) =>
            start + idx * step >= 10
                ? `${start + idx * step}`
                :`0${start + idx * step}`
        )
}

export function range(start: number, end: number, step = 1): Array<string> {
    const len = Math.floor((end - start) / step) + 1
    return Array(len)
        .fill('00')
        .map((_, idx) => `${start + idx * step}`)
}

export const getTableColumnsForReactTable = (columns: []) => {
    return columns.map((col: any, index: any) => {
        return {
            Header: col,
            accessor: `${col.replace('.', '')}`
        }
    })
}

export const getTableColumnsForSampleDataTable = (columns: []) => {
    return columns.map((col: any, index: any) => {
        return {
            title: col,
            dataIndex: `${col.replace('.', '')}`.toLowerCase(),
            key: `${index}`
        }
    })
}

export function getCronTimeFromHrTime(hrTime: string): string {
    if (hrTime.includes('AM')) {
        if (hrTime.includes('12')) {
            return `${0}`
        }
        return `${parseInt(hrTime.slice(0, hrTime.indexOf(':')))}`
    } else {
        if (hrTime.includes('12')) {
            return `${12}`
        }
        return `${parseInt(hrTime.slice(0, hrTime.indexOf(':'))) + 12}`
    }
}

export const jsDateToSqlString = (date: Date | null): string => {
    return format(date as Date, 'yyyy-MM-dd hh:mm a')
}

export const parsableDate = (date: Date): string => {
    return format(date, 'yyyy-MM-dd')
}

export const downloadData = (data: string | Blob, fileName: string, isBlob: boolean) => {
    const a: HTMLAnchorElement = document.createElement('a')
    document.body.appendChild(a)
    a.setAttribute('style', 'display: none')
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
    // @ts-ignore
    a.href = window.URL.createObjectURL(isBlob ? data:blob)
    a.download = fileName + '.csv'
    a.click()
    document.body.removeChild(a)
}

export const msInOneDay = 24 * 60 * 60 * 1000

export const LATEST = 'latest'

export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export enum ItemStatus {
    REQUESTED = 'REQUESTED',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export enum FeatureStoreProjectTypes {
    DEV = 'DEV',
    PROD = 'PROD',
}

export enum FeatureStoreBatchTypes {
    FS_UTILS = 'FS_UTILS',
    FLOWS = 'FLOWS',
}

export enum CatalogEntityType {
    FS_TABLE = 'FS_TABLE',
    TABLE = 'TABLE',
    FS_COLUMN = 'FS_COLUMN',
    COLUMN = 'COLUMN',
    FS_ENTITY = 'FS_ENTITY',
}

export enum DeploymentStatus {
    PROCESSING = 'PROCESSING',
    WORKFLOW_TRIGGERED = 'WORKFLOW_TRIGGERED',
    WORKFLOW_TRIGGER_FAILED = 'WORKFLOW_TRIGGER_FAILED',
    WORKFLOW_STARTED = 'WORKFLOW_STARTED',
    BUILDING_IMAGE = 'BUILDING_IMAGE',
    IMAGE_BUILD_SUCCESSFUL = 'IMAGE_BUILD_SUCCESSFUL',
    IMAGE_BUILD_FAILED = 'IMAGE_BUILD_FAILED',
    DEPLOYMENT_TRIGGERED = 'DEPLOYMENT_TRIGGERED',
    DEPLOYMENT_SUCCESSFUL = 'DEPLOYMENT_SUCCESSFUL',
    DEPLOYMENT_FAILED = 'DEPLOYMENT_FAILED',
    PIPELINE_FETCH_SUCCESSFUL = 'PIPELINE_FETCH_SUCCESSFUL',
    PIPELINE_VALIDATED_DAG_SUCCESSFUL = 'PIPELINE_VALIDATED_DAG_SUCCESSFUL',
    PIPELINE_PY_PACKAGE_PUSHED = 'PIPELINE_PY_PACKAGE_PUSHED',
    PIPELINE_BUILD_SUCCESSFUL = 'PIPELINE_BUILD_SUCCESSFUL',
    PIPELINE_BUILD_FAILED = 'PIPELINE_BUILD_FAILED',
}

export const MLP_ACL = 'mlp_acl'
export const ML_PLATFORM = 'ml-platform'
export const DATA_CATALOG = 'data_catalog'
export const DATA_CATALOG_ADMIN = 'data_catalog_admin'
export const MLP_MP_SOLVER = 'mlp_mp_solver'
export const MLP_FS_ACL = 'mlp_fs_acl'
export const MLP_DETECT_READ = 'mlp_detect_read'
export const MLP_DETECT_CREATE = 'mlp_detect_create'
export const MLP_DETECT_ADMIN = 'detect_admin'

export const inputFormStyles = {
    fontSize: '11pt',
    border: '0',
    color: '#001585',
    outlineWidth: 0,
    fontWeight: 400,
    width: '300px'
}

export const inputFormContainerStyle = {
    borderWidth: '0.01px',
    borderStyle: 'solid',
    padding: '10px',
    borderColor: '#cccccc',
    borderRadius: '14px'
}

export const rejectButtonBackground = '#d30303'

export const MAX_NOTIFICATION_CONTENT_LEN = 1000

export const getBackgroundColorFromQueryType = (queryType: ItemStatus) => {
    if (queryType===ItemStatus.APPROVED) {
        return {
            color: '#00ae29',
            borderColor: '#00ae29',
            border: '1px solid',
            backgroundColor: '#fff'
        }
    } else if (queryType===ItemStatus.REQUESTED) {
        return {
            color: '#ee9700',
            borderColor: '#ee9700',
            border: '1px solid',
            backgroundColor: '#fff'
        }
    } else if (queryType===ItemStatus.REJECTED) {
        return {
            color: '#c60000',
            borderColor: '#c60000',
            border: '1px solid',
            backgroundColor: '#fff'
        }
    }
    return {
        color: '#002fcb',
        borderColor: '#002fcb',
        border: '1px solid',
        backgroundColor: '#fff'
    }
}


export const getTagColorForItemStatus = (queryType: ItemStatus) => {
    if (queryType===ItemStatus.APPROVED) {
        return 'green'
    } else if (queryType===ItemStatus.REQUESTED) {
        return 'gold'
    } else if (queryType===ItemStatus.REJECTED) {
        return 'volcano'
    }
    return 'blue'
}


export const getBackgroundColorFromDeploymentStatus = (
    status: DeploymentStatus
) => {
    if (
        status===DeploymentStatus.IMAGE_BUILD_SUCCESSFUL ||
        status===DeploymentStatus.DEPLOYMENT_SUCCESSFUL ||
        status===DeploymentStatus.PIPELINE_BUILD_SUCCESSFUL
    ) {
        return {
            color: '#00ae29',
            antdColor: 'green',
            borderColor: '#00ae29',
            border: '1px solid',
            backgroundColor: '#fff'
        }
    } else if (
        status===DeploymentStatus.IMAGE_BUILD_FAILED ||
        status===DeploymentStatus.DEPLOYMENT_FAILED ||
        status===DeploymentStatus.PIPELINE_BUILD_FAILED
    ) {
        return {
            color: '#c60000',
            antdColor: 'red',
            borderColor: '#c60000',
            border: '1px solid',
            backgroundColor: '#fff'
        }
    }
    return {
        color: '#ee9700',
        antdColor: 'gold',
        borderColor: '#ee9700',
        border: '1px solid',
        backgroundColor: '#fff'
    }
}

export const getISTTime = (timestamp: number) => {
    const date = new Date(timestamp)
    let hours = date.getHours()
    let minutes: string | number = date.getMinutes()
    let ampm = hours >= 12 ? 'pm':'am'
    hours = hours % 12
    hours = hours ? hours:12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes:minutes
    let strTime = hours + ':' + minutes + ' ' + ampm
    return strTime + ', ' + date.toDateString()
}

export const sendNotification = (notification: NotificationInstance,
                                 type: 'success' | 'warning' | 'info' | 'error' | 'open',
                                 title: string,
                                 description: string,
                                 placement: NotificationPlacement = 'bottomRight',
                                 duration: number = 10
) => {

    switch (type) {
        case 'success':
            return notification.success({
                message: title,
                description: description,
                duration: duration,
                placement: placement
            })
        case 'info':
            return notification.info({
                message: title,
                description: description,
                duration: duration,
                placement: placement
            })
        case 'error':
            return notification.error({
                message: title,
                description: description,
                duration: duration,
                placement: placement
            })
        case 'warning':
            return notification.warning({
                message: title,
                description: description,
                duration: duration,
                placement: placement
            })
        case 'open':
            return notification.open({
                message: title,
                description: description,
                duration: duration,
                placement: placement
            })
    }
}
