import { useQuery } from 'react-query'
import { getUserDetectItems } from '../../../../lib/api/QueryApis/Common'
import React, { useEffect, useState } from 'react'
import { Empty, List, notification, Spin } from 'antd'
import { ItemStatus, sendNotification } from '../../../../lib/utils/common'
import { OrderBy, OrderType } from '../../../../lib/api/RequestTypes/Common'
import { getUseQueryOptions } from '../../Utils'
import { SelectKPICard } from './SelectKPICard'

interface DetectItemListProps {
    value?: Set<string>
    filterText: string
    orderBy: OrderBy
    orderType: OrderType
    itemType: ItemStatus
    dataSourceType: string,
    limit: number
    offset: number
    showPublic: boolean
    showProblematic: boolean
    refresh?: boolean
    leaderKPI: string | null
    onLeaderKPIChange: (leaderKPI: string) => void
    onChange?: (selectedKPIs: Set<string>) => void
}

const KPISelectList = ({
                           value,
                           filterText,
                           orderBy,
                           orderType,
                           itemType,
                           dataSourceType,
                           limit,
                           offset,
                           showPublic,
                           showProblematic,
                           refresh,
                           leaderKPI,
                           onLeaderKPIChange,
                           onChange
                       }: DetectItemListProps) => {
    const [currLeaderKPI, setCurrentLeaderKPI] = useState<string | null>(leaderKPI)
    const query = useQuery(
        [
            {
                configStatus: itemType,
                filterText: filterText,
                orderBy: orderBy,
                orderType: orderType,
                dataSourceType: dataSourceType,
                limit: limit,
                offset: offset,
                showPublic: showPublic,
                showProblematic: showProblematic
            }
        ],
        getUserDetectItems,
        getUseQueryOptions(2)
    )
    const [selectedKPIs, setSelectedKPIs] = useState<Set<string>>(
        new Set(value) || new Set()
    )
    const [isSelectedMapping, setIsSelectedMapping] = useState<
        Map<string, boolean>
    >(new Map())

    useEffect(() => {
        if (value) setSelectedKPIs(new Set(value))
    }, [value])

    useEffect(() => {
        if (query.isSuccess) {
            query.refetch()
        }
    }, [refresh])

    const onKPISelect = (kpiId: string) => {
        setSelectedKPIs(new Set<string>([kpiId, ...Array.from(selectedKPIs)]))
        let tempSelectedMapping = isSelectedMapping
        tempSelectedMapping.set(kpiId, true)
        setIsSelectedMapping(tempSelectedMapping)
        if (typeof onChange==='function') {
            onChange(new Set<string>([kpiId, ...Array.from(selectedKPIs)]))
        }
    }

    const onKPIDeselect = (kpiId: string) => {
        let selKPIs = selectedKPIs
        selKPIs.delete(kpiId)
        setSelectedKPIs(selKPIs)
        if (typeof onChange==='function') {
            onChange(selKPIs)
        }
        let tempSelectedMapping = isSelectedMapping
        tempSelectedMapping.set(kpiId, false)
        setIsSelectedMapping(tempSelectedMapping)
    }

    useEffect(() => {
        let tempSelectedMapping = isSelectedMapping
        selectedKPIs.forEach((kpiId) => {
            tempSelectedMapping.set(kpiId, true)
        })
        setIsSelectedMapping(tempSelectedMapping)
    }, [])

    if (query.isSuccess) {
        return (
            <List className='detect-list-scroll'>
                {query.data.map((item, idx) => (
                    <List.Item key={idx}>
                        <SelectKPICard
                            itemSummary={item}
                            isSelected={selectedKPIs.has(item.itemExternalId)}
                            ignoreClick={
                                itemType===ItemStatus.REJECTED ||
                                itemType===ItemStatus.REQUESTED
                            }
                            onKPISelect={onKPISelect}
                            onKPIDeselect={onKPIDeselect}
                            leaderKPI={item.itemExternalId===currLeaderKPI}
                            onSelectLeaderKPI={(leaderKPI) => {
                                setCurrentLeaderKPI(leaderKPI)
                                onLeaderKPIChange(leaderKPI)
                            }}
                        />
                    </List.Item>
                ))}
            </List>
        )
    } else if (query.isError) {
        sendNotification(notification, 'error', 'Can\'t fetch items', '')
        return (
            <div className='detect-full-center'>
                <Empty description={<span>Error Occurred. No Data</span>} />
            </div>
        )
    } else {
        return (
            <div className='detect-full-center'>
                <Spin tip='Loading' />
            </div>
        )
    }
}
export default KPISelectList
