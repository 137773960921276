import React from 'react'
import 'antd/dist/antd.css'

import './index.css'
import { Spin } from 'antd'
import { useQuery } from 'react-query'
import { getUserInfo } from '../../lib/api/QueryApis/Common'
import ProjectHome from '../Detect/Projects/ProjectHome'
import ProjectsDashboard from '../Detect/Projects/ProjectsDashboard'

function Homepage() {
    const [isCreateNewOpen, setisCreateNewOpen] = React.useState(false)
    const ref = React.useRef(null)
    const [userName, setUserName] = React.useState('')

    const { data: userInfo } = useQuery(['userInfo', {}], getUserInfo, {
        refetchOnWindowFocus: false,
        cacheTime: Infinity
    })

    const handleClickOutside = (event: any) => {
        if (ref.current && !(ref.current as any).contains(event.target)) {
            setisCreateNewOpen(false)
        }
    }

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    return (
        <>
            <ProjectsDashboard />
        </>
    )
}

export default Homepage
