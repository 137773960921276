import React, { Suspense } from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import App from './App/App'
import { AbsoluteCenter } from './components/Common/AbsoluteCenter'
import ErrorBoundary from './components/Common/ErrorBoundary'
import Loading from './components/Common/Loading'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { createEmotionCache } from './lib/utils/create-emotion-cache'
import { CacheProvider } from '@emotion/react'
import { MsalProvider } from '@azure/msal-react'
import generateMsalInstance from './App/useMsalAuth'

const emotionCache = createEmotionCache()

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <CacheProvider value={emotionCache}>
                <CookiesProvider>
                    <ErrorBoundary>
                        <Suspense
                            fallback={
                                <AbsoluteCenter>
                                    <Loading />
                                </AbsoluteCenter>
                            }
                        >
                            <BrowserRouter>
                                <MsalProvider instance={generateMsalInstance()}>
                                    <App />
                                </MsalProvider>
                            </BrowserRouter>
                        </Suspense>
                    </ErrorBoundary>
                </CookiesProvider>
            </CacheProvider>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
