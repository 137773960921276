import { DynamicFormFieldDefinition } from '../../../lib/api/ResponseTypes/Common'
import { Form, FormInstance, Input, InputNumber, Select } from 'antd'
import React, { useEffect } from 'react'
import { humanize } from '../../Detect/Utils'

export interface DynamicFormFieldProp {
    fieldName: string
    fieldDefinition: DynamicFormFieldDefinition
    callback: Function | null
}

const DynamicFormField = (
    {
        fieldName,
        fieldDefinition,
        callback
    }: DynamicFormFieldProp) => {
    let innerContent = <div />

    switch (fieldDefinition.type) {
        case 'float':
        case 'int': {
            innerContent = (
                <InputNumber
                    onChange={() => {
                        if (callback!==null) {
                            callback()
                        }
                    }}
                    style={{ minWidth: '150px', marginBottom: '10px' }}
                    placeholder={humanize(fieldName)}
                    type='number'
                    {...(fieldDefinition.range
                        ? {
                            min: fieldDefinition.range[0],
                            max: fieldDefinition.range[1],
                            step:
                                (fieldDefinition.range[1] -
                                    fieldDefinition.range[0]) /
                                10
                        }
                        :{})}
                />
            )
            break
        }
        case 'input': {
            innerContent = (
                <Input
                    onChange={(value) => {
                        if (callback!==null) {
                            callback(fieldName, value)
                        }
                    }}
                    style={{ minWidth: '150px', marginBottom: '10px' }}
                    placeholder={humanize(fieldName)}
                />
            )
            break
        }
        case 'boolean':
        case 'string': {
            innerContent = (
                <Select
                    onClick={() => {
                    }}
                    onChange={() => {
                        if (callback!==null) {
                            callback()
                        }
                    }}
                    style={{ minWidth: '150px', marginBottom: '10px' }}
                    placeholder={humanize(fieldName)}
                    options={fieldDefinition.options? fieldDefinition.options.map((it) => {
                        return {
                            label: it===null ? 'None':it.toString(),
                            value: it
                        }
                    }) : []}
                />
            )
            break
        }
        default:
            break
    }

    return (
        <Form.Item name={fieldName} label={humanize(fieldName)}>
            {innerContent}
        </Form.Item>
    )
}

export interface DynamicFormProp {
    fields: { [key: string]: DynamicFormFieldDefinition }
    form: FormInstance
    disabled: boolean
    callback: Function | null
    //defaultValues: { [key: string]: any } | null
}

const DynamicForm = (
    {
        fields,
        form,
        disabled,
        callback
        //defaultValues,
    }: DynamicFormProp) => {
    /*
    let defaults = { ...fields }
    Object.keys(defaults).forEach(
        (key) => (defaults[key] = defaults[key].default)
    )
    if (defaultValues !== null) {
        Object.keys(defaultValues).forEach((key) => {
            defaults[key] = defaultValues[key]
        })
    }*/

    return (
        <Form
            size='middle'
            form={form}
            layout='inline'
            disabled={disabled}
        >
            {Object.keys(fields).map((it) => (
                <DynamicFormField
                    key={it}
                    fieldName={it}
                    fieldDefinition={fields[it]}
                    callback={callback}
                />
            ))}
            <div style={{ marginTop: '30px' }} />
        </Form>
    )
}

export default DynamicForm
