export enum UserInfoEndpoints {
    userScopesList = '/data_platform/users/getUserScopes',
    userInfo = '/data_platform/users/getUserInfo',
    listUsersLike = '/etl/transform/data_item/listUsersLike?email={0}',
}

export enum DetectUserEndpoints {
    userProfile = '/detect/user/profile',
	authorize = '/detect/user/authorize'
}

export enum DetectEndpoints {
	itemBase = '/detect/kpi',
	particularItem = '/detect/kpi/{itemExtId}',
	pendingRequests = '/detect/kpi/{itemExtId}/pending',
	lastData = '/detect/kpi/result/{itemExtId}/last-data',
	particularItemSummary = '/detect/kpi/summary/{itemExtId}',
	multipleSummaries = '/detect/kpi/summaries',
	userItemsCount = '/detect/kpi/count',
	itemConfig = '/detect/kpi/{itemExtId}/config',
	userConfigs = '/detect/kpi/',
	requestedConfigs = '/detect/kpi/requested',
	requestedConfigsCount = '/detect/kpi/requested/count',
	grantAccess = '/detect/kpi/grantAccess',
	deleteAccess = '/detect/kpi/deleteAccess',
	moderateConfig = '/detect/kpi/moderateConfig',
	moderateItem = '/detect/kpi/moderateItem',
	getResult = '/detect/kpi/result/{itemExtId}',
	getResultHistory = '/detect/kpi/result/{itemExtId}/logs',
	getResultHistoryCount = '/detect/kpi/result/{itemExtId}/logs-count',
	resultForecast = '/detect/kpi/result/forecast',
	getFormInitData = '/detect/kpi/formInitData',
	canModerate = '/detect/kpi/canModerate',
	itemUserPermissions = '/detect/kpi/{itemExtId}/permissions',
	udaanServiceList = '/detect/kpi/result/udaan-services',
	udaanResourceList = '/detect/kpi/result/udaan-resources',
	resourceDependency = '/detect/kpi/result/resource-dependency',
	kpiFiringAlerts = '/detect/kpi/result/{itemExtId}/activeAlerts',
	dataSourceSummary = '/detect/kpi/{itemExtId}/dataSourceSummary',
    collectionHealth = '/detect/kpi/result/{itemExtId}/group/collection/health',
    projectHealth = '/detect/kpi/result/{itemExtId}/group/project/health',
    traceIds = '/detect/kpi/result/exception-traces',
    httpRequestsByTraceId = '/detect/kpi/result/trace/{traceId}',
    exception = '/detect/kpi/result/trace/{traceId}/exceptions',
    exceptions = '/detect/kpi/result/exceptions'
}

export enum DetectGroupEndpoints {
    groupsBase = '/detect/groups',
    particularGroupItem = '/detect/groups/{itemExtId}',
    particularGroupItemLeaderData = '/detect/groups/{itemExtId}/leader-data',
    collectionKPIData = '/detect/groups/{itemExtId}/kpi',
    addKPI = '/detect/groups/addKPI',
    projectCollectionData = '/detect/groups/{itemExtId}/collection',
    requestedConfigsCount = '/detect/groups/requested/count',
    itemConfig = '/detect/groups/{itemExtId}/config',
    userItemsCount = '/detect/groups/count',
    multipleSummaries = '/detect/groups/summaries',
    requestedConfigs = '/detect/groups/requested',
    moderateConfig = '/detect/groups/moderateConfig',
    moderateItem = '/detect/groups/moderateItem',
    grantAccess = '/detect/groups/grantAccess',
    deleteAccess = '/detect/groups/deleteAccess',
    itemUserPermissions = '/detect/groups/{itemExtId}/permissions'
}

export enum DataSourceEndpoints {
    dataSourceBase = '/dataSource',
    dataSourceColumns = '/dataSource/{dataSourceId}/columns',
    dataSourceMetricQueries = '/dataSource/{dataSourceType}/queries',
    dataSourceMetricQuery = '/dataSource/{dataSourceType}/query',
    dataSourceAlerts = '/dataSource/{dataSourceType}/{queryType}/alerts'
}

export enum DataItemEndpoints {
    userInfo = '/data_platform/users/getUserInfo',
}

export type APIEndpoints =
    | UserInfoEndpoints
    | DetectEndpoints
    | DataSourceEndpoints
    | DataItemEndpoints
    | DetectGroupEndpoints
    | DetectUserEndpoints
