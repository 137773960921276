import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { DetectGroupItem } from '../../../../lib/api/RequestTypes/Common'
import { getDetectGroupLeaderData, getDetectProjectCollectionsData } from '../../../../lib/api/QueryApis/Common'
import { getUseQueryOptions } from '../../Utils'
import { useHistory } from 'react-router-dom'
import { Card, Col, Empty, Row, Spin } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import LastDataTable from '../../../Common/LastDataTable'
import { DetectGroupItemSummary } from '../../../../lib/api/ResponseTypes/Common'
import '../../index.css'
import ReactFlow, {
    addEdge,
    Background,
    Controls,
    Handle,
    MarkerType,
    Position,
    useEdgesState,
    useNodesState
} from 'reactflow'
import { CollectionsCard } from '../../Collections/CollectionsDashboard/CollectionsCard'
import ELK from 'elkjs'


export interface CollectionNodeProp {
    data: { itemSummary: DetectGroupItemSummary, isLeader?: boolean }
    isConnectable: boolean
}

const CollectionNode = memo(({ data, isConnectable }: CollectionNodeProp) => {
    return (
        <>
            <Handle
                type='source'
                position={Position.Bottom}
                id='a'
                style={{ background: 'rgba(85,85,85,0)' }}
                onConnect={(params) => {
                }}
                isConnectable={isConnectable}
            />
            <CollectionsCard
                itemSummary={data.itemSummary}
                mini={true}
                isLeader={data.isLeader}
                showItemStatus={false}
            />
            <Handle
                type='target'
                position={Position.Top}
                id='b'
                style={{ background: 'rgba(85,85,85,0)' }}
                isConnectable={true}
                onConnect={(params) => {
                }}
            />
        </>

    )
})


export interface ProjectGraphComponentInfo {
    collectionItemId: string
    collectionItemInfo: DetectGroupItem
}


const nodeTypes = {
    CollectionNode: CollectionNode
}

const ProjectGraphComponent = ({
                                   collectionItemId,
                                   collectionItemInfo
                               }: ProjectGraphComponentInfo) => {

    const history = useHistory()

    const container = useRef<null | HTMLDivElement>(null)
    const [hidden, setHidden] = useState(true)
    const [currentCollection, setCurrentCollection] = useState(['', ''])

    const onInit = (reactFlowInstance: any) => {
    }

    const [nodes, setNodes, onNodesChange] = useNodesState([])
    const [edges, setEdges, onEdgesChange] = useEdgesState([])
    const [nodePositions, setNodePositions] = useState<any>()
    const [nodeLayoutError, setNodeLayoutError] = useState<any>(false)
    const onConnect = useCallback((params) => setEdges(
        (eds) => addEdge(params, eds)), [])


    let width = 400
    let height = 150


    useEffect(() => {
        if (nodePositions===undefined || nodePositions===null) return
        setNodes(nodes.map((it) => {
            it['position'] = nodePositions[it.id]
            return it
        }))
    }, [nodePositions])

    let leaderTable = (
        <div>
            <Spin
                className='detect-full-width-center--no-margin'
                size='small' />
        </div>
    )


    const collectionData = useQuery(
        [{ itemExtId: collectionItemId, groupVersionId: null }],
        getDetectProjectCollectionsData,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: 1000 * 60,
            retry: 1
        }
    )


    const collectionLeaderData = useQuery(
        [{
            itemExtId: currentCollection[0],
            groupVersionId: 'latest',
            'key': 'collectionLeaderData'
        }],
        getDetectGroupLeaderData,
        getUseQueryOptions(1)
    )

    if (collectionLeaderData.isSuccess) {
        let leaderData = collectionLeaderData.data

        leaderTable = <LastDataTable
            itemName={leaderData.itemName}
            forecasts={leaderData.forecasts}
            measureColumn={leaderData.measureColumn}
            timestampColumn={leaderData.timestampColumn}
            mainDimensionColumn={leaderData.mainDimensionColumn} />

    } else if (collectionLeaderData.isError) {
        leaderTable = <div><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
    }

    useEffect(() => {
        if (collectionData.isSuccess) {
            const nodesInfo = collectionData.data.collectionItemSummaryList
            const undeletedNodes = collectionData.data.collectionItemSummaryList.map(it => {
                return it.itemExternalId
            })
            let nodesList: any[] = []
            let edgesList: any[] = []
            nodesInfo.forEach((nodeInfo, index) => {
                nodesList.push({
                    id: nodeInfo.itemExternalId,
                    position: { x: index * width, y: index * height },
                    width: width,
                    height: height,
                    label: nodeInfo.itemName,
                    type: 'CollectionNode',
                    data: {
                        itemSummary: nodeInfo,
                        isLeader: nodeInfo.itemExternalId===collectionItemInfo.leaderKPI
                    }
                })
            })
            Object.entries(collectionItemInfo.graph).forEach((value, index) => {
                value[1].children.filter((it: string) => {
                    return undeletedNodes.includes(it) && (undeletedNodes.includes(value[0]))
                }).forEach((childItemId: string, indexTwo: number) =>
                    edgesList.push({
                        source: value[0],
                        target: childItemId,
                        sources: [value[0]],
                        targets: [childItemId],
                        id: index * 1000 + indexTwo,
                        style: {
                            strokeWidth: '3px'
                        },
                        markerEnd: {
                            type: MarkerType.ArrowClosed
                        }
                    })
                )
            })
            setEdges(edgesList)
            setNodes(nodesList)

            let elk = new ELK()
            elk.layout({
                id: 'root',
                layoutOptions: {
                    'elk.algorithm': 'mrtree',
                    'elk.direction': 'DOWN',
                    'elk.spacing.edgeNode': '10',
                    'elk.spacing.edgeEdge': '10',
                    'elk.spacing.nodeNode': '60'
                },
                children: nodesList,
                edges: edgesList
            })
                .then(it => {
                    let pos = it.children?.map(it => {
                        return [it.id, { x: it.x, y: it.y }]
                    })

                    setNodePositions(Object.fromEntries(pos!!))
                })
                .catch(() => {
                    setNodeLayoutError(true)
                })

        }
    }, [collectionData.isSuccess])


    let graphComponent = <div />
    if (nodePositions!==undefined || nodeLayoutError) {
        graphComponent = (
            <div style={{
                height: '80vh',
                width: '100%'
            }}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodeMouseEnter={(event, node) => {
                        setHidden(false)
                        setCurrentCollection([node.data.itemSummary.itemExternalId, node.data.itemSummary.itemName])
                    }}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onInit={onInit}
                    nodeTypes={nodeTypes}
                    fitView
                    attributionPosition='top-right'
                >
                    <Controls />
                    <Background color='#aaa' gap={16} />
                </ReactFlow>
            </div>
        )
    } else {
        graphComponent = <div style={{ width: '50%', height: '100%' }}>
            <Spin className={'detect-full-width-center'} tip={'Loading'} />
        </div>
    }

    useEffect(() => {
        setHidden(!(collectionLeaderData.isSuccess || collectionLeaderData.isLoading))
    }, [collectionLeaderData.isSuccess || collectionLeaderData.isLoading])

    return (
        <Row
            style={{ display: 'flex', flexDirection: 'row' }}>
            <Col span={hidden?24:16}>
                {graphComponent}
            </Col>
            <Col span={hidden?0:8}>
            <Card
                size={'small'}
                hidden={hidden}
                style={{ width: '100%', height: '100%' }}
                title={collectionData.isSuccess ? currentCollection[1] +
                    (collectionLeaderData.isSuccess && collectionLeaderData.data.forecasts.length > 0 ?
                        '  | '+collectionLeaderData.data.forecasts[0][collectionLeaderData.data.timestampColumn]
                        :'')
                    :'Collection Information'}
                bordered={true}
                extra={
                    <CloseCircleOutlined onClick={() => {
                        setHidden(!hidden)
                    }} />
                }

            >
                {leaderTable}
            </Card>
            </Col>
        </Row>)
}

export default ProjectGraphComponent
