// ML Platform
import { fetchEndpoint } from '../utils'
import {
    DataSourceEndpoints,
    DetectEndpoints,
    DetectGroupEndpoints,
    DetectUserEndpoints
} from '../Endpoints'
import {
    AddDataSourceRequest, AddKPI,
    DataSourceType,
    DeleteItemAccessRequest,
    DetectGroupItem,
    DetectItem,
    DetectModerateGroupRequest,
    DetectModerateRequest,
    GrantItemAccessRequest,
    RequestByItemExtId,
    UpdateDetectGroupItem,
    UpdateDetectItem
} from '../RequestTypes/Common'
import {
    DataSourceAddResponse,
    DetectUpdateGroupItemConfigResponse,
    DetectUpdateItemConfigResponse,
    ExceptionCount,
    ExceptionTraces
} from '../ResponseTypes/Common'
import { configParametersList } from '../../../components/Detect/Utils'
import { HttpRequest } from '../ResponseTypes/Common';

export const validateAndLogin = async () => {
    const data: any = await fetchEndpoint(
        DetectUserEndpoints.authorize,
        {},
        {},
        {},
        'POST'
    )
    return data
}

export const addDetectItem = async (detectItem: DetectItem) => {
    delete detectItem.configParameters
    let temp = { ...detectItem } as any
    detectItem.configParameters = configParametersList(
        detectItem.dataSourceType==null
            ? DataSourceType.DATA_PLATFORM
            :detectItem.dataSourceType
    ).reduce((result: any, key) => {
        if (temp.hasOwnProperty(key)) {
            result[key] = temp[key]
        }
        return result
    }, {})

    detectItem.configParameters['exceptionRules'] = {
        'mainDimensionExceptions': detectItem.mainDimensionExceptions
    }


    for (let idx = 0; idx < detectItem.algorithms.length; idx++) {
        ['service_name', 'resource_name', 'namespace', 'cluster'].forEach((key: string) => {
            if (key in detectItem) {
                detectItem.algorithms[idx].hyperParameters[key] = detectItem[key as keyof DetectItem]
            }
        })
    }

    const data: any = await fetchEndpoint(
        DetectEndpoints.itemBase,
        {},
        {},
        detectItem,
        'POST'
    )
    return data
}

export const addDetectGroupItem = async (detectGroupItem: DetectGroupItem) => {
    const data: any = await fetchEndpoint(
        DetectGroupEndpoints.groupsBase,
        {},
        {},
        detectGroupItem,
        'POST'
    )
    return data
}

export const addDataSource = async (
    dataSourceRequest: AddDataSourceRequest
) => {
    const data: DataSourceAddResponse = await fetchEndpoint(
        DataSourceEndpoints.dataSourceBase,
        {},
        {},
        dataSourceRequest,
        'POST'
    )
    return data
}

export const deleteDetectItem = async (
    detectDeleteItemRequest: RequestByItemExtId
) => {
    const data: any = await fetchEndpoint(
        DetectEndpoints.particularItem,
        detectDeleteItemRequest,
        {},
        {},
        'DELETE'
    )
    return data
}

export const deleteDetectGroupItem = async (
    detectDeleteItemRequest: RequestByItemExtId
) => {
    const data: any = await fetchEndpoint(
        DetectGroupEndpoints.particularGroupItem,
        detectDeleteItemRequest,
        {},
        {},
        'DELETE'
    )
    return data
}

export const updateDetectItemConfig = async (
    updateDetectItem: UpdateDetectItem
) => {
    delete updateDetectItem.item.configParameters

    let temp = { ...updateDetectItem.item } as any
    updateDetectItem.item.configParameters = configParametersList(
        updateDetectItem.item.dataSourceType==null
            ? DataSourceType.DATA_PLATFORM
            :updateDetectItem.item.dataSourceType
    ).reduce((result: any, key) => {
        if (temp.hasOwnProperty(key)) {
            result[key] = temp[key]
        }
        return result
    }, {})

    for (let idx = 0; idx < updateDetectItem.item.algorithms.length; idx++) {
        ['service_name', 'resource_name', 'namespace', 'cluster'].forEach((key: string) => {
            if (key in updateDetectItem.item) {
                updateDetectItem.item.algorithms[idx].hyperParameters[key] = updateDetectItem.item[key as keyof DetectItem]
            }
        })
    }

    updateDetectItem.item.configParameters['exceptionRules'] = {
        'mainDimensionExceptions': updateDetectItem.item.mainDimensionExceptions
    }


    const data: DetectUpdateItemConfigResponse = await fetchEndpoint(
        DetectEndpoints.itemConfig,
        { itemExtId: updateDetectItem.itemExtId },
        {},
        updateDetectItem.item,
        'PUT'
    )
    return data
}

export const updateDetectGroupItemConfig = async (
    updateDetectGroupItem: UpdateDetectGroupItem
) => {
    const data: DetectUpdateGroupItemConfigResponse = await fetchEndpoint(
        DetectGroupEndpoints.itemConfig,
        { itemExtId: updateDetectGroupItem.itemExtId },
        {},
        updateDetectGroupItem.item,
        'PUT'
    )
    return data
}

export const grantItemAccess = async (
    grantItemAccessRequest: GrantItemAccessRequest
) => {
    const data: any = await fetchEndpoint(
        DetectEndpoints.grantAccess,
        {},
        {},
        grantItemAccessRequest,
        'POST'
    )
    return data
}

export const deleteItemAccess = async (
    deleteItemAccessRequest: DeleteItemAccessRequest
) => {
    const data: any = await fetchEndpoint(
        DetectEndpoints.deleteAccess,
        {},
        {},
        deleteItemAccessRequest,
        'POST'
    )
    return data
}

export const grantGroupItemAccess = async (
    grantItemAccessRequest: GrantItemAccessRequest
) => {
    const data: any = await fetchEndpoint(
        DetectGroupEndpoints.grantAccess,
        {},
        {},
        grantItemAccessRequest,
        'POST'
    )
    return data
}

export const deleteGroupItemAccess = async (
    deleteItemAccessRequest: DeleteItemAccessRequest
) => {
    const data: any = await fetchEndpoint(
        DetectGroupEndpoints.deleteAccess,
        {},
        {},
        deleteItemAccessRequest,
        'POST'
    )
    return data
}

export const moderateItem = async (
    detectModerateRequest: DetectModerateRequest
) => {
    const data: any = await fetchEndpoint(
        DetectEndpoints.moderateItem,
        {},
        {},
        detectModerateRequest,
        'POST'
    )
    return data
}

export const moderateConfig = async (
    detectModerateRequest: DetectModerateRequest
) => {
    const data: any = await fetchEndpoint(
        DetectEndpoints.moderateConfig,
        {},
        {},
        detectModerateRequest,
        'POST'
    )
    return data
}

export const moderateGroupItem = async (
    detectModerateRequest: DetectModerateGroupRequest
) => {
    const data: any = await fetchEndpoint(
        DetectGroupEndpoints.moderateItem,
        {},
        {},
        detectModerateRequest,
        'POST'
    )
    return data
}

export const moderateGroupConfig = async (
    detectModerateRequest: DetectModerateGroupRequest
) => {
    const data: any = await fetchEndpoint(
        DetectGroupEndpoints.moderateConfig,
        {},
        {},
        detectModerateRequest,
        'POST'
    )
    return data
}

export const addKPIToCollection = async (
    addKPI: AddKPI
) => {
    const data: any = await fetchEndpoint(
        DetectGroupEndpoints.addKPI,
        {},
        {},
        addKPI,
        'POST'
    )
    return data
}

export const getExceptionList =  async ({
    cluster,
    namespace,
    serviceName,
    resourceName,
    fromDt,
    toDt,
    only5xx
}: {
    cluster: string,
    namespace: string,
    serviceName: string,
    resourceName: string | null,
    fromDt: string,
    toDt: string,
    only5xx: boolean
}) => { 
    const data: Array<ExceptionCount> = await fetchEndpoint(
        DetectEndpoints.exceptions,
        { },
        { cluster, serviceName, namespace, resourceName, from: fromDt, to: toDt, only5xx },
        {},
        'GET'
    )
    return data
}

export const getTraceIds = async ({
    cluster,
    namespace,
    serviceName,
    resourceName,
    fromDt,
    toDt,
    only5xx,
    exceptionMessage
}: {
    cluster: string,
    namespace: string,
    serviceName: string,
    resourceName: string | null,
    fromDt: string,
    toDt: string,
    only5xx: boolean,
    exceptionMessage: string
}) => {
    const data: Array<ExceptionTraces> = await fetchEndpoint(
        DetectEndpoints.traceIds,
        {},
        { cluster, namespace, serviceName, resourceName, from: fromDt, to: toDt, only5xx, exceptionMessage},
        {},
        'GET'
    )
    return data
}

export const getHttpRequestByTraceId = async ({traceId, cluster }: {traceId: string, cluster: string }) => {
    const data: Array<HttpRequest> = await fetchEndpoint(
        DetectEndpoints.httpRequestsByTraceId,
        { traceId},
        { cluster },
        {},
        'GET'
    )
    return data
}

