import * as React from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Typography,
    Slide
} from "@material-ui/core";
import { Exception } from "../../../../../lib/api/ResponseTypes/Common";
import { CloseOutlined  } from "@ant-design/icons";
import { extractStackTraceCauses } from "./TraceUtils";
import { TransitionProps } from '@material-ui/core/transitions';
import FBox from "./FBox";

const ExceptionView: React.FunctionComponent<{exceptions: Exception[], onClose: () => void }> = ({exceptions, onClose}) => {      
    const Transition = React.forwardRef(function Transition(
      props: TransitionProps & { children?: React.ReactElement },
      ref: React.Ref<unknown>,
    ) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
    
  return(
      <Dialog onClose={onClose} open={true} fullScreen TransitionComponent={Transition}>
          <DialogTitle>
              <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" size="small">
                  <CloseOutlined />
              </IconButton>
              <span style={{paddingLeft: '10px'}}>Exception Details</span>
          </DialogTitle>
          <DialogContent dividers>
              {exceptions.map(exception => (
                  <Box mt={2} key={Math.round(Math.random()*1000000)}>
                      <Paper>
                          <FBox padding={2} flex={1} flexDirection={"column"}>
                              <FBox flex={1} flexDirection={"row"} alignItems={"center"}>
                                  <FBox flex={1} flexDirection={"column"}>
                                      <FBox flex={1} flexDirection={"row"}>
                                          <Typography color={"primary"}>
                                              {exception.serviceName}
                                          </Typography>
                                          {/* <Typography color={"textSecondary"} style={{marginLeft: 4}}>
                                              {exception.Pod}
                                          </Typography> */}
                                      </FBox>
                                      {exception.properties.telemetry_type && (
                                          <Typography color={"textSecondary"}>
                                              Request Type: {exception.properties.telemetry_type}
                                          </Typography>
                                      )}
                                      {exception.properties.loc && (
                                          <Typography color={"textSecondary"}>
                                              Loc: {exception.properties.loc}
                                          </Typography>
                                      )}
                                      {exception.properties.uri && (
                                          <Typography color={"textSecondary"}>
                                              Path: {exception.properties.uri}
                                          </Typography>
                                      )}
                                  </FBox>
                              </FBox>
                              <Box mt={2} key={`stackTrace`}>
                                  <Typography color={"textPrimary"}>{exception.type}: {exception.message}</Typography>
                                  <Box overflow={"scroll"} maxHeight={300}>
                                      <Typography color={"textSecondary"} component={"pre"}>
                                          {exception.stackTrace}
                                      </Typography>
                                  </Box>
                              </Box>
                              {extractStackTraceCauses(exception.stackTrace).map((cause, idx) => <Box mt={2} key={`cause-${idx}`}>
                                  <Typography color={"textPrimary"}>{cause.message}</Typography>
                                  <Box overflow={"scroll"} maxHeight={300}>
                                      <Typography color={"textSecondary"} component={"pre"}>
                                          {cause.stackTrace}
                                      </Typography>
                                  </Box>
                              </Box>)}
                          </FBox>
                      </Paper>
                  </Box>
              ))}
          </DialogContent>
      </Dialog>
  )
};

export default ExceptionView