import React, { useEffect, useState } from 'react'
import KPIChart from './KPIChart'
import { Modal, Button, Card, Empty, Spin, Select } from 'antd'
import DataTable from 'react-data-table-component'
import { useQuery } from 'react-query'
import { getFormattedChange, getUseQueryOptions } from '../../Utils'
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'
import { getDetectResultForecast } from '../../../../lib/api/QueryApis/Common'

import './index.css'
import { isFloat } from '../../Utils'
import { getFormattedNumber } from '../../../../lib/utils/common'
import { DetectAlgorithmInput } from '../../../../lib/api/RequestTypes/Common'

const { Option } = Select

export interface DetectChartCardProps {
    title: any
    filepath: string
    tsCol: string
    measureCol: string
    granularity: number
    height: string
    onClickCallback?: Function
    showOnlyFocused?: boolean
    focusedAnomalyIdx?: number
    statsOnRight?: boolean
    algorithms: DetectAlgorithmInput[]
}

const KPIChartCard = (
    {
        height,
        title,
        filepath,
        tsCol,
        measureCol,
        granularity,
        onClickCallback,
        statsOnRight,
        algorithms
    }: DetectChartCardProps) => {
    const [anomalyStats, setAnomalyStats] = useState([
        <span />,
        <span />,
        <span />,
        <span />
    ])
    const [currAnomaly, setCurrAnomaly] = useState<number | null>(null)
    const [viewData, setViewData] = useState<boolean>(false)
    const [currConfigAlgorithmId, setCurrConfigAlgorithmId] = useState<string>(
        algorithms[0].configAlgorithmId!!
    )

    const dataQuery = useQuery(
        [
            {
                filepath: filepath,
                tsCol: tsCol,
                measureCol: measureCol,
                granularity: granularity,
                configAlgorithmId: currConfigAlgorithmId
            }
        ],
        getDetectResultForecast,
        getUseQueryOptions(1, 5 * 60 * 1000)
    )

    useEffect(() => {
        if (!dataQuery.isSuccess || currAnomaly===null) return
        let data = dataQuery.data.forecasts
        let start_val = data[currAnomaly - 1][measureCol]
        let end_val = data[currAnomaly][measureCol]
        let impact_val = data[currAnomaly]['impact']

        let time = <span>{data[currAnomaly][tsCol]}</span>
        let change = getFormattedChange((end_val - start_val) / start_val)

        let impact = <span />
        if (impact_val!==undefined) {
            impact = getFormattedChange(impact_val)
        }
        let difference = <span>{getFormattedNumber(end_val - start_val)}</span>
        setAnomalyStats([difference, change, impact, time])
    }, [currAnomaly])

    useEffect(() => {
        setCurrAnomaly(null)
    }, [filepath])

    useEffect(() => {
        if (
            dataQuery.isSuccess &&
            dataQuery.data.lastAnomaly!==null &&
            dataQuery.data.lastAnomaly!==0 &&
            currAnomaly==null
        ) {
            setCurrAnomaly(dataQuery.data.lastAnomaly)
        }
    }, [dataQuery])

    const onGraphClick = (state: CategoricalChartState) => {
        if (
            state &&
            state.activeTooltipIndex &&
            state.activeTooltipIndex > 0 &&
            dataQuery.data!!.forecasts[state.activeTooltipIndex]['anomaly']
        ) {
            setCurrAnomaly(state.activeTooltipIndex)
            if (onClickCallback) {
                onClickCallback(state.activeTooltipIndex)
            }
        }
    }

    let innerContent = <div />
    if (dataQuery.isLoading) {
        innerContent = (
            <div className='detect-full-center' style={{ height: height }}>
                <Spin tip='loading'></Spin>
            </div>
        )
    } else if (dataQuery.isSuccess) {
        innerContent = (
            <div style={{ width: '100%', height: height }}>
                <KPIChart
                    algorithms={algorithms}
                    measureCol={measureCol}
                    tsCol={tsCol}
                    data={dataQuery.data.forecasts}
                    showForecast={true}
                    onClick={onGraphClick}
                />
            </div>
        )
    } else if (dataQuery.isError) {
        innerContent = (
            <div className='detect-full-center' style={{ height: height }}>
                <Empty description={<span>Graph Not Available</span>} />
            </div>
        )
    }

    const convertArrayOfObjectsToCSV = (items: any) => {
        const replacer = (key: any, value: any) => (value===null ? '':value)
        const header: any = Object.keys(items[0])
        const csv = [
            header.join(','),
            ...items.map((row: any) =>
                header
                    .map((fieldName: any) =>
                        JSON.stringify(row[fieldName], replacer)
                    )
                    .join(',')
            )
        ].join('\r\n')

        return csv
    }

    const downloadCSV = (array: any) => {
        const link = document.createElement('a')
        let csv = convertArrayOfObjectsToCSV(array)
        if (csv==null) return

        const filename = 'export.csv'

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`
        }

        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', filename)
        link.click()
    }

    var dataTable = (
        <div className='detect-full-center'>
            <Spin tip='loading'></Spin>
        </div>
    )

    if (dataQuery.isSuccess && dataQuery.data.forecasts.length > 0) {

        var columns = []
        var data = dataQuery.data.forecasts

        if (data[0]) {
            for (const key of Object.keys(data[0]))
                columns.push({
                    name: key!='Unnamed: 0' ? key:'#',
                    selector: key,
                    sortable:
                        key=='anomaly' || key=='Unnamed: 0' ? true:false,
                    cell: (row: any) => {
                        if (key=='ci')
                            return [
                                getFormattedNumber(row['ci'][0]),
                                getFormattedNumber(row['ci'][1])
                            ].join(' - \n')
                        else if (key=='anomaly')
                            return (
                                <span
                                    style={{
                                        color:
                                            row['anomaly'] < row['ci'][0]
                                                ? 'green'
                                                :'red'
                                    }}
                                >
									{getFormattedNumber(row['anomaly'])}
								</span>
                            )
                        else
                            return isFloat(row[key])
                                ? getFormattedNumber(row[key])
                                :row[key]
                    }
                })
        }

        dataTable = (
            <DataTable
                columns={columns}
                data={data ? data:[]}
                highlightOnHover
                striped={true}
            />
        )
    }

    let stats = currAnomaly && (
        <div className='detect-graph-stats'>
            <ul>
                <li>
                    <div className='detect-graph-stats__title'>Time:</div>
                    <div className='detect-graph-stats__body'>
                        {' '}
                        {anomalyStats[3]}
                    </div>
                </li>
                <li>
                    <div className='detect-graph-stats__title'> Change:</div>
                    <div className='detect-graph-stats__body'>
                        {' '}
                        {anomalyStats[0]}{' '}
                        <span style={{ fontSize: '10pt' }}>
							{anomalyStats[1]}
						</span>
                    </div>
                </li>
                <li>
                    <div className='detect-graph-stats__title'>Impact:</div>
                    <div className='detect-graph-stats__body'>
                        {' '}
                        {anomalyStats[2]}
                    </div>
                </li>
                <li>
                    <Button
                        className='detect-button--primary detect-graph-stats__title'
                        style={{ marginTop: '10px' }}
                        onClick={() => setViewData(true)}
                    >
                        View Data
                    </Button>
                </li>
            </ul>
        </div>
    )

    return (
        <>
            <Card size='small' className='detect-graph-card'>
                <div className='detect-graph-card__header'>
                    {title}{' '}
                    <span style={{ marginLeft: '20px', width: '100%' }}>
						Algorithm:{' '}
                        <Select
                            defaultValue={algorithms[0].configAlgorithmId}
                            onChange={(it) => {
                                setCurrConfigAlgorithmId(it)
                            }}
                        >
							{algorithms.map((it) => {
                                return <Option
                                    value={it.configAlgorithmId!!}> {it.severity.toLowerCase()} | {it.algorithm}</Option>
                            })}
						</Select>
					</span>
                </div>
                <div className='detect-graph-card__body'>
                    {!statsOnRight && stats}
                    <div
                        style={{ width: currAnomaly!==null ? '85%':'100%' }}
                    >
                        {innerContent}
                    </div>
                    {statsOnRight && stats}
                </div>
            </Card>

            <Modal
                title='View Data'
                visible={viewData}
                width='fit-content'
                footer={null}
                bodyStyle={{ paddingLeft: '20px', paddingRight: '20px' }}
                onCancel={() => setViewData(false)}
            >
                {dataTable}
            </Modal>
        </>
    )
}

export default KPIChartCard
