import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

const Loading = (
    {
        styles
    }: {
        styles?: React.CSSProperties
    }) => {
    return (
        <LoadingOutlined
            style={{ color: 'green[500]', fontSize: '24px', ...styles }}
        />
    )
}

export default Loading
