import React, { useEffect, useState } from 'react'
import { Input, Space } from 'antd'
import { preventEnterToSubmit } from '../../Detect/Utils'
import './index.css'

type ChangeGranularity = (a: number) => void;

interface IntervalPickerProps {
    timeInterval: number,
    onChange: ChangeGranularity
    disabled: boolean
}

const IntervalPicker = (props: IntervalPickerProps) => {

    const [days, setDays] = useState(1)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)

    const updateAndReturn = (type: string, value: number) => {
        let currDays = days
        let currHours = hours
        let currMinutes = minutes

        switch (type) {
            case 'd':
                currDays = value
                break
            case 'h':
                currHours = value
                break
            case 'm':
                currMinutes = value
                break
        }

        props.onChange(currDays * 60 * 60 * 24 + currHours * 60 * 60 + currMinutes * 60)

        setDays(currDays)
        setHours(currHours)
        setMinutes(currMinutes)
    }

    useEffect(() => {
        if (props.timeInterval===undefined || Number.isNaN(props.timeInterval)) return
        setDays(Math.floor(props.timeInterval / (60 * 60 * 24)))
        setHours(Math.floor((props.timeInterval % (60 * 60 * 24)) / (60 * 60)))
        setMinutes(Math.floor((props.timeInterval % (60 * 60)) / 60))
    }, [])


    return (
        <span>
      <Space>
        <Input
            onKeyDown={preventEnterToSubmit}
            type='number'
            value={days}
            min={0}
            disabled={props.disabled}
            placeholder='Days'
            className='interval-input--short'
            onChange={(event) => {
                updateAndReturn('d', parseInt(event.target.value))
            }} />
         Day
        <Input
            onKeyDown={preventEnterToSubmit}
            type='number'
            value={hours}
            min={0}
            className='interval-input--short'
            disabled={props.disabled}
            placeholder='Hours'
            onChange={(event) => {
                updateAndReturn('h', parseInt(event.target.value))
            }} />
        Hrs
        <Input
            type='number'
            value={minutes}
            min={0}
            className='interval-input--short'
            onKeyDown={preventEnterToSubmit}
            disabled={props.disabled}
            placeholder={`Minutes`}
            onChange={(event) => {
                updateAndReturn('m', parseInt(event.target.value))
            }} />
        Min
      </Space>
    </span>
    )
}

export default IntervalPicker
