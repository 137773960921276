import {
    DataSourceEndpoints,
    DetectEndpoints,
    DetectGroupEndpoints,
    DetectUserEndpoints,
    UserInfoEndpoints,
} from '../Endpoints'
import { fetchEndpoint } from '../utils'
let qs = require('querystringify')
import {
    DetectGetItemRequest,
    DetectGetRequestedRequest,
    DetectGetResultRequest,
    DetectGetUserItemsCountRequest,
    DetectGetUserGroupItemsRequest,
    DetectItem,
    DetectResultForecastRequest,
    GenericItemRequestParams,
    GetDataSourceRequest,
    ItemType,
    ListUsersLikeRequestParams,
    RequestByItemExtId,
    DetectGetUserItemsRequest,
    DetectGroupItem,
    DetectGetGroupItemRequest,
    DetectGetMultipleItemsRequest,
    DetectGetUserGroupItemsCountRequest,
    DetectGetMultipleGroupItemsRequest,
    DetectGetRequestedGroupRequest,
    DataSourceType,
    DataSourceTypeRequestParams,
    DetectExecutionLogsRequest,
    DataSourceAlertRequestParams,
    UdaanServiceRequest,
    UdaanResourceRequest,
    ResourceDependenciesRequestParams,
    DataSourceQueryRequestParams, ThanosQueryRequest
} from '../RequestTypes/Common'

import {
    CanModerateResponse,
    CollectionKPIData,
    CountResponse,
    DataSourceColumn,
    DataSourceQuery,
    DetectAlgorithm,
    DetectExecutionLog,
    DetectGroupItemSummary,
    DetectInitFormData,
    DetectItemSummary,
    DetectResultResponse,
    ForecastResponse,
    GetUserInfoAPIResponse,
    GetUserScopeResponse,
    PendingRequestsResponse,
    ListUsersLikeAPIResponse,
    ProjectCollectionData,
    UserPermission,
    LastDataResponse,
    DetectUserProfile,
    ResourceDependenciesResponse,
    DetectConfigDataSourceSummary,
    KPIFiringAlertsResponse,
    CollectionHealthResponse,
    ProjectHealthResponse,
    Exception,
} from '../ResponseTypes/Common'
import { fetchDPEndpoint } from '../dpUtils'
import { getFormattedDate, getPriorityCol } from '../../../components/Detect/Utils'
import dayjs from "dayjs"

/* DP: User Info */

export default function sleep(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getUserScopes = async (key: string, params: {}) => {
    const data: GetUserScopeResponse = await fetchDPEndpoint(
        UserInfoEndpoints.userScopesList,
        params
    )
    return data.response
}

export const getUserProfile = async () => {
    const data: DetectUserProfile = await fetchEndpoint(
        DetectUserEndpoints.userProfile,
        {},
        {},
        {},
        'GET'
    )
    return data
}

/* User */

export const getUserInfo = async (key: string) => {
    const data: GetUserInfoAPIResponse = await fetchDPEndpoint(
        UserInfoEndpoints.userInfo,
        {},
        'GET'
    )
    return data.response
}

/* User IDs and Email -> DP*/
export const listEmailsStartingWith = async (
    params: ListUsersLikeRequestParams
) => {
    const data: ListUsersLikeAPIResponse = await fetchDPEndpoint(
        UserInfoEndpoints.listUsersLike,
        params,
        'GET'
    )
    return data.response
}

export const getDetectItem = async (
    detectGetItemRequest: DetectGetItemRequest
) => {
    const data: DetectItem = await fetchEndpoint(
        DetectEndpoints.particularItem,
        { itemExtId: detectGetItemRequest.itemExtId },
        { configId: detectGetItemRequest.configId },
        {},
        'GET'
    )
    data.configParameters = new Map(Object.entries(data.configParameters))
    data.mainDimensionColumn = data.configParameters?.get('mainDimensionColumn')
    data.timestampColumn = data.configParameters?.get('timestampColumn')
    data.categoricalDimensionColumns = data.configParameters?.get(
        'categoricalDimensionColumns'
    )
    data.continuousDimensionColumns = data.configParameters?.get(
        'continuousDimensionColumns'
    )
    data.measureColumn = data.configParameters?.get('measureColumn')
    data.granularity = data.configParameters?.get('granularity')
    data.timePeriod = data.configParameters?.get('timePeriod')
    data.dataSourceItemPath = data.configParameters?.get('dataSourceItemPath')
    data.dataSourceItemExtId = data.configParameters?.get('dataSourceItemExtId')
    data.ignoreLatest = data.configParameters?.get('ignoreLatest')
    data.taskType = data.configParameters?.get('taskType')
    data.taskConfig = data.configParameters?.get('taskConfig')
    data.taskTriggerMode = data.configParameters?.get('taskTriggerMode')
    data.triggerEndpoint = data.configParameters?.get('triggerEndpoint')
    data.computeType = data.configParameters?.get('computeType')
    data.aggregate = data.configParameters?.get('aggregate')
    data.aggregationRule = data.configParameters?.get('aggregationRule')
    data.fillMissingDates = data.configParameters?.get('fillMissingDates')
    data.prometheusMetricTypeId = data.configParameters?.get(
        'prometheusMetricTypeId'
    )
    data.service_name = data.configParameters?.get('service_name')
    data.namespace = data.configParameters?.get('namespace')
    data.cluster = data.configParameters?.get('cluster')
    data.resource_name = data.configParameters?.get('resource_name')
    let exceptionRules = data.configParameters?.get('exceptionRules')
    if (exceptionRules!==undefined) {
        data.mainDimensionExceptions = exceptionRules['mainDimensionExceptions']
    }

    return data
}

export const getDetectItemSummary = async (
    detectGetItemRequest: DetectGetItemRequest
) => {
    const data: DetectItemSummary = await fetchEndpoint(
        DetectEndpoints.particularItemSummary,
        { itemExtId: detectGetItemRequest.itemExtId },
        {},
        {},
        'GET'
    )
    return data
}

export const getMultipleItemSummaries = async (
    detectGetMultipleItemsRequest: DetectGetMultipleItemsRequest
) => {
    const data: Array<DetectItemSummary> = await fetchEndpoint(
        DetectEndpoints.multipleSummaries,
        {},
        detectGetMultipleItemsRequest,
        {},
        'GET'
    )
    return data
}

export const getMultipleGroupItemSummaries = async (
    detectGetMultipleItemsRequest: DetectGetMultipleGroupItemsRequest
) => {
    const data: Array<DetectGroupItemSummary> = await fetchEndpoint(
        DetectGroupEndpoints.multipleSummaries,
        {},
        detectGetMultipleItemsRequest,
        {},
        'GET'
    )
    return data
}

export const getUserDetectItems = async (
    detectGetUserItemsRequest: DetectGetUserItemsRequest
) => {
    const data: Array<DetectItemSummary> = await fetchEndpoint(
        DetectEndpoints.userConfigs,
        {},
        detectGetUserItemsRequest,
        {},
        'GET'
    )
    return data
}

export const getRequestedDetectItems = async (
    detectGetRequestedRequest: DetectGetRequestedRequest
) => {
    const data: Array<DetectItemSummary> = await fetchEndpoint(
        DetectEndpoints.requestedConfigs,
        {},
        detectGetRequestedRequest,
        {},
        'GET'
    )
    return data
}

export const getRequestedDetectGroupItems = async (
    detectGetRequestedRequest: DetectGetRequestedGroupRequest
) => {
    const data: Array<DetectGroupItemSummary> = await fetchEndpoint(
        DetectGroupEndpoints.requestedConfigs,
        {},
        detectGetRequestedRequest,
        {},
        'GET'
    )
    return data
}

export const getDetectResult = async (
    detectGetResultRequest: DetectGetResultRequest
) => {
    const data: DetectResultResponse = await fetchEndpoint(
        DetectEndpoints.getResult,
        { itemExtId: detectGetResultRequest.itemExtId },
        { anomalyId: detectGetResultRequest.anomalyId },
        {},
        'GET'
    )
    return data
}

/*
export const getDetectResultFromConfig = async (
	detectGetResultFromConfigRequest: DetectGetResultFromConfigRequest
) => {
	const data: DetectResultFromConfigResponse = await fetchEndpoint(
		DetectEndpoints.getResultFromConfig,
		{ configId: detectGetResultFromConfigRequest.configId },
		{ anomalyId: detectGetResultFromConfigRequest.anomalyId },
		{},
		'GET'
	)
	return data
}
*/
export const getDataSourceColumns = async (
    getDataSourceRequest: GetDataSourceRequest
) => {
    const data: DataSourceColumn[] = await fetchEndpoint(
        DataSourceEndpoints.dataSourceColumns,
        getDataSourceRequest,
        {},
        {},
        'GET'
    )
    return data
}

export const getFormInitData = async () => {
    const data: DetectInitFormData = await fetchEndpoint(
        DetectEndpoints.getFormInitData,
        {},
        {},
        {},
        'GET'
    )
    return data
}

export const getDetectUserItemsCount = async (
    itemsCountRequest: DetectGetUserItemsCountRequest
) => {
    const data: CountResponse = await fetchEndpoint(
        DetectEndpoints.userItemsCount,
        {},
        itemsCountRequest,
        {},
        'GET'
    )
    return data
}

export const getDetectUserGroupItemsCount = async (
    itemsCountRequest: DetectGetUserGroupItemsCountRequest
) => {
    const data: CountResponse = await fetchEndpoint(
        DetectGroupEndpoints.userItemsCount,
        {},
        itemsCountRequest,
        {},
        'GET'
    )
    return data
}

export const getDetectRequestedCount = async () => {
    const data: CountResponse = await fetchEndpoint(
        DetectEndpoints.requestedConfigsCount,
        {},
        {},
        {},
        'GET'
    )
    return data
}

export const getDetectGroupsRequestedCount = async (groupType: ItemType) => {
    const data: CountResponse = await fetchEndpoint(
        DetectGroupEndpoints.requestedConfigsCount,
        {},
        { groupType: groupType },
        {},
        'GET'
    )
    return data
}

export const canModerate = async () => {
    const data: CanModerateResponse = await fetchEndpoint(
        DetectEndpoints.canModerate,
        {},
        {},
        {},
        'GET'
    )
    return data
}

export const getItemUserPermissions = async ({
    itemExtId,
}: RequestByItemExtId) => {
    const data: UserPermission[] = await fetchEndpoint(
        DetectEndpoints.itemUserPermissions,
        { itemExtId: itemExtId },
        {},
        {},
        'GET'
    )
    return data
}

export const getGroupItemUserPermissions = async ({
    itemExtId,
}: RequestByItemExtId) => {
    const data: UserPermission[] = await fetchEndpoint(
        DetectGroupEndpoints.itemUserPermissions,
        { itemExtId: itemExtId },
        {},
        {},
        'GET'
    )
    return data
}

export const getDetectResultForecast = async ({
    filepath,
    tsCol,
    measureCol,
    granularity,
    configAlgorithmId,
}: DetectResultForecastRequest) => {
    const data: ForecastResponse = await fetchEndpoint(
        DetectEndpoints.resultForecast,
        {},
        {
            filepath: filepath,
            configAlgorithmId: configAlgorithmId,
            tsCol: tsCol,
            measureCol: measureCol,
        },
        {},
        'GET'
    )
    let options: any =
        granularity >= 60 * 60 * 24
            ? {
                  month: 'short',
                  day: 'numeric',
              }
            : {
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: false,
              }
    for (let idx in data.forecasts) {
        data.forecasts[idx][tsCol] = new Date(
            data.forecasts[idx][tsCol]
        ).toLocaleDateString('en-IN', options)
    }
    return data
}

export const getDetectExecutionLog = async ({
    item_external_id,
    limit,
    offset,
}: DetectExecutionLogsRequest) => {
    const data: DetectExecutionLog[] = await fetchEndpoint(
        DetectEndpoints.getResultHistory,
        { itemExtId: item_external_id },
        { limit: limit, offset: offset },
        {},
        'GET'
    )
    return data
}

export const getDetectExecutionLogCount = async ({
    item_external_id,
}: GenericItemRequestParams) => {
    const data: CountResponse = await fetchEndpoint(
        DetectEndpoints.getResultHistoryCount,
        { itemExtId: item_external_id },
        {},
        {},
        'GET'
    )
    return data
}

export const getDetectItemLastData = async ({
    item_external_id,
}: GenericItemRequestParams) => {
    const data: any = await fetchEndpoint(
        DetectEndpoints.lastData,
        { itemExtId: item_external_id },
        {},
        {},
        'GET'
    )
    return data
}

export const checkPendingRequests = async ({
    item_external_id,
}: GenericItemRequestParams) => {
    const data: PendingRequestsResponse = await fetchEndpoint(
        DetectEndpoints.pendingRequests,
        { itemExtId: item_external_id },
        {},
        {},
        'GET'
    )
    return data
}

export const getUserDetectGroupItems = async (
    detectGetUserItemsRequest: DetectGetUserGroupItemsRequest
) => {
    const data: Array<DetectGroupItemSummary> = await fetchEndpoint(
        DetectGroupEndpoints.groupsBase,
        {},
        detectGetUserItemsRequest,
        {},
        'GET'
    )
    return data
}

export const getDetectCollectionsKPIsData = async (
    detectItemsRequest: DetectGetItemRequest
) => {
    const data: CollectionKPIData = await fetchEndpoint(
        DetectGroupEndpoints.collectionKPIData,
        { itemExtId: detectItemsRequest.itemExtId },
        {},
        {},
        'GET'
    )
    return data
}

export const getDetectProjectCollectionsData = async (
    detectItemsRequest: DetectGetGroupItemRequest
) => {
    const data: ProjectCollectionData = await fetchEndpoint(
        DetectGroupEndpoints.projectCollectionData,
        { itemExtId: detectItemsRequest.itemExtId },
        {},
        {},
        'GET'
    )
    return data
}

export const getDetectGroupItem = async (
    detectGetItemRequest: DetectGetGroupItemRequest
) => {
    const data: DetectGroupItem = await fetchEndpoint(
        DetectGroupEndpoints.particularGroupItem,
        { itemExtId: detectGetItemRequest.itemExtId },
        detectGetItemRequest,
        {},
        'GET'
    )
    return data
}

export const getDetectGroupLeaderData = async (
    detectGetItemRequest: DetectGetGroupItemRequest
) => {
    const data: LastDataResponse = await fetchEndpoint(
        DetectGroupEndpoints.particularGroupItemLeaderData,
        { itemExtId: detectGetItemRequest.itemExtId },
        detectGetItemRequest,
        {},
        'GET'
    )
    return data
}

export const getUdaanServiceList = async (
    udaanServiceRequest: UdaanServiceRequest
) => {
    const data: Array<string> = await fetchEndpoint(
        DetectEndpoints.udaanServiceList,
        {},
        udaanServiceRequest,
        {},
        'GET'
    )
    return data
}

export const getUdaanResourceList = async (
    udaanResourceRequest: UdaanResourceRequest
) => {
    const data: Array<string> = await fetchEndpoint(
        DetectEndpoints.udaanResourceList,
        {},
        udaanResourceRequest,
        {},
        'GET'
    )
    return data
}

export const getDataSourceQueries = async ({
    dataSourceType,
}: DataSourceTypeRequestParams) => {
    const data: Array<DataSourceQuery> = await fetchEndpoint(
        DataSourceEndpoints.dataSourceMetricQueries,
        { dataSourceType: dataSourceType },
        {},
        {},
        'GET'
    )
    return data
}

export const getDataSourceQuery = async ({
    dataSourceType,
    queryId,
}: DataSourceQueryRequestParams) => {
    const data: DataSourceQuery = await fetchEndpoint(
        DataSourceEndpoints.dataSourceMetricQuery,
        { dataSourceType: dataSourceType },
        { queryId: queryId },
        {},
        'GET'
    )
    return data
}

export const getDataSourceAlerts = async ({
    dataSourceType,
    queryType,
}: DataSourceAlertRequestParams) => {
    const data: { [key: string]: DetectAlgorithm } = await fetchEndpoint(
        DataSourceEndpoints.dataSourceAlerts,
        { dataSourceType: dataSourceType, queryType: queryType },
        {},
        {},
        'GET'
    )
    return data
}

export const getDataSourceSummary = async ({
    itemExtId,
}: RequestByItemExtId) => {
    const data: DetectConfigDataSourceSummary = await fetchEndpoint(
        DetectEndpoints.dataSourceSummary,
        { itemExtId: itemExtId },
        {},
        {},
        'GET'
    )
    return data
}

export const getResourceDependency = async ({
    cluster,
    namespace,
    serviceName,
    resourceName,
}: ResourceDependenciesRequestParams) => {
    const data: ResourceDependenciesResponse = await fetchEndpoint(
        DetectEndpoints.resourceDependency,
        {},
        {
            cluster: cluster,
            namespace: namespace,
            serviceName: serviceName,
            resourceName: resourceName,
        },
        {},
        'GET'
    )
    return data
}

export const getKPIAlertStatus = async ({
    itemExtId,
    configId,
}: DetectGetItemRequest) => {
    const data: KPIFiringAlertsResponse = await fetchEndpoint(
        DetectEndpoints.kpiFiringAlerts,
        { itemExtId: itemExtId },
        { configId: configId },
        {},
        'GET'
    )
    return data
}

export const getCollectionHealth = async ({
    itemExtId, groupVersionId
}: DetectGetGroupItemRequest) => {
    const data: CollectionHealthResponse = await fetchEndpoint(
        DetectEndpoints.collectionHealth,
        { itemExtId: itemExtId },
        { groupVersionId: groupVersionId },
        {},
        'GET'
    )
    return data
}

export const getProjectHealth = async (
    { itemExtId, groupVersionId }: DetectGetGroupItemRequest
) => {
    const data: ProjectHealthResponse = await fetchEndpoint(
        DetectEndpoints.projectHealth,
        { itemExtId: itemExtId },
        { groupVersionId: groupVersionId },
        {},
        'GET'
    )
    return data
}

export const thanosQuery = async ({ query,step,range, label }: ThanosQueryRequest) => {

    let params = {
        query: query,
        step: Math.max(step, Math.floor(range / 1000)),
        max_source_resolution: 0,
        dedup: true,
        start: Date.now() / 1000 - range,
        end: Date.now() / 1000,
        partial_response: false
    }

    let queryComp = qs.stringify(params, true)

    const resp: { metric: any, values: any }[] = (await (await fetch(
        '/api/thanos/api/v1/query_range' + queryComp
    )).json() as any)['data']['result']
    if (resp.length===0) {
        return { data: [], min: 0 }
    } else if (resp.length===1) {
        let minVal = 1e7
        let maxVal = -1e7
        let data: any[] = resp[0].values.filter((it: any) => {
            return it[1]!=='NaN'
        }).map((it: any) => {
            let currentVal = parseInt(it[1])
            minVal = Math.min(currentVal, minVal)
            maxVal = Math.max(maxVal, currentVal)
            // return { date: getFormattedDate(it[0], false, true), value: parseInt(it[1]), label: label }
            return { date: dayjs.unix(it[0]).format("YYYY-MM-DD HH:mm"), value: parseInt(it[1]), label: label }
        })
        return { min: minVal===maxVal ? 0:minVal, data: data }
    } else {
        let data: any[] = []
        let minVal = 1e7
        let maxVal = -1e7
        resp.forEach(it => {
            let label = it.metric[getPriorityCol(it.metric)]
            let values = it.values.filter((it: any) => {
                return it[1]!=='NaN'
            }).map((it: any) => {
                let currentVal = parseInt(it[1])
                minVal = Math.min(currentVal, minVal)
                maxVal = Math.max(maxVal, currentVal)
                // return { date: getFormattedDate(it[0], false, true) ,value: currentVal, label: label }
                return { date: dayjs.unix(it[0]).format("YYYY-MM-DD HH:mm"), value: parseInt(it[1]), label: label }
            })
            data = [...data, ...values]
        })
        return { min: minVal===maxVal ? 0:minVal, data: data }
    }
}

export const getExceptionDetails = async (
    { serviceName, cluster, namespace, traceId }: { serviceName:string, cluster:string, namespace:string, traceId:string }
) => {
    const data: Exception[] = await fetchEndpoint(
        DetectEndpoints.exception,
        { traceId },
        { cluster, namespace, serviceName  },
        {},
        'GET'
    )
    return data
}