import React from 'react'
import { useCookies } from 'react-cookie'
import { Redirect, Route } from 'react-router-dom'
import { Cookie } from '../lib/api/constants'

export default function PrivateRoute({ children, ...rest }: any) {
  const [cookies] = useCookies([Cookie.id_token, Cookie.refresh_token])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        cookies[Cookie.id_token] || cookies[Cookie.refresh_token] ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
