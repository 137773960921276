import React, { useEffect, useState } from 'react'
import { Avatar, Col, Input, Row, Space } from 'antd'
import { preventEnterToSubmit } from '../Detect/Utils'

interface UserInfoProp {
    username: string | null,
    email: string | null,
    avatar: string | null
    showEmail: boolean | null
}

const UserInfo = ({ username, email, avatar, showEmail }: UserInfoProp) => {

    return (
        <Row gutter={24}>
            <Col span={4} style={{ padding: '5px', paddingRight: '10px' }}>
                <Avatar src={avatar} />
            </Col>
            {showEmail &&
                <Col span={20}>
                    <Row>{username}</Row>
                    <Row style={{ color: 'grey' }}>{email}</Row>
                </Col>}
            {!showEmail &&
                <Col style={{ color: 'black', marginTop: '10px', paddingLeft: '20px' }} span={20}>
                    {username}
                </Col>}
        </Row>
    )
}

export default UserInfo
