import React, { useEffect, useState } from 'react'
import { Button, Card, Checkbox, Divider, Typography } from 'antd'
import { useHistory } from 'react-router'
import './index.css'
import {
    DatabaseOutlined,
    IdcardOutlined,
    InfoCircleOutlined,
    UserOutlined
} from '@ant-design/icons'
import { DetectItemSummary } from '../../../../lib/api/ResponseTypes/Common'
import { humanize } from '../../Utils'

const { Text, Title } = Typography

export interface DetectSelectKPICardProps {
    itemSummary: DetectItemSummary
    ignoreClick?: boolean
    showItemStatus?: boolean
    hideUpdatedAt?: boolean
    onClickCallback?: Function
    isSelected: boolean
    onKPISelect: (kpiId: string) => void
    onKPIDeselect: (kpiId: string) => void
    leaderKPI: boolean,
    onSelectLeaderKPI: (leaderKPI: string) => void
}

export const SelectKPICard = ({
                                  itemSummary,
                                  showItemStatus,
                                  isSelected,
                                  onKPISelect,
                                  onKPIDeselect,
                                  leaderKPI,
                                  onSelectLeaderKPI

                              }: DetectSelectKPICardProps) => {
    const history = useHistory()
    const [isLeader, setIsLeader] = useState<boolean>(leaderKPI)
    useEffect(() => {
        setIsLeader(leaderKPI)
    }, [leaderKPI])

    return (
        <Card hoverable size='small' className='detect-item-card'>
            <div className='detect-item-card__body'>
                <div className='detect-item-card__title'>
                    <Title level={4} style={{ margin: 0 }}>
                        {itemSummary.itemName}
                    </Title>
                    <Text type='secondary'>
                        Problem Area: {itemSummary.problemArea}
                    </Text>
                </div>

                <div className='detect-item-card__summary'>
                    {!isSelected && (
                        <Button
                            style={{ width: '100%' }}
                            className='detect-button--primary'
                            onClick={() =>
                                onKPISelect(itemSummary.itemExternalId)
                            }
                        >
                            Select
                        </Button>
                    )}
                    {isSelected && (
                        <Button
                            style={{ width: '100%' }}
                            className='detect-button--selected'
                            onClick={() =>
                                onKPIDeselect(itemSummary.itemExternalId)
                            }
                        >
                            Selected ✔
                        </Button>
                    )}
                    <Checkbox checked={isLeader} onChange={(e) => {
                        if (e.target.checked) {
                            onSelectLeaderKPI(itemSummary.itemExternalId)
                        }
                        setIsLeader(e.target.checked)
                    }}>Make Leader</Checkbox>
                </div>
            </div>
            <Divider style={{ margin: '0px 0px 5px 0px' }} />
            <div className='detect-item-card__footer'>
				<span>
					<span className='detect-card-footer__key'>
						<IdcardOutlined /> ID:
					</span>
					<span className='detect-card-footer__value'>
						{' '}
                        {itemSummary.itemExternalId}
					</span>
				</span>
                <span>
					<span className='detect-card-footer__key'>
						<UserOutlined /> Created By:
					</span>
					<span className='detect-card-footer__value'>
						{' '}
                        {itemSummary.createdBy}
					</span>
				</span>
                <span>
					<span className='detect-card-footer__key'>
						<DatabaseOutlined /> Data Source:
					</span>
					<span className='detect-card-footer__value'>
						{' '}
                        {humanize(itemSummary.dataSourceType)}
					</span>
				</span>
                {showItemStatus && (
                    <span>
						<span className='detect-card-footer__key'>
							<InfoCircleOutlined /> Item Status:
						</span>
						<span className='detect-card-footer__value'>
							{' '}
                            {itemSummary.itemStatus}
						</span>
					</span>
                )}
            </div>
        </Card>
    )
}
