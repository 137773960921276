import { useQuery } from 'react-query'
import React, { useEffect, useState } from 'react'
import { getUseQueryOptions } from '../../Utils'
import {
    Breadcrumb
} from 'antd'
import { useHistory } from 'react-router'
import './index.css'
import { ItemStatus, MLP_DETECT_ADMIN } from '../../../../lib/utils/common'
import {
    getDetectUserGroupItemsCount
} from '../../../../lib/api/QueryApis/Common'
import { ItemType, OrderBy, OrderType } from '../../../../lib/api/RequestTypes/Common'
import useScopes from '../../../../hooks/useScopes'
import { Tabs } from 'antd'
import CollectionsDashboard from '../CollectionsDashboard'
import DetectCollectionsAdminDashboard from '../CollectionsAdminDashboard'

const CollectionHome = () => {
    const [itemType, setItemType] = useState<ItemStatus>(ItemStatus.APPROVED)
    const [showCreate, setShowCreate] = useState(false)
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)
    const [total, setTotal] = useState(10)
    const [filterText, setFilterText] = useState('')
    const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.createdAt)
    const [orderType, setOrderType] = useState<OrderType>(OrderType.DESC)
    const [showPublic, setShowPublic] = useState<boolean>(false)
    const [adminView, showAdminView] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const history = useHistory()
    const userPermissions = useScopes()
    const { TabPane } = Tabs

    useEffect(() => {
        setRefresh(!refresh)
    }, [])

    const itemCountQuery = useQuery(
        [
            {
                configStatus: itemType,
                filterText: filterText,
                orderBy: orderBy,
                orderType: orderType,
                showPublic: showPublic,
                groupType: ItemType.COLLECTION
            }
        ],
        getDetectUserGroupItemsCount,
        getUseQueryOptions(1)
    )
    useEffect(() => {
        if (itemCountQuery.isSuccess) setTotal(itemCountQuery.data.count)
    }, [itemCountQuery])

    return (
        <div style={{ width: '99%' }}>
            <Tabs defaultActiveKey='1'>
                <TabPane
                    tab='Collections'
                    key='1'
                >
                    <CollectionsDashboard />
                </TabPane>
                {userPermissions.data!=null &&
                    userPermissions.data.includes(MLP_DETECT_ADMIN) && (
                        <TabPane
                            tab='Pending Requests'
                            key='2'
                        >
                            <DetectCollectionsAdminDashboard />
                        </TabPane>
                    )}
            </Tabs>
        </div>
    )
}

export default CollectionHome
