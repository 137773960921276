
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import NoMatch from '../components/Common/NoMatch'
import { Center } from '../components/Common/Center'
import Loading from '../components/Common/Loading'

import { Activity } from 'react-feather'

import UnauthorizedAccess from '../components/Common/UnauthorizedAccess'

import KPIPage from "../components/Detect/KPI/KPIPage"
import KPIHome from '../components/Detect/KPI/KPIHome'
import Homepage from '../components/Homepage'
import CollectionHome from '../components/Detect/Collections/CollectionHome'
import CollectionPage from '../components/Detect/Collections/CollectionPage'
import ProjectHome from '../components/Detect/Projects/ProjectHome'
import ProjectPage from '../components/Detect/Projects/ProjectPage'

const Login = React.lazy(() => import('../components/Login'))

export interface RootAction {
  title: string
  path: string
  component: JSX.Element
  isExactPath?: boolean
}

export interface ActionWithSubRoute {
  title: string
  description: string
  path: string
  component: JSX.Element
  icon?: any
  iconLarge?: any
  selector: string
  subRoutes?: RootAction[]
  isExactPath?: boolean
  isFeature?: boolean
  isBeta?: boolean
  isComingSoon?: boolean
}

export const observabilityProductGroup: ActionWithSubRoute[] = [
  {
    title: 'KPIs',
    path: 'kpi',
    component: <KPIHome />,
    icon: <Activity />,
    iconLarge: <Activity style={{ color: '#00d0c8' }} />,
    selector: 'mlp_detect_read',
    isFeature: true,
    isBeta: true,
    isExactPath: true,
    description: 'ML based Anomaly Detection & KPI Monitoring',
    subRoutes: [
      {
        title: 'Item Dashboard',
        path: ':itemExtId',
        component: <KPIPage />,
        isExactPath: true,
      }
    ]
  },
  {
    title: 'Collections',
    path: 'collection',
    component: <CollectionHome />,
    icon: <Activity />,
    iconLarge: <Activity style={{ color: '#00d0c8' }} />,
    selector: 'mlp_detect_read',
    isFeature: true,
    isBeta: true,
    isExactPath: true,
    description: 'Flow creation',
    subRoutes: [
      {
        title: 'Collection Dashboard',
        path: ':itemExtId',
        component: <CollectionPage />,
        isExactPath: true,
      }
    ]
  },
  {
    title: 'Projects',
    path: 'project',
    component: <ProjectHome />,
    icon: <Activity />,
    iconLarge: <Activity style={{ color: '#00d0c8' }} />,
    selector: 'mlp_detect_read',
    isFeature: true,
    isBeta: true,
    isExactPath: true,
    description: 'Flow creation',
    subRoutes: [
      {
        title: 'Project Dashboard',
        path: ':itemExtId',
        component: <ProjectPage />,
        isExactPath: true,
      }
    ]
  }
]

export default function Routes({
  data,
  status,
}: {
  data: Array<string> | undefined
  status: string
}) {
  const routes =
    status === 'loading'
      ? []
      : observabilityProductGroup
        .map((observabilityAction) => (
          <PrivateRoute
            exact={observabilityAction.isExactPath}
            path={`/${observabilityAction.path}`}
            key={observabilityAction.path}
          >
            {(data as Array<string>).includes(observabilityAction.selector) ?
              observabilityAction.component : <UnauthorizedAccess />}
          </PrivateRoute>
        ))

  const subRoutes: JSX.Element[] = []

  if (status === 'success') {
    observabilityProductGroup
      .filter((action: ActionWithSubRoute) => !!action.subRoutes)
      .forEach((action: ActionWithSubRoute) => {
        (action.subRoutes as RootAction[]).forEach(
          (subRoute: RootAction, index: number) => {
            return subRoutes.push(
              <PrivateRoute
                exact={!!subRoute.isExactPath}
                path={`/${action.path}/${subRoute.path}`}
                key={index}
              >
                {(data as Array<string>).includes(action.selector) ? subRoute.component : <UnauthorizedAccess />}
              </PrivateRoute>
            )
          }
        )
      })
  }


  return (
    <Switch>
      <PrivateRoute exact path="/">
        <Homepage />
      </PrivateRoute>
      <Route path="/home">
        <Redirect to="/kpi" />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      {routes}
      {subRoutes}
      {status === 'loading' && (
        <Route path="*">
          <Center>
            <Loading />
          </Center>
        </Route>
      )}
      {status === 'success' && (
        <Route path="*">
          <NoMatch />
        </Route>
      )}
    </Switch>
  )
}
