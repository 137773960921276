import React from 'react'
import AntNavbar from '../AntNavbar'
import AntSidebar from '../AntSidebar'
import 'antd/dist/antd.css';

import './index.css'
import Routes from '../Routes';
import { useQuery } from 'react-query';
import { getUserInfo, getUserScopes } from '../../lib/api/QueryApis/Common';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd'



function AntLayout() {

    const location = useLocation()

    const {Content} = Layout

    const { data, status } = useQuery(['scopes', {}], getUserScopes, {
        refetchOnWindowFocus: false,
        cacheTime: Infinity,
        enabled: location.pathname !== '/login',
    })

    useQuery(['userInfo', {}], getUserInfo, {
        refetchOnWindowFocus: false,
        cacheTime: Infinity,
        enabled: status === 'success',
    })

    const [isSidebarOpen, setIsSidebarOpen] = React.useState(true)
    const isLocationHome = location.pathname.includes('home')
    const isLocationLogin = location.pathname.includes('login')
    const isLocationMainScreen = location.pathname === '/'


    if (isLocationLogin) {
        return <Routes data={data || []} status={status} />
    }

    return (
        <Layout >
            <AntNavbar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
            <Content style={{paddingLeft: '10px'}}>
                <div className={clsx({
                    'render-container': true,
                    'render-container--home': isLocationHome,
                    'render-container--main': isLocationMainScreen,
                })}>
                    <Routes data={data || []} status={status} />
                </div>
            </Content>
            {/*<Layout>*/}
            {/*    /!*<AntSidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />*!/*/}

            {/*</Layout>*/}
        </Layout>
    )
}

export default AntLayout
