import { Avatar, Spin, Layout, Row, Col, Divider, Dropdown, Menu, Tag, Button, MenuProps } from 'antd'
import React from 'react'
import { useCookies } from 'react-cookie'
import { useQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import { Cookie } from '../../lib/api/constants'
import { getUserInfo, getUserProfile } from '../../lib/api/QueryApis/Common'
import {DownOutlined, LogoutOutlined} from '@ant-design/icons'
import {Image} from 'antd'
import './index.css'
import { FcFolder, FcLineChart, FcWorkflow } from 'react-icons/all'
import { googleLogout } from '@react-oauth/google';

interface IAntNavbarProps {
	isSidebarOpen: boolean
	setIsSidebarOpen: (isSidebarOpen: boolean) => void
}


const AntNavbar: React.FC<IAntNavbarProps> = ({
	isSidebarOpen,
	setIsSidebarOpen,
}) => {
	const { Header } = Layout

	const [userName, setUserName] = React.useState('')
	const [iconUrl, setIconUrl] = React.useState('')
	const [userType, setUserType] = React.useState('')
	const [isLogoutBarOpen, setisLogoutBarOpen] = React.useState(false)

	const { data: userInfo } = useQuery(
		['userInfo', {}], getUserInfo, {
		refetchOnWindowFocus: false,
		cacheTime: Infinity,
	})

	const { data: userProfile, status, error } = useQuery(
		['userProfile', {}], getUserProfile, {
		refetchOnWindowFocus: false,
		cacheTime: Infinity,
	})



	const [, , removeCookie] = useCookies([
		Cookie.id_token,
		Cookie.refresh_token,
	])

	const onSignOut = () => {
		googleLogout();
		removeCookie(Cookie.id_token, {
			path: '/',
		})
		window.location.href = '/login'
	}
	if (status==='error') {
		onSignOut()
	}

	const history = useHistory()

	React.useEffect(() => {
		if (userProfile!==undefined) {
			setUserName(userProfile.fullName || '')
			setIconUrl(userProfile.iconUrl || '')
		}

		setUserType(userInfo?.[0].user_type || '')



	}, [userProfile, userInfo?.[0].user_type])

	const menus = (
		<Menu>
			<Menu.Item>
				<div style={{fontWeight: 'bolder'}}>
					{userName}
				</div>
			</Menu.Item>
			<Menu.Item>
				<Tag color="geekblue">{userType}</Tag>
			</Menu.Item>
			<Menu.Item>
					<div onClick={onSignOut} >
						<LogoutOutlined /> Logout
					</div>
			</Menu.Item>
		</Menu>
	)


	return (
		<Header style={{ position: 'sticky', top: 0, zIndex: 1,
			backgroundColor: '#ffffff', paddingRight: '15px',
			paddingLeft: '1px'}}>
			<Row
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					// alignItems: 'center',
					// backgroundColor: 'red',
					maxHeight: '65px'
				}}
			>
				<Row >
					<Row style={{alignItems: 'center'}}
						 onClick={() => {
							 history.push('/')
						 }}
					>
						<Image style={{marginTop: '0px'}}
							   src={"images/udaan-log.svg"}/>
						<Divider type="vertical"/>
						<Col >
							<Row className="navbar-main-product-name">
								Netra Platform
							</Row>
							<Row className="navbar-main-company-name">
								Assisted Intelligence
							</Row>
						</Col>
					</Row>
					{/*<Row style={{marginLeft: '20px'}}>*/}
						<Menu className="navbar-menu-item"
							  style={{paddingLeft: '10px', marginLeft: '20px'}}>
							<Link to={'/kpi'}>
								<FcLineChart style={{marginRight: '5px'}} />
								KPIs

							</Link>
						</Menu>
						<Menu className="navbar-menu-item"
							  style={{paddingLeft: '10px'}}>
							<Link to={'/collection'} style={{minHeight: '100%'}}>
								<FcWorkflow
									style={{marginRight: '5px'}} /> Collections
							</Link>
						</Menu>
						<Menu className="navbar-menu-item"
							  style={{paddingLeft: '10px'}}>
							<Link to={'/project'} style={{minHeight: '100%'}}>
								<FcFolder
									style={{marginRight: '5px'}}  /> Projects
							</Link>
						</Menu>
					{/*</Row>*/}
				</Row>


				<Row className="navbar-user">
					{userType ? (<Dropdown overlay={menus}>
						<a onClick={
							(e) => e.preventDefault()
						}>
							<Row style={{ display: 'flex', alignItems: 'center' }}>
								{iconUrl !== '' ? (
									<Avatar style={{ marginRight: '5px' }}
											src={iconUrl} />
								) : <Avatar
									style={{
										color: '#FF4D4F',
										backgroundColor: '#FFCCC7',
										marginRight: '5px',
									}}
								>
									{userName ? userName[0].toUpperCase() : ''}
								</Avatar>}
								{/*<div style={{marginRight: '2px'}}>{userName}</div>*/}
								<DownOutlined />
							</Row>
						</a>
					</Dropdown>) : (
						<Spin size="small"></Spin>
					)}

				</Row>
			</Row>
		</Header>
	)
}

export default AntNavbar
