import {
    ItemAccessLevel,
    ItemScope
} from '../../../types/DataPlatformTypes/CommonTypes'
import { ItemStatus } from '../../utils/common'

export type NullableFormData = FormData | null

export interface EmptyRequestParams {
}

// DP Request Types
export interface ListUsersLikeRequestParams {
    email: string
}

export interface ListTagsLikeRequestParams {
    name: string
}

// ML Platform Request Types
export interface GenericItemRequestParams {
    item_external_id: string
}

export interface DataSourceTypeRequestParams {
    dataSourceType: DataSourceType
}

export interface DataSourceQueryRequestParams {
    dataSourceType: DataSourceType,
    queryId: number
}

export interface DataSourceAlertRequestParams {
    dataSourceType: DataSourceType,
    queryType?: string
}

export interface ResourceDependenciesRequestParams {
    cluster: string,
    namespace: string,
    serviceName: string,
    resourceName: string
}

export enum SecretType {
    SECRET = 'SECRET',
    VOLUME = 'VOLUME',
}

export interface AccessLevelPerUser {
    access_level: ItemAccessLevel
    user_email: string
}


export interface ItemsIncremental {
    limit: number
    offset: number
    filter_text: string
    order_by: string
    order_type: string
}

export interface AutoCorrectSearchParams {
    query: string
}

export interface JobRunLogRequestParams {
    data_item_external_id: string
    topN: number
    job_type: 'data_item_run'
}

export enum TaskType {
    SCHEDULE = 'SCHEDULE',
    EVENT_HOOK = 'EVENT_HOOK',
}

export enum TaskTriggerMode {
    POST_INGEST = 'POST_INGEST',
    ON_INGEST = 'ON_INGEST',
}

export enum ComputeType {
    DATABRICKS = 'DATABRICKS',
    KUBERNETES = 'KUBERNETES',
    NATIVE = 'NATIVE'
}

export enum SeverityLevel {
    CRITICAL = 'CRITICAL',
    WARNING = 'WARNING',
    INFO = 'INFO',
    OK = 'OK'
}

export interface DetectItem {
    itemExternalId: string
    configId: string
    itemName: string
    problemAreaName: string
    itemScope: ItemScope
    algorithms: DetectAlgorithmInput[]
    dataSourceId: string
    dataSourceItemExtId: string | null
    dataSourceItemPath: string | null
    categoricalDimensionColumns: string[] | null
    continuousDimensionColumns: string[] | null
    measureColumn: string | null
    mainDimensionColumn: string | null
    timestampColumn: string | null
    granularity: number | null
    timePeriod: number | null
    ignoreLatest: number | null
    taskType: TaskType | null
    taskConfig: any | null
    taskTriggerMode: TaskTriggerMode | null
    triggerEndpoint: string | null,
    computeType: ComputeType | null
    configParameters: any | null
    dataSourceType: DataSourceType | null
    aggregate: Boolean | null
    aggregationRule: string | null,
    fillMissingDates: boolean | null
    prometheusMetricTypeId: number | null,
    service_name: string | null,
    resource_name: string | null,
    namespace: string | null,
    cluster: string | null
    data_granularity: string | null
    mainDimensionExceptions: string[] | null
}

export interface UpdateDetectItem {
    itemExtId: string
    item: DetectItem
}

export interface UpdateDetectGroupItem {
    itemExtId: string,
    item: DetectGroupItem
}

export enum DataSourceType {
    DATA_PLATFORM = 'DATA_PLATFORM',
    PROMETHEUS = 'PROMETHEUS'
}

export interface AddDataSourceRequest {
    dataSourceType: DataSourceType
    dataSourceParameters: any
}

export interface GetDataSourceRequest {
    dataSourceId: string
}

export interface RequestByItemExtId {
    itemExtId: string
}

export interface DetectGetItemRequest {
    itemExtId: string
    configId: string | null
}

export interface DetectGetMultipleItemsRequest {
    itemExtIds: string[]
}

export interface DetectGetMultipleGroupItemsRequest {
    itemExtIds: string[],
    groupType: ItemType
}

export interface DetectGetGroupItemRequest {
    itemExtId: string
    groupVersionId: string | null
}

export enum OrderBy {
    itemId = 'itemExternalId',
    itemName = 'itemName',
    problemArea = 'problemArea',
    createdAt = 'createdAt',
    createdBy = 'createdBy',
}

export enum OrderType {
    DESC = 'DESC',
    ASC = 'ASC',
}

export enum AnomalyMode {
    UPPER = 'UPPER',
    LOWER = 'LOWER',
    UPPER_AND_LOWER = 'UPPER_AND_LOWER'
}

export interface DetectGetUserGroupItemsRequest {
    configStatus: ItemStatus
    filterText: string
    orderBy: OrderBy
    orderType: OrderType
    limit: number
    offset: number
    showPublic: boolean
    groupType: ItemType
}

export interface DetectGroupItem {
    itemExternalId: string
    groupVersionId: string
    itemName: string
    itemScope: ItemScope
    groupType: ItemType
    graph: Map<string, DetectCollectionGraphNode>
    enclosingGroupIds: string[]
    description: string
    leaderKPI: string
}

export interface DetectCollectionGraphNode {
    parents: string[],
    children: string[]
}

export interface DetectGetUserItemsRequest {
    configStatus: ItemStatus
    filterText: string
    orderBy: OrderBy
    orderType: OrderType
    dataSourceType: string,
    limit: number
    offset: number
    showPublic: boolean
    showProblematic: boolean
}

export interface DetectGetUserItemsCountRequest {
    configStatus: ItemStatus
    filterText: String
    dataSourceType: string
    showProblematic: boolean
}

export interface DetectGetUserGroupItemsCountRequest {
    groupType: ItemType
    configStatus: ItemStatus
    filterText: String
}

export interface GrantItemAccessRequest {
    item_id: string
    item_external_id: string
    item_access_level: ItemAccessLevel
    user_email_list: string[]
}

export interface DeleteItemAccessRequest {
    item_id: string
    item_external_id: string
    access_per_user: {
        user_email: string
        access_level: ItemAccessLevel
    }
}

export interface DetectGetRequestedRequest {
    limit: number
    offset: number
}

export interface DetectExecutionLogsRequest {
    limit: number
    offset: number
    item_external_id: string
}

export interface MainChartsRequest {
    configId: string
}

export interface DetectGetRequestedGroupRequest {
    limit: number
    offset: number
    groupType: ItemType
}

export interface DetectModerateRequest {
    itemExternalId: string
    configId: string
    requestStatus: ItemStatus
}

export interface DetectModerateGroupRequest {
    itemExternalId: string
    groupVersionId: string
    requestStatus: ItemStatus
}

export interface DetectGetResultRequest {
    itemExtId: string
    anomalyId: string | null
}

export interface DetectGetResultFromConfigRequest {
    configId: string
    anomalyId: string | null
}

export interface DetectResultForecastRequest {
    filepath: string
    tsCol: string,
    measureCol: string
    granularity: number,
    configAlgorithmId: string
}

export interface DetectAlgorithmInput {
    algorithm: string,
    hyperParameters: any
    severity: SeverityLevel
    squadcastSlug: string
    sensitivity: number
    configAlgorithmId: string | null
    anomalyMode: AnomalyMode | null
}

export interface UdaanServiceRequest {
    cluster: string,
    namespace: string
}

export interface UdaanResourceRequest {
    cluster: string,
    namespace: string
    serviceName: string
}

export enum ItemType {
    DETECT = 'DETECT',
    COLLECTION = 'DETECT_COLLECTION',
    PROJECT = 'DETECT_PROJECT'
}

export interface AddKPI {
    kpiId: string,
    groupId: string,
    parents: string[]
    children: string[]
}

export interface ThanosQueryRequest {
    query: string,
    step: number
    range: number
    label:string
}
