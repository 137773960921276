
import * as React from "react";
import { LinearProgress, Typography } from "@material-ui/core";
import { Exception, HttpRequest } from "../../../../../lib/api/ResponseTypes/Common";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { getExceptionDetails } from '../../../../../lib/api/QueryApis/Common';
import { getUseQueryOptions } from "../../../Utils";
import FBox from "./FBox";
import BorderLinearProgress from "./BorderLinearProgress";
import ExceptionView from "./ExceptionView";
interface StackViewProps {
    req: HttpRequest;
    numChildren: number;
    isExpanded: boolean;
    onChangeExpanded: (isExpanded: boolean) => void;
    percent?: number;
    cluster: string,
    namespace: string,
    serviceName: string
}
const useStyles = makeStyles({
    stack: {
        cursor: 'pointer',
        background: "white",
        '&:hover': {
            background: "#EEE",
        }
    }
});

export const StackView: React.FunctionComponent<StackViewProps> = (
    {req, isExpanded, onChangeExpanded, numChildren, percent, cluster, namespace, serviceName}) => {
    const classes = useStyles();
    const [openExceptionView, setOpenExceptionView] = React.useState(false);
    const [exceptions, setExceptions] = React.useState<Exception[]>([]);
    const {host, method, path, statusCode, duration, properties, traceId} = req;
    const Path = path.length > 80 ? path.substr(0, 80) : path;
    const Duration = (duration * 1000).toFixed(2);
    const hasChildren = numChildren > 0;
    const handleClick = () => {
        if (numChildren <= 0) {
            return;
        }
        onChangeExpanded(!isExpanded);
    };

    const fetchExceptions = useQuery([{cluster, namespace, serviceName, traceId }], getExceptionDetails, getUseQueryOptions(1))

    React.useEffect(() => {
        if (fetchExceptions.isSuccess) {
            setExceptions(fetchExceptions.data)
        }
    }, [fetchExceptions])


    return (
        <FBox flex={1} flexDirection={"column"}>
            <FBox
                flexDirection={"row"}
                flex={1}
                px={2}
                alignItems={"center"}
                style={{border: '1px solid #ccc'}}
                className={hasChildren ? classes.stack : undefined}
            >
            {hasChildren && <DownOutlined title="Expand" color={'disabled'} onClick={handleClick}/>}

                <FBox flex={1} flexDirection={"row"} alignItems={"center"}>
                    <FBox flexDirection={"row"} flex={1} onClick={() => setOpenExceptionView(true)}>
                        <Typography style={{color:'#3f51b5'}}>{serviceName}</Typography>
                        {req.serviceName !== host && (
                            <Typography style={{color:'gray',marginLeft: 8}}>{host}</Typography>
                        )}
                        {properties.telemetry_type !== 'HTTP_REQUEST' && (
                            <Typography style={{color:'gray',marginLeft: 8}}>{properties.telemetry_type}</Typography>
                        )}
                        <Typography style={{marginLeft: 8, display: "flex", flex: 1}}>{method} {Path}</Typography>
                        <Typography style={{marginLeft: 8, color:'red'}}>{statusCode}</Typography>
                        <Typography style={{marginLeft: 8 }}>{Duration} ms</Typography>
                        <Typography style={{marginLeft: 8,color:'#3f51b5'}}>{numChildren > 0 ? `+${numChildren}` : 0}</Typography>
                    </FBox>
                    <Button 
                        onClick={() => setOpenExceptionView(true)} 
                        style={{margin: '2px 0 2px 10px', border:'none', fontSize:"small", borderRadius:'20px' }} 
                        type="primary"
                    >
                        View Exception
                    </Button>
                </FBox>
                <LinearProgress value={90} />
            </FBox>
            {(percent || 0) > 0 && (<BorderLinearProgress variant="determinate" value={percent} />)}
            {exceptions.length > 0 && openExceptionView && 
                <ExceptionView  exceptions={exceptions} onClose={() => setOpenExceptionView(false)} />
            }
        </FBox>
    );
};

export default StackView