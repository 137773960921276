import { Col, Row, Select, Typography } from 'antd';
import React from 'react'
import { RxOpenInNewWindow } from 'react-icons/rx';
import { ExceptionTraces } from '../../../../../lib/api/ResponseTypes/Common';

interface ShowTraceIDProps {
    selectedTraceId: string | undefined,
    traceIdListIsLoading: boolean,
    setSelectedTraceId: (value: React.SetStateAction<string | undefined>) => void,
    traceIds: ExceptionTraces[]
}
const ShowTraceID = ({selectedTraceId, traceIdListIsLoading, setSelectedTraceId, traceIds}: ShowTraceIDProps) => {

    const getTracerLink = ():string => `https://tools.udaan.dev/tracer/trace/${selectedTraceId}`

    return (
        <Row style={{padding: '10px'}}>
            <Col flex={2}>
                <span>
                    Trace IDs: 
                    <Select
                        showSearch
                        value={selectedTraceId}
                        onChange={(value) => setSelectedTraceId(value)}
                        loading={traceIdListIsLoading}
                        style={{
                            minWidth: '200px',
                            marginLeft: '10px'
                        }}
                        placeholder="Select a timestamp"
                        options={traceIds.map((it) => {
                            return { value: it.traceId, label: it.timestamp }
                        })}
                        dropdownMatchSelectWidth={false}
                    />
                </span>
            </Col >
            {selectedTraceId != undefined && <Col flex="auto">
                <Typography.Paragraph copyable> {selectedTraceId} 
                    <Typography.Link href={getTracerLink()} target="_blank">
                        <RxOpenInNewWindow/>
                    </Typography.Link>
                </Typography.Paragraph>
            </Col>}
        </Row>
    )
}

export default ShowTraceID