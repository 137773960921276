import React from 'react'
import AntLayout from './AntLayout'
import { GoogleOAuthProvider } from '@react-oauth/google';


function App() {
    return (
        <>
            <GoogleOAuthProvider
                clientId={`${process.env.REACT_APP_CLIENT_ID}`}>
                <AntLayout />
            </GoogleOAuthProvider>
        </>
  )
}

export default App
