import { Avatar, Divider, Empty, notification, Tag, Tooltip } from 'antd'
import { ItemAccessLevel } from '../../types/DataPlatformTypes/CommonTypes'
import React, { useEffect, useState } from 'react'
import { UserPermission } from '../../lib/api/ResponseTypes/Common'
import { useQuery } from 'react-query'
import {
    getGroupItemUserPermissions,
    getItemUserPermissions,
} from '../../lib/api/QueryApis/Common'
import { getUseQueryOptions } from '../Detect/Utils'
import UserInfo from './UserInfo'

interface ItemPermissionsProps {
    itemExtId: string
    isGroupItem: boolean
    permissions: UserPermission[]
}

const ItemPermissions = ({ permissions }: ItemPermissionsProps) => {
    let userListComponent = <div />
    if (permissions.length === 0) {
        userListComponent = (
            <div className="mlp-full-width-center">
                <Empty />
            </div>
        )
    } else {
        userListComponent = (
            <div>
                {permissions.map((value, index) => {
                    return (
                        <div key={index}>
                            <div className="mlp-user-permission">
                                <UserInfo
                                    email={value.email}
                                    avatar={value.avatar}
                                    username={value.username}
                                    showEmail={false}
                                />
                                <div>
                                    <Tag
                                        color="#2db7f5"
                                        style={{ marginTop: '10px' }}
                                    >
                                        {value.accessLevel}
                                    </Tag>
                                </div>
                            </div>
                            <Divider
                                style={{ margin: '0px', padding: '0px' }}
                            />
                        </div>
                    )
                })}
            </div>
        )
    }
    return (
        <div
            style={{
                backgroundColor: 'white',
                padding: '5px',
                height: 'fit-content',
                width: 'fit-content',
            }}
        >
            {userListComponent}
        </div>
    )
}

interface CustomAvatarGroupProps {
    permissions: UserPermission[] | null
    itemExternalId: string
    isGroupItem: boolean
}

export const CustomAvatarGroup = ({
    permissions,
    itemExternalId,
    isGroupItem,
}: CustomAvatarGroupProps) => {
    const [userPermissions, setUserPermissions] = useState(
        permissions == null ? [] : permissions
    )

    const permissionsQuery = useQuery(
        [{ itemExtId: itemExternalId }],
        isGroupItem ? getGroupItemUserPermissions : getItemUserPermissions,
        getUseQueryOptions(1, 60 * 1000, permissions == null)
    )

    useEffect(() => {
        if (permissionsQuery.isSuccess) {
            setUserPermissions(permissionsQuery.data)
        }
    }, [permissionsQuery])

    if (permissionsQuery.isSuccess && userPermissions.length === 0) {
        return <div />
    }
    return (
        <Tooltip
            color={'white'}
            title={
                <ItemPermissions
                    isGroupItem={isGroupItem}
                    itemExtId={itemExternalId}
                    permissions={userPermissions}
                />
            }
        >
            <Avatar
                style={{ marginRight: '5px' }}
                src={
                    userPermissions.filter((it) => {
                        return it.accessLevel === ItemAccessLevel.OWNER
                    })[0]?.avatar
                }
            />

            {userPermissions.length > 1 && (
                <Avatar style={{ marginLeft: '-12px' }}>
                    +{userPermissions.length - 1}
                </Avatar>
            )}
        </Tooltip>
    )
}
