import { HttpRequest, HttpRequestNode } from "../../../../../lib/api/ResponseTypes/Common";

const TIME_DIFF_MS = 5;

export const findRoot = (requests: HttpRequest[]): HttpRequest => {
    const rootByReqContainer = requests.find(it => !it.properties.req_container);
    if (rootByReqContainer) {
        return rootByReqContainer;
    }
    return requests.sort((a: HttpRequest, b: HttpRequest) => {
        if (a.timestamp < b.timestamp) return -1;
        return 1;
    })[0];
}
export const removeRequest = (request: HttpRequest, all: HttpRequest[]) => {
    const idx = all.indexOf(request);
    all.splice(idx, 1);
}
export const findChildren = (request: HttpRequest, all: HttpRequest[]) => {
    // Explicit relation
    if (request.properties.parent_id_for_children) {
        return all.filter(it => it.parentId === request.properties.parent_id_for_children);
    }
    // Implicit relation
    const {serviceName, pod, timestamp} = request;
    const parentTime = new Date(timestamp).getTime();
    return all.filter(it => {
        if (it.properties.req_container === serviceName && it.properties.req_pod === pod) {
            const childTime = new Date(it.timestamp).getTime();
            // Allowing 10ms diff in server times
            if (childTime > parentTime - TIME_DIFF_MS) {
                return true;
            }
        }
        return false;
    })
}

export const buildCallTree = (httpRequests: HttpRequest[]): HttpRequestNode[] => {
    if (!httpRequests || !httpRequests.length) {
        return [];
    }
    // Can we have multiple roots?
    const roots: HttpRequestNode[] = [];
    while (httpRequests.length) {

        // Get root from existing set of requests
        const request = findRoot(httpRequests);
        removeRequest(request, httpRequests);
        const root: HttpRequestNode = {request, children: []};
        roots.push(root);

        // Run BFS
        const queue: HttpRequestNode[] = [root];
        while (queue.length) {
            const node = queue.shift()!;
            const children = findChildren(node.request, httpRequests);
            children.forEach(c => {
                removeRequest(c, httpRequests);
                const newNode = {request: c, children: []};
                node.children.push(newNode);
                queue.push(newNode);
            });
        }
    }
    return roots;
}

interface TinyTrace {
    message: string;
    stackTrace: string;
}
const CAUSE_CAPTION = "Caused by: ";
export const extractStackTraceCauses = (stackTrace: string) => {
    let lines = stackTrace.split('\n');
    let ret: TinyTrace[] = [];
    let buffer: string[]|null = null;

    let drainBuffer = () => {
        if (buffer === null) return;
        if (buffer.length === 0) {
            buffer = null;
            return;
        }

        ret.push({
            message: buffer[0],
            stackTrace: buffer.join('\n'),
        });
        buffer = null;
    }

    lines.forEach(line => {
        if (line.startsWith(CAUSE_CAPTION)) {
            if (buffer !== null) {
                drainBuffer();
            }
            buffer = [line];
        } else {
            if (buffer !== null) {
                /** all subsequent lines to CAUSE_CAPTION would be prefixed with a \t
                any subsequent lines not starting with the same, would be starting
                with another CAUSE_CAPTION, ideally. in any case, drain buffer
                and move on.
                ref: java.lang.Throwable#printStackTrace(java.lang.Throwable.PrintStreamOrWriter)
                ref: java.lang.Throwable#printEnclosedStackTrace **/
                if (line.startsWith('\t')) {
                    buffer.push(line);
                } else {
                    drainBuffer();
                }
            }
        }
    })

    if (buffer !== null) {
        drainBuffer();
    }

    return ret;
}
