import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import React, { useMemo } from 'react'
import { getFormattedNumber } from '../../lib/utils/common'
import {
    DetectAlgorithm,
    ExecutionStatus
} from '../../lib/api/ResponseTypes/Common'
import { FormInstance, Tag } from 'antd'
import { DataSourceType } from '../../lib/api/RequestTypes/Common'

export const getPriorityCol = (metric: any) => {
    let keys = Object.keys(metric)
    if (keys.length===1) {
        return keys[0]
    }

    let mostPriority = 5
    let priorityCol = 'target'
    keys.forEach(it => {
        let priority = 10
        switch (it) {
            case 'pod':
                priority = 0
                break
            case 'service':
                priority = 1
                break
            case 'instance':
                priority = 2
                break
            case 'job':
                priority = 3
                break
            case 'namespace':
                priority = 4
                break
            case 'cluster':
                priority = 5
                break
            default:
                priority = 5
                break
        }
        if (priority <= mostPriority) {
            mostPriority = priority
            priorityCol = it
        }
    })

    return priorityCol
}

export const getSensitivityLevel = (
    algorithm: DetectAlgorithm,
    hyperparameters: FormInstance
) => {
    try {
        if (algorithm.sensitivityMapping===null) return 0
        let key = Object.keys(algorithm.sensitivityMapping['1'])[0]
        let val = hyperparameters.getFieldValue(key)
        let sensitivity = 10
        let dist = 1e5
        for (let i = 0; i <= 10; i++) {
            if (Math.abs(val - algorithm.sensitivityMapping[i][key]) < dist) {
                dist = Math.abs(val - algorithm.sensitivityMapping[i][key])
                sensitivity = i
            }
        }
        return sensitivity
    } catch (e) {
        return 5
    }
}

export const getUseQueryOptions = (
    retry: boolean | number,
    staleTime: number = 60 * 1000,
    enabled: boolean = true
) => {
    return {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: staleTime,
        retry: retry,
        enabled: enabled
    }
}

export const humanize = (str: string): string => {
    var i,
        frags = str.split('_')
    for (i = 0; i < frags.length; i++) {
        frags[i] =
            frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase()
    }
    return frags.join(' ')
}

export const humanizeCamelCase = (str: string): string => {
    const splitted = str.replace(/([a-z])([A-Z])/g, '$1 $2')
    return splitted.charAt(0).toUpperCase() + splitted.slice(1)
}

export const preventEnterToSubmit = (e: any) => {
    e.key==='Enter' && e.preventDefault()
}

export const getFormattedChange = (change: number) => {
    if (Math.abs(change) < 1) {
        if (change > 0) {
            return (
                <span style={{ color: '#4caf50' }}>
					<ArrowUpOutlined />{' '}
                    {getFormattedNumber(Math.abs(change * 100)) + '%'}
				</span>
            )
        } else {
            return (
                <span style={{ color: 'red' }}>
					<ArrowDownOutlined />{' '}
                    {getFormattedNumber(Math.abs(change * 100)) + '%'}
				</span>
            )
        }
    } else {
        if (change > 0) {
            return (
                <span style={{ color: '#4caf50' }}>
					<ArrowUpOutlined /> {getFormattedNumber(1 + change) + 'x'}
				</span>
            )
        } else {
            return (
                <span style={{ color: 'red' }}>
					<ArrowDownOutlined /> {getFormattedNumber(change - 1) + 'x'}
				</span>
            )
        }
    }
}

export const getFormattedDate = (dt: string | number, onlyDay = true, onlyTime: boolean = false) => {
    let date: Date
    if (typeof dt==='string') {
        date = new Date(dt)
    } else {
        date = new Date(dt * 1000)
    }
    const dtf = new Intl.DateTimeFormat('en', {
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'Asia/Kolkata',
        second: 'numeric'
    })
    const [
        { value: month },
        ,
        { value: day },
        ,
        { value: hour },
        ,
        { value: minute },
        ,
        { value: seconds }
    ] = dtf.formatToParts(date)
    if (onlyTime) {
        return `${hour}:${minute}:${seconds}`
    } else if (onlyDay) {
        return `${day} ${month}`
    } else {
        return `${hour}:${minute}, ${day} ${month}`
    }
}

export const numberFormatter = Intl.NumberFormat('en-IN', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'short'
})

export const granularityString = (granularity: number) => {
    if (granularity===60 * 60 * 24) return '1 Day'
    let days = Math.floor(granularity / (60 * 60 * 24))
    let hours = Math.floor((granularity % (60 * 60 * 24)) / (60 * 60))
    let minutes = Math.floor((granularity % (60 * 60)) / 60)
    return (
        (days!==0 ? `${days} Days `:'') +
        `${hours} Hours, ${minutes} Minutes`
    )
}

export const wrapString = (str: string, maxChars: number = 20) => {
    if (str===undefined) return ''
    if (str.length > maxChars) {
        str = str.substring(0, maxChars - 3) + '...'
    }
    return str
}

export const isFloat = (value: any) => {
    return (
        value!=null &&
        value!=='' &&
        value % 1!==0 &&
        !isNaN(Number(value.toString()))
    )
}

export const getRunStatusTag = (executionStatus: ExecutionStatus) => {
    switch (executionStatus) {
        case ExecutionStatus.RUN_SUBMIT_FAILED: {
            return <Tag color='red'>Failed</Tag>
        }
        case ExecutionStatus.RUN_FAILURE: {
            return <Tag color='red'>Failed</Tag>
        }
        case ExecutionStatus.RUN_TRIGGERED: {
            return <Tag color='yellow'>Running</Tag>
        }
        case ExecutionStatus.RUN_SUBMITTED: {
            return <Tag color='yellow'>Running</Tag>
        }
        case ExecutionStatus.RUN_STARTED: {
            return <Tag color='yellow'>Running</Tag>
        }
        case ExecutionStatus.RUN_SUCCESSFUL: {
            return <Tag color='green'>Success</Tag>
        }
    }
}

export const configParametersList = (dataSourceType: DataSourceType): string[] => {
    switch (dataSourceType) {
        case DataSourceType.DATA_PLATFORM:
            return [
                'mainDimensionColumn',
                'timestampColumn',
                'categoricalDimensionColumns',
                'continuousDimensionColumns',
                'measureColumn',
                'granularity',
                'timePeriod',
                'dataSourceItemPath',
                'dataSourceItemExtId',
                'ignoreLatest',
                'taskType',
                'taskConfig',
                'taskTriggerMode',
                'triggerEndpoint',
                'computeType',
                'aggregate',
                'aggregationRule',
                'fillMissingDates'
            ]
        default:
            return ['prometheusMetricTypeId', 'computeType', 'data_granularity']
    }
}
