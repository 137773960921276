import React, { useEffect, useState } from 'react'
import { Avatar, Col, Input, Row, Space, Table } from 'antd'
import { getFormattedChange, humanize, numberFormatter, preventEnterToSubmit } from '../Detect/Utils'
import { LastDataResponse } from '../../lib/api/ResponseTypes/Common'
import { ColumnsType } from 'antd/es/table'
import Tag from 'antd/es/tag'

const getNestedTable = (leaderData: LastDataResponse, mainDimensionValue: string) => {

    let data = leaderData.forecasts.sort((a, b) => {
        return (a['anomaly']!==null) ? -1:1
    }).filter(it => {
        return it['main_dimension_value']===mainDimensionValue && it['cat_col']!==null
    })


    let leaderDataColumns = [
        {
            title: humanize(data[0]['cat_col']),
            dataIndex: data[0]['cat_col'],
            key: data[0]['cat_col']
        },
        {
            title: humanize(leaderData.measureColumn),
            dataIndex: leaderData.measureColumn,
            width: '20%',
            key: leaderData.measureColumn,
            render: (value: any) => {
                return numberFormatter.format(value).replace('T', 'K')
            }
        },
        {
            title: '% Change',
            dataIndex: 'change',
            width: '30%',
            key: 'change',
            render: (value: any, record: any) => {
                if (record['anomaly']!==null) {
                    return <span style={{ color: 'red' }}> {getFormattedChange(value)}<Tag style={{ marginLeft: '5px' }}
                                                                                           color={'red'}>Anomaly</Tag></span>
                } else {
                    return <span>{getFormattedChange(value)}</span>
                }
            }
        }
    ]

    return (
        <Table
            size='small'
            pagination={false}
            columns={leaderDataColumns} dataSource={data.map((it, idx) => {
            let obj = it
            obj[data[0]['cat_col']] = obj['cat_col_value']
            obj['key'] = idx
            return obj
        })}
        />
    )
}


const LastDataTable = ({
                           itemName,
                           forecasts,
                           measureColumn,
                           timestampColumn,
                           mainDimensionColumn
                       }: LastDataResponse) => {

    let leaderDataColumns: ColumnsType<any> = [
        {
            title: humanize(mainDimensionColumn),
            dataIndex: mainDimensionColumn,
            key: mainDimensionColumn
        },
        {
            title: humanize(measureColumn),
            dataIndex: measureColumn,
            width:'20%',
            key: measureColumn,
            render: (value: any) => {
                return numberFormatter.format(value).replace('T', 'K')
            }
        },
        {
            title: '% Change',
            width:'30%',
            dataIndex: 'change',
            key: 'change',
            render: (value: any, record: any) => {
                if (record['anomaly']!==null) {
                    return <span style={{ color: 'red' }}> {getFormattedChange(value)}<Tag style={{ marginLeft: '5px' }}
                                                                                           color={'red'}>Anomaly</Tag></span>
                } else {
                    return <span>{getFormattedChange(value)}</span>
                }
            }
        }
    ]

    return (
        <Table
            size={'small'}
            style={{height:'100%'}}
            pagination={{ position: ['bottomCenter'] }}
            columns={leaderDataColumns}
            dataSource={forecasts.sort((a, b) => {
                return (a['anomaly']!==null) ? -1:1
            }).filter(it => {
                return it.cat_col==null
            }).map((it, idx) => {
                let obj = it
                obj[mainDimensionColumn] = it['main_dimension_value']
                obj['key'] = idx
                return obj
            })}
            expandable={{
                expandedRowRender: (record) => {
                    return getNestedTable({
                        itemName,
                        forecasts,
                        measureColumn,
                        timestampColumn,
                        mainDimensionColumn
                    }, record['main_dimension_value'])
                },
                rowExpandable: (record) => true
            }}
            scroll={{ y: 500 }}
        />)
}

export default LastDataTable
