import React, { useEffect, useState } from 'react'
import { ItemStatus } from '../../../lib/utils/common'
import {
    DetectItemSummary,
    PrometheusRule,
} from '../../../lib/api/ResponseTypes/Common'
import { Card, Divider, List, Row, Tag, Tooltip, Typography } from 'antd'
import { useHistory } from 'react-router'
import {
    DatabaseOutlined,
    IdcardOutlined,
    InfoCircleOutlined,
    StarFilled,
    WarningOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons'
import { getFormattedDate, humanize, numberFormatter } from '../Utils'
import {
    DataSourceType,
    SeverityLevel,
} from '../../../lib/api/RequestTypes/Common'
import '../index.css'
import KPIMinimalChart from './KPIPage/KPIMinimalChart'
import { CustomAvatarGroup } from '../../Common/CustomAvatarGroup'
import { useMutation } from 'react-query'
import { getKPIAlertStatus } from '../../../lib/api/QueryApis/Common'
import PromQLChart from '../../Common/PromQLChart'

const { Text } = Typography

export interface AlertTableProp {
    data: any
}

export const AlertTable = ({ data }: AlertTableProp) => {
    let keys = Object.keys(data)
    let date = data[keys[0]]['ds']

    return (
        <div style={{ color: 'black', backgroundColor: 'white' }}>
            Incident on <Text code>{getFormattedDate(date, false)}</Text>
            <List
                style={{
                    maxHeight: '200px',
                    marginTop: '5px',
                    overflow: 'scroll',
                }}
            >
                {keys.map((it) => {
                    return (
                        <List.Item key={it} title={it}>
                            <Text code>{it}</Text>
                            Expected:{' '}
                            <Text code>
                                {numberFormatter
                                    .format(data[it]['yhat'])
                                    .replace('T', 'K')}
                            </Text>
                            Actual:{' '}
                            <Text code>
                                {numberFormatter
                                    .format(data[it]['y'])
                                    .replace('T', 'K')}
                            </Text>
                        </List.Item>
                    )
                })}
            </List>
        </div>
    )
}

export interface NonFunctionalAlertTableProp {
    firingRules: PrometheusRule[]
}

export const NonFunctionalAlertTable = ({
    firingRules,
}: NonFunctionalAlertTableProp) => {
    const criticalAlerts = firingRules.filter(
        (rule) =>
            'critical' == (rule.labels['severity'] as string).toLowerCase()
    )
    const warningAlerts = firingRules.filter(
        (rule) => 'warning' == (rule.labels['severity'] as string).toLowerCase()
    )
    const infoAlerts = firingRules.filter(
        (rule) => 'info' == (rule.labels['severity'] as string).toLowerCase()
    )

    return (
        <div
            style={{
                color: 'black',
                backgroundColor: 'white',
                textAlign: 'center',
            }}
        >
            Critical: <Text code>{criticalAlerts.length}</Text> | Warning:{' '}
            <Text code>{warningAlerts.length}</Text> | Info:{' '}
            <Text code>{infoAlerts.length}</Text>
            <List
                style={{
                    maxHeight: '200px',
                    marginTop: '5px',
                    overflow: 'scroll',
                }}
            >
                {firingRules.map((rule, idx) => {
                    return (
                        <List.Item key={idx} title={rule.name}>
                            <Text code>{rule.name}</Text>
                        </List.Item>
                    )
                })}
            </List>
        </div>
    )
}

export interface DetectItemCardProps {
    itemSummary: DetectItemSummary
    ignoreClick?: boolean
    showItemStatus?: boolean
    onClickCallback?: Function
    mini?: boolean
    isLeader?: boolean
}

export const KPICard = ({
    itemSummary,
    ignoreClick,
    showItemStatus,
    onClickCallback,
    mini,
    isLeader,
}: DetectItemCardProps) => {
    const history = useHistory()
    const [incident, setIncident] = useState<any | null>(null)
    const [nonFunctionalRules, setNonFunctionalRules] = useState<
        PrometheusRule[]
    >([])

    let promChart = (
        <PromQLChart query={itemSummary.promQLQuery} step={null} range={null} />
    )

    const [alerts] = useMutation(getKPIAlertStatus, {
        onSuccess: (resp) => {
            setNonFunctionalRules(resp.rules)
            if (resp.rules.length > 0) {
                let criticalAlertCount = resp.rules.filter(
                    (rule) =>
                        'critical' ==
                        (rule.labels['severity'] as string).toLowerCase()
                ).length
                const warningAlertsCount = resp.rules.filter(
                    (rule) =>
                        'warning' ==
                        (rule.labels['severity'] as string).toLowerCase()
                ).length
                const infoAlertsCount = resp.rules.filter(
                    (rule) =>
                        'info' ==
                        (rule.labels['severity'] as string).toLowerCase()
                ).length
                const maxSeverity =
                    criticalAlertCount > 0
                        ? 'CRITICAL'
                        : warningAlertsCount > 0
                        ? 'WARNING'
                        : infoAlertsCount > 0
                        ? 'INFO'
                        : ''
                if (maxSeverity != '') {
                    setIncident({ level: maxSeverity, data: null })
                }
            }
        },
        onError: () => {
            console.log(
                'There has been an error while fetching active alerts for ' +
                    itemSummary.itemExternalId
            )
        },
    })

    useEffect(() => {
        if (
            itemSummary.incidents !== null &&
            itemSummary.incidents.data !== null
        ) {
            let mp = {
                CRITICAL: 3,
                WARNING: 2,
                INFO: 1,
                OK: 0,
            }

            let algorithm = Object.keys(itemSummary.incidents.data).sort(
                (a: string, b: string) => {
                    return mp[a.split('|')[1] as SeverityLevel] >
                        mp[b.split('|')[1] as SeverityLevel]
                        ? 1
                        : 0
                }
            )[0]

            setIncident({
                level: algorithm.split('|')[1],
                data: itemSummary.incidents.data[algorithm],
            })
        }
        if (itemSummary.dataSourceType == DataSourceType.PROMETHEUS) {
            alerts({
                itemExtId: itemSummary.itemExternalId,
                configId: itemSummary.configId,
            })
            setInterval(() => {
                alerts({
                    itemExtId: itemSummary.itemExternalId,
                    configId: itemSummary.configId,
                })
            }, 60000)
        }
    }, [itemSummary])

    const handleOnclick = () => {
        if (ignoreClick) return
        if (onClickCallback) {
            onClickCallback(
                itemSummary.itemExternalId,
                itemSummary.configId,
                itemSummary.itemStatus
            )
        } else {
            history.push({
                pathname: `/kpi/${itemSummary.itemExternalId}`,
                state: itemSummary,
            })
        }
    }

    let actions = [
        <Tag
            key={itemSummary.itemExternalId}
            color="blue"
            style={{ borderRadius: '10px', marginLeft: '5px' }}
            icon={<IdcardOutlined />}
        >
            {itemSummary.itemExternalId}
        </Tag>,
    ]
    if (itemSummary.dataSourceType === DataSourceType.DATA_PLATFORM) {
        actions.push(
            <Tag
                key={'dataSourceType'}
                color="blue"
                style={{ borderRadius: '10px', marginLeft: '5px' }}
                icon={<DatabaseOutlined />}
            >
                {humanize(itemSummary.dataSourceType.toString())}
            </Tag>
        )
    }
    if (itemSummary.dataSourceType === DataSourceType.PROMETHEUS) {
        actions.push(
            <Tag
                key={'dataSourceType'}
                color="red"
                style={{ borderRadius: '10px', marginLeft: '5px' }}
                icon={<DatabaseOutlined />}
            >
                {humanize(itemSummary.dataSourceType.toString())}
            </Tag>
        )
    }
    if (showItemStatus) {
        actions.push(
            <Tag
                key={'itemStatus'}
                color="blue"
                style={{ borderRadius: '10px', marginLeft: '5px' }}
                icon={<InfoCircleOutlined />}
            >
                {itemSummary.itemStatus === ItemStatus.APPROVED
                    ? 'Update'
                    : 'New'}
            </Tag>
        )
    }
    if (isLeader) {
        actions.push(
            <Tag
                key={'leader'}
                color="gold"
                style={{ borderRadius: '10px', marginLeft: '5px' }}
                icon={<StarFilled />}
            >
                Leader
            </Tag>
        )
    }

    let cardStyle: any
    if (mini) {
        cardStyle = {
            padding: '0px',
            minWidth: '200px',
        }
    } else {
        cardStyle = { margin: '0 0 10px 5px', height: '250px' }
    }

    return (
        <Card
            hoverable
            onClick={handleOnclick}
            style={cardStyle}
            size={mini ? 'small' : undefined}
            title={
                <Row
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    {mini && itemSummary.itemName.length > 18 && (
                        <Tooltip title={itemSummary.itemName}>
                            <div
                                style={{ maxWidth: '18ch', overflow: 'hidden' }}
                            >
                                {itemSummary.itemName}
                            </div>
                        </Tooltip>
                    )}
                    {(!mini || !(itemSummary.itemName.length > 18)) && (
                        <div>{itemSummary.itemName}</div>
                    )}
                    {incident !== null && (
                        <Tooltip
                            color={'white'}
                            overlayStyle={{ maxWidth: '500px' }}
                            title={
                                itemSummary.dataSourceType !=
                                DataSourceType.PROMETHEUS ? (
                                    <AlertTable data={incident.data} />
                                ) : (
                                    <NonFunctionalAlertTable
                                        firingRules={nonFunctionalRules}
                                    />
                                )
                            }
                        >
                            <Tag
                                style={{
                                    borderRadius: '10px',
                                    alignItems: 'center',
                                    display: 'flex',
                                }}
                                color={
                                    incident.level === SeverityLevel.CRITICAL
                                        ? 'red'
                                        : incident.level ===
                                          SeverityLevel.WARNING
                                        ? 'yellow'
                                        : 'blue'
                                }
                                icon={
                                    incident.level ===
                                    SeverityLevel.CRITICAL ? (
                                        <ExclamationCircleOutlined />
                                    ) : incident.level ===
                                      SeverityLevel.WARNING ? (
                                        <WarningOutlined />
                                    ) : (
                                        <InfoCircleOutlined />
                                    )
                                }
                            >
                                {!mini && 'Needs Attention: '}
                                {incident.level}
                            </Tag>
                        </Tooltip>
                    )}
                    <CustomAvatarGroup
                        permissions={itemSummary.permissions}
                        itemExternalId={itemSummary.itemExternalId}
                        isGroupItem={false}
                    />
                </Row>
            }
        >
            <div>
                {!mini && itemSummary.promQLQuery !== null && (
                    <div
                        style={{ height: '130px', maxWidth: '100%' }}
                        onClick={(event) => {
                            event.stopPropagation()
                        }}
                    >
                        {promChart}
                    </div>
                )}
                {!mini && itemSummary.result !== null && (
                    <div
                        onClick={(event) => {
                            event.stopPropagation()
                        }}
                    >
                        <KPIMinimalChart data={itemSummary.result} />
                    </div>
                )}
                {!mini && itemSummary.resultSummary == null && (
                    <div style={{ minHeight: '100px' }}></div>
                )}
                <Divider style={{ marginBottom: '5px' }} />
                <div>
                    {actions.map((it) => {
                        return it
                    })}
                </div>
            </div>
        </Card>
    )
}
