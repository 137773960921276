import React, { useState } from 'react'
import { HttpRequestNode } from "../../../../../lib/api/ResponseTypes/Common";
import FBox from './FBox';
import StackView from './StackView';

export const HttpRequestNodeView: React.FunctionComponent<{node: HttpRequestNode, percent?: number, exp?: boolean, showRootCause?: boolean, cluster: string, namespace: string, serviceName: string}> = 
({node, percent, exp, showRootCause, cluster, namespace, serviceName}) => {
    const [expanded, setExpanded] = useState(exp || false);
    const hasChildren = node.children.length > 0;
    const sortedChildren = [...node.children].sort((c1, c2) => c2.request.duration - c1.request.duration);
    const parentDur = node.request.duration;

    return (
        <FBox flex={1} flexDirection={"column"} mt={1}>
            {((node.request.statusCode == 500 && showRootCause) || !showRootCause) && (
            <StackView
                req={node.request}
                numChildren={node.children.length}
                isExpanded={expanded}
                percent={percent}
                onChangeExpanded={is => {
                    if (hasChildren) {
                        setExpanded(is)
                    }
                }}
                cluster={cluster}
                namespace={namespace}
                serviceName={serviceName}
            />)}
            {expanded && hasChildren && (
                <FBox flex={1} ml={2} flexDirection={"column"}>
                    {sortedChildren.filter(tree =>((tree.request.statusCode == 500 && showRootCause) || !showRootCause)).map((c, idx) => (
                        <FBox flex={1} flexDirection={"row"} alignItems={"flex-start"} key={Math.round(Math.random()*1000000)}>
                            <HttpRequestNodeView
                                cluster={cluster}
                                namespace={namespace}
                                serviceName={serviceName}
                                key={`c${idx}`}
                                percent={c.request.duration / parentDur * 100}
                                node={c}
                                showRootCause={showRootCause}
                            />
                        </FBox>
                    ))}
                </FBox>
            )}
        </FBox>
    )
};