import { Col, Row, Spin  } from 'antd';
import React from 'react'
import { HttpRequestNode  } from '../../../../../lib/api/ResponseTypes/Common';
import { HttpRequestNodeView } from './HttpRequestNodeView';
import { Empty } from 'antd';


interface ShowRequestTraceProps {
    selectedTraceId: string | undefined,
    requestTreeIsLoading: boolean,
    requestTree: HttpRequestNode[],
    cluster: string,
    namespace: string,
    serviceName: string
}
const ShowRequestTrace = ({selectedTraceId, requestTreeIsLoading, requestTree, cluster, namespace, serviceName}: ShowRequestTraceProps) => {
    return (
        <Row>
            <Col>
            {requestTreeIsLoading && selectedTraceId != undefined &&
                <div
                    style={{
                        height: '50vh',
                        width: '60vw',
                        justifyItems: 'center',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <Spin
                        tip="  Loading..."
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            verticalAlign: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    />
                </div>                                       
            }
            {!requestTreeIsLoading && selectedTraceId != undefined && requestTree.length == 0 &&
                <Empty description="No Http Requests found for the selected traceID"/>
            }
            {!requestTreeIsLoading && selectedTraceId != undefined && requestTree.length > 0 &&
                requestTree.map(tree => (
                    <HttpRequestNodeView 
                        key={Math.round(Math.random()*1000000)}
                        node={tree} 
                        exp={true} 
                        cluster={cluster}
                        namespace={namespace}
                        serviceName={serviceName}
                    />
                ))
            }
            </Col>
        </Row> 
    )
}

export default ShowRequestTrace