import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { getUseQueryOptions } from '../../Detect/Utils'
import {
    getGroupItemUserPermissions,
    getItemUserPermissions, listEmailsStartingWith
} from '../../../lib/api/QueryApis/Common'
import {
    AutoComplete,
    Button,
    Divider,
    Empty,
    Form,
    Input,
    notification,
    Select,
    Spin,
    Tag,
    Typography
} from 'antd'
import { CloseCircleFilled, DeleteOutlined, UserOutlined } from '@ant-design/icons'
import {
    deleteGroupItemAccess,
    deleteItemAccess,
    grantGroupItemAccess,
    grantItemAccess
} from '../../../lib/api/MutationApis/Common'
import { FormInstance } from 'antd/es'
import './index.css'
import { ValidateStatus } from 'antd/es/form/FormItem'
import { ItemAccessLevel } from '../../../types/DataPlatformTypes/CommonTypes'
import { sendNotification } from '../../../lib/utils/common'
import UserInfo from '../UserInfo'

const { Text } = Typography
const { Option } = Select

interface ItemPermissionsProps {
    itemExtId: string
    isGroupItem: boolean
}

const ItemPermissions = ({ itemExtId, isGroupItem }: ItemPermissionsProps) => {
    const [form] = Form.useForm()
    const [emailInput,setEmailInput] =  useState('');

    const [addStatus, setAddStatus] = useState<ValidateStatus>('')
    const formRef = React.createRef<FormInstance>()

    const permissionsQuery = useQuery(
        [{ itemExtId: itemExtId }],
        isGroupItem ? getGroupItemUserPermissions:getItemUserPermissions,
        getUseQueryOptions(1)
    )

    const searchQuery = useQuery(
        [{ email: emailInput, key:'email' }],
        listEmailsStartingWith,
        getUseQueryOptions(1, 60 * 1000, false)
    )

    useEffect(() => {
        searchQuery.refetch()
    }, [emailInput])

    const [api, contextHolder] = notification.useNotification()

    const [grantItemAccessRequest] = useMutation(
        isGroupItem ? grantGroupItemAccess:grantItemAccess,
        {
            onSuccess: (resp) => {
                setAddStatus('success')
                sendNotification(notification, 'success', 'User access added', '')
            },
            onError: (e) => {
                setAddStatus('error')

                sendNotification(notification, 'error', 'Failed to add user access', '')
            }
        }
    )
    const [deleteItemAccessRequest] = useMutation(
        isGroupItem ? deleteGroupItemAccess:deleteItemAccess,
        {
            onSuccess: (resp) => {
                sendNotification(notification, 'success', 'User access deleted', '')
            },
            onError: (e) => {
                sendNotification(
                    notification,
                    'error',
                    'Failed to delete user access',
                    ''
                )
            }
        }
    )

    const handleAdd = () => {
        if (!permissionsQuery.isSuccess) {
            sendNotification(notification, 'warning', 'Failed to fetch item users', '')
            return
        }

        setAddStatus('validating')

        grantItemAccessRequest({
            item_access_level: form.getFieldValue('accessLevel'),
            item_id: permissionsQuery.data[0].itemId,
            item_external_id: itemExtId,
            user_email_list: [form.getFieldValue('userEmail')]
        }).then(permissionsQuery.refetch)
    }

    const handleDelete = (userEmail: string, accessLevel: ItemAccessLevel) => {
        if (!permissionsQuery.isSuccess) {
            sendNotification(api, 'warning', 'Failed to fetch item users', '')
            return
        }
        deleteItemAccessRequest({
            item_external_id: itemExtId,
            item_id: permissionsQuery.data[0].itemId,
            access_per_user: {
                user_email: userEmail,
                access_level: accessLevel
            }
        }).then(permissionsQuery.refetch)
    }

    let userListComponent = <div />
    if (permissionsQuery.isLoading) {
        userListComponent = (
            <div className='mlp-full-width-center'>
                <Spin tip='Loading' />
            </div>
        )
    } else if (permissionsQuery.isError) {
        sendNotification(notification, 'warning', 'Failed to fetch item users', '')
        userListComponent = (
            <div className='mlp-full-width-center'>
                <Empty />
            </div>
        )
    } else if (permissionsQuery.isSuccess) {
        userListComponent = (
            <div>
                {permissionsQuery.data.map((value, index) => {
                    return (
                        <div className='mlp-user-permission'>
                            <UserInfo email={value.email} avatar={value.avatar} username={value.username}
                                      showEmail={true} />
                            <div>
                                <Tag color='#2db7f5'>
                                    {value.accessLevel}
                                </Tag>
                                <DeleteOutlined
                                    onClick={() =>
                                        handleDelete(
                                            value.email,
                                            value.accessLevel
                                        )
                                    }
                                />
                            </div>
                        </div>

                    )
                })}
            </div>
        )
    } else {
        return <div />
    }

    return (
        <>
            <div className='mlp-item-permission'>
                <div>
                    <Form
                        initialValues={{ accessLevel: ItemAccessLevel.READER }}
                        onFinish={handleAdd}
                        layout='inline'
                        ref={formRef}
                        form={form}
                    >
                        <Form.Item
                            name='userEmail'
                            rules={[
                                {
                                    required: true,
                                    message: 'User email is required!'
                                }
                            ]}
                            hasFeedback
                            validateStatus={addStatus}
                        >
                            <AutoComplete
                                dropdownStyle={{ minWidth: 'fit-content' }}
                                value={emailInput}
                                placeholder={'Email-Id'}
                                style={{minWidth:'200px'}}
                                options={(searchQuery.isSuccess && searchQuery.data!==null) ?
                                    searchQuery.data.map(it => {
                                        return {
                                            label: it.email,
                                            value: it.email
                                        }
                                    })
                                    :[]
                                }

                                onChange={(value, option) => {
                                    setEmailInput(value)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name='accessLevel'
                            rules={[{ required: true }]}
                        >
                            <Select style={{ minWidth: '120px', fontSize: '10pt' }} size={'small'}>
                                <Option value={ItemAccessLevel.READER} key={0}>
                                    Reader
                                </Option>
                                <Option
                                    value={ItemAccessLevel.CONTRIBUTOR}
                                    key={1}
                                >
                                    Contributor
                                </Option>
                                <Option value={ItemAccessLevel.OWNER} key={2}>
                                    Owner
                                </Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                className='mlp-button--primary'
                                style={{ minWidth: '60px' }}
                                htmlType='submit'
                                type={'primary'}
                            >
                                + Share
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <Divider />
                {userListComponent}
            </div>
        </>
    )
}

export default ItemPermissions
