import React, { useState } from 'react'
import { Button, Col, Descriptions, Empty, notification, Row, Spin } from 'antd'
import { useMutation, useQuery } from 'react-query'
import {
    getDetectGroupItem,
    getDetectItem
} from '../../../../lib/api/QueryApis/Common'
import { getUseQueryOptions } from '../../Utils'
import { useHistory } from 'react-router'
import {
    moderateConfig,
    moderateGroupConfig,
    moderateGroupItem,
    moderateItem
} from '../../../../lib/api/MutationApis/Common'
import { ItemStatus, sendNotification } from '../../../../lib/utils/common'
import KPIConfig, { ItemMode } from '../../KPI/KPIConfig'
import DetectCollectionConfig from '../CollectionConfig'

interface DetectCollectionItemSummaryProps {
    itemExtId: string
    groupVersionId: string
    itemStatus: ItemStatus
    callback: Function
}

const CollectionItemSummary = (
    {
        itemExtId,
        groupVersionId,
        itemStatus,
        callback
    }: DetectCollectionItemSummaryProps) => {
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [showFullConfig, setShowFullConfig] = useState(false)
    const collectionConfigQuery = useQuery(
        [
            {
                itemExtId: itemExtId,
                groupVersionId: groupVersionId
            }
        ],
        getDetectGroupItem,
        getUseQueryOptions(1)
    )

    const [moderateItemQuery] = useMutation(moderateGroupItem, {
        onSuccess: (data) => {
            setIsLoading(false)
            sendNotification(notification, 'success', `Item ${itemExtId} moderated`, '')
            callback()
        },
        onError: (e) => {
            setIsLoading(false)
            sendNotification(
                notification,
                'error',
                `Failed to moderate ${itemExtId}`,
                ''
            )
        }
    })

    const [moderateConfigQuery] = useMutation(moderateGroupConfig, {
        onSuccess: (data) => {
            setIsLoading(false)
            sendNotification(notification, 'success', `Item ${itemExtId} moderated`, '')
            callback()
        },
        onError: (e) => {
            setIsLoading(false)
            sendNotification(
                notification,
                'error',
                `Failed to moderate ${itemExtId}`,
                ''
            )
        }
    })

    let configSummary = <div></div>
    if (collectionConfigQuery.isLoading || isLoading) {
        configSummary = (
            <div className='detect-full-center'>
                <Spin />
            </div>
        )
    } else if (collectionConfigQuery.isSuccess) {
        if (showFullConfig) {
            configSummary = (
                <DetectCollectionConfig
                    itemConfig={collectionConfigQuery.data}
                    itemExtId={collectionConfigQuery.data.itemExternalId}
                    mode={ItemMode.View}
                />
            )
        } else {
            configSummary = (
                <Descriptions bordered>
                    <Descriptions.Item label='Name'>
                        {collectionConfigQuery.data.itemName}
                    </Descriptions.Item>
                    <Descriptions.Item label='Scope'>
                        {collectionConfigQuery.data.itemScope}
                    </Descriptions.Item>
                    <Descriptions.Item label='Description'>
                        {collectionConfigQuery.data.description}
                    </Descriptions.Item>
                </Descriptions>
            )
        }
    } else if (collectionConfigQuery.isError) {
        configSummary = (
            <div className='detect-full-center'>
                <Empty />
            </div>
        )
    }

    const handleModerate = (modelStatus: ItemStatus) => {
        setIsLoading(true)
        if (itemStatus===ItemStatus.APPROVED) {
            moderateConfigQuery({
                itemExternalId: itemExtId,
                groupVersionId: groupVersionId,
                requestStatus: modelStatus
            })
        } else {
            moderateItemQuery({
                itemExternalId: itemExtId,
                groupVersionId: groupVersionId,
                requestStatus: modelStatus
            })
        }
    }

    return (
        <div style={{ padding: '10px', minWidth: '30vw', maxWidth: '55vw' }}>
            {configSummary}
            <Row gutter={8} style={{ paddingTop: '5px' }}>
                <Col span={8}>
                    <Button
                        className='detect-button--primary--full'
                        style={{ width: '100%' }}
                        onClick={() => setShowFullConfig(!showFullConfig)}
                    >
                        {showFullConfig
                            ? 'Hide Full Config'
                            :'Show Full Config'}
                    </Button>
                </Col>
                <Col span={8}>
                    <Button
                        onClick={() => handleModerate(ItemStatus.APPROVED)}
                        className='detect-button--primary--full'
                    >
                        Approve
                    </Button>
                </Col>
                <Col span={8}>
                    <Button
                        className='detect-button--danger--full'
                        onClick={() => handleModerate(ItemStatus.REJECTED)}
                        danger
                    >
                        Reject
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default CollectionItemSummary
