import React from 'react'
import {
    Area,
    Brush,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts'
import { numberFormatter } from '../../Utils'
import { DetectAlgorithmInput } from '../../../../lib/api/RequestTypes/Common'

export interface DetectChartProps {
    data: any,
    tsCol: string,
    measureCol: string,
    showForecast: boolean,
    onClick?: Function,
    showOnlyFocused?: boolean,
    focusedAnomalyIdx?: number,
    algorithms: DetectAlgorithmInput[]
}

const KPIChart = (props: DetectChartProps) => {
    const tooltipFormatter = (value: any, name: string) => {
        if (name===props.measureCol || name==='forecast') return numberFormatter.format(value).replace('T', 'K')
        if (name==='ci') return [[
            numberFormatter.format(value[0]).replace('T', 'K') + ' - ',
            numberFormatter.format(value[1]).replace('T', 'K')], 'CI Interval']
        if (name==='anomaly') return [null, null]
        else return value
    }

    return (
        <ResponsiveContainer width='100%' height='100%'>
            <ComposedChart
                data={props.data}
                onClick={(state) => {
                    if (props.onClick) {
                        props.onClick(state)
                    }
                }}>
                <Scatter shape='circle' dataKey='anomaly' fill='#f44336aa' />
                <Line dot={false} type='linear' dataKey={props.measureCol} stroke='blue' activeDot={{ r: 8 }} />
                {'ci' in props.data[0] && <Area dataKey='ci' stroke='lightblue' fill='lightblue' opacity={0.4} />}

                {props.showForecast && (
                    <Line dot={false}
                          type='linear'
                          dataKey='forecast'
                          stroke='#3881ff'
                          activeDot={{ r: 8 }}
                          strokeDasharray='3 3' />
                )}

                <CartesianGrid strokeDasharray='3 3' vertical={false} />

                <XAxis padding={{ left: 25 }} dataKey={props.tsCol} />
                <YAxis domain={['auto', 'auto']}
                       tickFormatter={(value) => numberFormatter.format(value).replace('T', 'K')} />

                <Brush
                    stroke='#4287f5'
                    height={15}
                    width={200}
                    tickFormatter={(value, index) => {
                        return props.data[index][props.tsCol]
                    }} />
                <Tooltip formatter={tooltipFormatter} />
                <Legend />
            </ComposedChart>
        </ResponsiveContainer>
    )
}
export default KPIChart
