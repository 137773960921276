import { useQuery } from 'react-query'
import React, { useEffect, useState } from 'react'
import { getUseQueryOptions } from '../../Utils'
import { ItemMode } from '../../KPI/KPIConfig'
import { Button, Col, Input, Modal, Pagination, Row, Select, Switch, Typography } from 'antd'
import { useHistory } from 'react-router'
import { SearchOutlined, SortAscendingOutlined, SyncOutlined } from '@ant-design/icons'
import './index.css'
import { ItemStatus, MLP_DETECT_CREATE } from '../../../../lib/utils/common'
import { getDetectUserGroupItemsCount } from '../../../../lib/api/QueryApis/Common'
import { ItemType, OrderBy, OrderType } from '../../../../lib/api/RequestTypes/Common'
import useScopes from '../../../../hooks/useScopes'
import CollectionConfig from '../CollectionConfig'
import CollectionsList from './CollectionsList'

const { Title } = Typography
const { Search } = Input
const { Option } = Select

const CollectionsDashboard = () => {
    const [itemType, setItemType] = useState<ItemStatus>(ItemStatus.APPROVED)
    const [showCreate, setShowCreate] = useState(false)
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)
    const [total, setTotal] = useState(10)
    const [filterText, setFilterText] = useState('')
    const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.createdAt)
    const [orderType, setOrderType] = useState<OrderType>(OrderType.DESC)
    const [showPublic, setShowPublic] = useState<boolean>(true)
    const [refresh, setRefresh] = useState<boolean>(false)
    const history = useHistory()
    const userPermissions = useScopes()

    useEffect(() => {
        setRefresh(!refresh)
    }, [])

    useEffect(() => {
        if (refresh) {
            setTimeout(() => { setRefresh(false) }, 1000); 
            itemCountQuery.refetch()
        }
    })

    const itemCountQuery = useQuery(
        [
            {
                configStatus: itemType,
                filterText: filterText,
                orderBy: orderBy,
                orderType: orderType,
                showPublic: showPublic,
                groupType: ItemType.COLLECTION
            }
        ],
        getDetectUserGroupItemsCount,
        getUseQueryOptions(1)
    )
    useEffect(() => {
        if (itemCountQuery.isSuccess) setTotal(itemCountQuery.data.count)
    }, [itemCountQuery])

    return (
        <>
            <Row gutter={16} justify='space-around' align='middle'>
                <Col span={3} style={{ textAlign: 'center', margin: 'auto' }}>
                    <Switch
                        style={{ marginLeft: '10px', marginTop: '20px' }}
                        checked={showPublic}
                        checkedChildren={'All'}
                        unCheckedChildren={'Yours'}
                        onClick={(checked) => {
                            setShowPublic(checked)
                        }}
                    />
                </Col>
                <Col className='gutter-row' span={5}>
                    <div className='detect-card-footer__key'>Item Type:</div>
                    <Select
                        style={{ width: '100%', marginTop: '5px' }}
                        defaultValue='APPROVED'
                        placeholder='Select Item Type'
                        onSelect={(value: string) => {
                            setItemType(value as ItemStatus)
                        }}
                    >
                        <Option value={ItemStatus.APPROVED} key={0}>
                            Approved
                        </Option>
                        <Option value={ItemStatus.REQUESTED} key={1}>
                            Requested
                        </Option>
                        <Option value={ItemStatus.REJECTED} key={2}>
                            Rejected
                        </Option>
                    </Select>
                </Col>

                <Col className='gutter-row' span={6}>
                    <div className='detect-card-footer__key'>
                        <SearchOutlined /> Search
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <Search
                            placeholder='Search Items'
                            onSearch={(text) =>
                                setFilterText(text.trim().toLowerCase())
                            }
                            enterButton
                        />
                    </div>
                </Col>
                <Col className='gutter-row' span={6}>
                    <Row gutter={16}>
                        <Col className='gutter-row' span={12}>
                            <div className='detect-card-footer__key'>
                                <SortAscendingOutlined /> Sort By
                            </div>
                            <Select
                                style={{
                                    width: '100%',
                                    margin: '5px 0px 5px 0px'
                                }}
                                defaultValue='createdAt'
                                placeholder='Order By'
                                onSelect={(value: string) => {
                                    setOrderBy(value as OrderBy)
                                }}
                            >
                                <Option value={OrderBy.createdAt} key={0}>
                                    Created At
                                </Option>
                                <Option value={OrderBy.createdBy} key={1}>
                                    Created By
                                </Option>
                                <Option value={OrderBy.problemArea} key={2}>
                                    Problem Area
                                </Option>
                                <Option value={OrderBy.itemName} key={3}>
                                    Item Name
                                </Option>
                                <Option value={OrderBy.itemId} key={4}>
                                    Item Id
                                </Option>
                            </Select>
                        </Col>
                        <Col className='gutter-row' span={12}>
                            <div className='detect-card-footer__key'>
                                <SortAscendingOutlined /> Sort Order
                            </div>
                            <Select
                                style={{
                                    width: '100%',
                                    margin: '5px 0px 5px 0px'
                                }}
                                defaultValue='DESC'
                                placeholder='Order Type'
                                onSelect={(value: string) => {
                                    setOrderType(value as OrderType)
                                }}
                            >
                                <Option value={OrderType.ASC} key={0}>
                                    Ascending
                                </Option>
                                <Option value={OrderType.DESC} key={1}>
                                    Descending
                                </Option>
                            </Select>
                        </Col>
                    </Row>
                </Col>
                {userPermissions?.data?.includes(MLP_DETECT_CREATE) && (
                    <Col className='gutter-row' span={3} style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Button style={{ width: '100%' }} type='primary' onClick={() => setShowCreate(true)}>
                            ＋ Add Collection
                        </Button>
                    </Col>
                )}
                <Col 
                    className="gutter-row"
                    span={1}
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Button 
                            style={{ width: '100%' }} 
                            onClick={() => { if (!refresh) setRefresh(true) } } 
                            type='primary' 
                            icon={<SyncOutlined spin={refresh} />} 
                        />
                </Col>
            </Row>
            <Row
                gutter={12}
                style={{
                    overflowY: 'scroll',
                    width: '100%',
                    height:'70vh',
                    paddingBottom:'10px'
                }}
            >
                <CollectionsList
                    filterText={filterText}
                    orderBy={orderBy}
                    orderType={orderType}
                    itemType={itemType}
                    limit={limit}
                    offset={offset}
                    showPublic={showPublic}
                    refresh={refresh}
                />
            </Row>
            <Pagination
                className='detect__footer'
                showSizeChanger
                showQuickJumper
                total={total}
                onChange={(page, pageSize) => {
                    setLimit(pageSize)
                    setOffset(pageSize * (page - 1))
                }}
            />
            <Modal
                title='Create Collection'
                visible={showCreate}
                footer={null}
                width='fit-content'
                bodyStyle={{ padding: '0px' }}
                onCancel={() => setShowCreate(false)}
            >
                <CollectionConfig
                    itemExtId={null}
                    mode={ItemMode.Create}
                    callback={() => setShowCreate(false)}
                    itemConfig={null}
                />
            </Modal>
        </>
    )
}

export default CollectionsDashboard
