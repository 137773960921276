import React, { useState } from 'react'
import { ComposedChart, Line, ResponsiveContainer, Scatter, Tooltip, XAxis, YAxis } from 'recharts'
import { getUseQueryOptions, numberFormatter } from '../../Utils'
import { useQuery } from 'react-query'
import { getDetectResultForecast } from '../../../../lib/api/QueryApis/Common'
import { Empty, Select, Spin } from 'antd'

export interface KPIMinimalChartProps {
    data: any
}

const KPIMinimalChart = ({ data }: KPIMinimalChartProps) => {

    const [currFile, setCurrFile] = useState(Object.keys(data['data'])[0])
    let tsCol = data['timestampColumn']
    let measureCol = data['measureColumn']
    let granularity = data['granularity']
    let algorithms = data['algorithms']
    let values = Object.keys(data['data'])

    const dataQuery = useQuery(
        [{
            filepath: data['data'][currFile],
            tsCol: tsCol,
            measureCol: measureCol,
            granularity: granularity,
            configAlgorithmId: algorithms[0]
        }],
        getDetectResultForecast, getUseQueryOptions(1, 5 * 60 * 1000)
    )

    const tooltipFormatter = (value: any, name: string) => {
        if (name===measureCol || name==='forecast') return numberFormatter.format(value).replace('T', 'K')
        if (name==='ci') return [[
            numberFormatter.format(value[0]).replace('T', 'K') + ' - ',
            numberFormatter.format(value[1]).replace('T', 'K')], 'CI Interval']
        if (name==='anomaly') return [null, null]
        else return value
    }

    if (dataQuery.isSuccess) {
        let graphData = dataQuery.data.forecasts
        return (
            <div style={{ width: '100%', height: '100px' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                    <Select
                        size={'small'}
                        value={currFile}
                        onChange={(value) => {
                            setCurrFile(value)
                        }}
                        options={values.map((it) => {
                            return { label: it, value: it }
                        })}>
                    </Select>
                </div>
                <ResponsiveContainer>
                    <ComposedChart data={graphData}>
                        <Scatter shape='circle' dataKey='anomaly' fill='#f44336aa' />
                        <Line type='monotone' dot={false} dataKey={measureCol} stroke='blue' activeDot={{ r: 8 }} />
                        <XAxis hide={true} padding={{ left: 25 }} dataKey={tsCol} />
                        <YAxis
                            hide={true} domain={['auto', 'auto']}
                            tickFormatter={(value) => numberFormatter.format(value).replace('T', 'K')} />
                        <Tooltip formatter={tooltipFormatter} />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        )
    } else if (dataQuery.isLoading) {
        return (
            <Spin
                className='detect-full-width-center--no-margin'
                tip='loading'
                style={{ minHeight: '100px' }}
            />
        )
    } else {
        return (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
    }
}
export default KPIMinimalChart
