import React, { useEffect, useState } from 'react'
import {
    DetectCollectionGraphNode,
    DetectGroupItem,
    OrderBy,
    OrderType
} from '../../../../lib/api/RequestTypes/Common'
import { useMutation } from 'react-query'
import {
    addDetectGroupItem,
    updateDetectGroupItemConfig
} from '../../../../lib/api/MutationApis/Common'
import { ItemScope } from '../../../../types/DataPlatformTypes/CommonTypes'
import {
    Button,
    Col,
    Form,
    Input,
    notification,
    Pagination,
    Row,
    Select,
    Spin,
    Steps,
    Switch,
    Typography
} from 'antd'
import { preventEnterToSubmit } from '../../Utils'
import './index.css'
import { FilterOutlined, SearchOutlined } from '@ant-design/icons'
import { ItemStatus, sendNotification } from '../../../../lib/utils/common'
import CollectionSelectList from './CollectionSelectList'
import { CreateCollectionGraph } from './CreateProjectGraph'

export enum ItemMode {
    Create = 'Create',
    Update = 'Update',
    View = 'View',
}

export interface DetectProjectConfigProps {
    itemConfig: DetectGroupItem | null
    itemExtId: string | null
    mode: ItemMode
    callback?: Function
}

const ProjectConfig = (props: DetectProjectConfigProps) => {
    const [form] = Form.useForm()
    const [current, setCurrent] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isFraudAndAbuse, setIsFraudAndAbuse] = useState(false)

    const { Step } = Steps

    // Detect Filters Constants
    const { Title } = Typography
    const { Search } = Input
    const { Option } = Select

    const [showPublic, setShowPublic] = useState<boolean>(true)
    const [itemType, setItemType] = useState<ItemStatus>(ItemStatus.APPROVED)
    const [filterText, setFilterText] = useState('')
    const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.createdAt)
    const [orderType, setOrderType] = useState<OrderType>(OrderType.DESC)
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)
    const [refresh, setRefresh] = useState<boolean>(false)
    const [total, setTotal] = useState(10)

    const [addItem] = useMutation(addDetectGroupItem, {
        onSuccess: (data) => {
            setIsLoading(false)
            if (props.callback) {
                props.callback()
            }
            sendNotification(
                notification,
                'success',
                'New Item created: ' + data.itemExternalId,
                ''
            )
        },
        onError: (e) => {
            setIsLoading(false)
            sendNotification(
                notification,
                'error',
                `Failed to add Item. ${(e as any).message} 😓`,
                ''
            )
        }
    })

    const [updateItem] = useMutation(updateDetectGroupItemConfig, {
        onSuccess: (data) => {
            let message =
                data.needPermission==='true'
                    ? 'Update requested for item '
                    :'Updated item '

            sendNotification(
                notification,
                'success',
                message + props.itemExtId + '. Will be live in the next run.',
                ''
            )
            setIsLoading(false)
            if (props.callback) {
                props.callback()
            }
        },
        onError: (e) => {
            setIsLoading(false)
            sendNotification(
                notification,
                'error',
                `Failed to update item. ${(e as any).message}`,
                ''
            )
        }
    })

    let itemEditable = props.mode===ItemMode.Create
    let configEditable = props.mode===ItemMode.Create || props.mode===ItemMode.Update
    let marks: any = {}
    for (let i = 0; i <= 10; i++) {
        marks[i] = i
    }

    useEffect(() => {
        if (props.itemConfig!==null) {
            const itemConfig = {
                ...props.itemConfig,
                enclosingGroupIds: new Set<string>(
                    props.itemConfig.enclosingGroupIds as string[]
                ),
                graphInfo: generateGraphInfoFromGraph(props.itemConfig.graph)
            }
            form.setFieldsValue(itemConfig)
        }
    }, [])

    const generateGraphInfoFromGraph = (
        graph: Map<string, DetectCollectionGraphNode>
    ): Map<string, string[]> => {
        let graphInfo = new Map<string, string[]>()
        Object.entries(graph).forEach((entry: any) => {
            const value = entry[1]
            const kpiItemId = entry[0]
            graphInfo.set(kpiItemId, value.parents)
        })
        return graphInfo
    }

    const submit = () => {
        setIsLoading(true)
        if (props.mode===ItemMode.Create) {
            let item = form.getFieldsValue(true)
            item.graph = generateGraphFromParentMappings(
                item.enclosingGroupIds,
                item.graphInfo
            )
            item.groupType = 'DETECT_PROJECT'
            item.groupVersionId = ''
            item.itemExternalId = ''
            item.graphInfo = ''
            item.problemAreaName = 'netra'
            item.enclosingGroupIds = Array.from(item.enclosingGroupIds)
            item.graph = Object.fromEntries(item.graph)
            addItem(item)
        } else if (props.mode===ItemMode.Update) {
            if (props.itemExtId===null) return
            let item = {
                ...form.getFieldsValue(true),
                dataSourceItemPath: '',
                itemExternalId: props.itemExtId,
                groupVersionId: '',
                groupType: 'DETECT_PROJECT',
                problemAreaName: 'netra'
            }
            item.enclosingGroupIds = Array.from(item.enclosingGroupIds)
            item.graph = generateGraphFromParentMappings(
                item.enclosingGroupIds,
                item.graphInfo as Map<string, string[]>
            )
            item.graph = Object.fromEntries(item.graph)
            updateItem({
                itemExtId: props.itemExtId,
                item: item
            })
        }
    }

    const generateGraphFromParentMappings = (
        enclosingGroupIds: Set<string>,
        parentMappings: Map<string, string[]>
    ): Map<string, { children: string[], parents: string[] }> => {
        let graph = new Map<string, { children: string[], parents: string[] }>()
        enclosingGroupIds.forEach((enclosingGroupId) =>
            graph.set(enclosingGroupId, { parents: [], children: [] })
        )
        parentMappings.forEach((parentList: string[], kpiItemId: string) => {
            graph.set(kpiItemId, {
                children:
                    graph.has(kpiItemId) && graph.get(kpiItemId)!==undefined
                        ? graph.get(kpiItemId)!.children
                        :[],
                parents: parentList
            })
            parentList.forEach((parentKPIId: string) => {
                graph.set(parentKPIId, {
                    children: graph.has(parentKPIId)
                        ? [...graph.get(parentKPIId)!.children, kpiItemId]
                        :[kpiItemId],
                    parents: graph.has(parentKPIId)
                        ? graph.get(parentKPIId)!.parents
                        :[]
                })
            })
        })
        return graph
    }

    if (isLoading) {
        let loadingMessage = ''
        if (props.mode===ItemMode.Update) loadingMessage = 'Updating Item...'
        else loadingMessage = 'Creating Item...'
        return (
            <div className='detect-item__loading'>
                <Spin tip={loadingMessage} />
            </div>
        )
    }
    return (
        <Form
            onFinish={submit}
            layout='vertical'
            form={form}
            className='detect-item__form'
            initialValues={{
                itemScope: ItemScope.PUBLIC
            }}
        >
            <Steps
                size='small'
                style={{ marginBottom: '20px' }}
                current={current}
                onChange={(key) => {
                    setCurrent(key)
                }}
            >
                <Step key={0} title='Project Details' />
                <Step key={1} title='Define Dependencies' />
            </Steps>
            <div hidden={current!==0} className='detect-item__body'>
                <Row gutter={12}>
                    <Col span={18}>
                        <Form.Item
                            name='itemName'
                            rules={[{ required: true, message: '' }]}
                            label='Project Name'
                        >
                            <Input
                                disabled={!configEditable}
                                placeholder='Name of Project'
                                onKeyDown={preventEnterToSubmit}
                                maxLength={200}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='itemScope'
                            rules={[{ required: true, message: '' }]}
                            label='Item Scope'
                        >
                            <Select disabled={!itemEditable}>
                                {isFraudAndAbuse && (
                                    <Option value={ItemScope.PRIVATE} key={0}>
                                        Private
                                    </Option>
                                )}
                                <Option value={ItemScope.PUBLIC} key={1}>
                                    Public
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <Form.Item
                            name='description'
                            rules={[{ required: false, message: '' }]}
                            label='Project Description'
                        >
                            <Input
                                disabled={!configEditable}
                                placeholder='Short Description of Project'
                                onKeyDown={preventEnterToSubmit}
                                maxLength={200}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} justify='space-around' align='middle'>
                    <Col span={5} offset={13}>
                        <div className='detect-card-footer__key'>
                            Show Public Items:
                        </div>
                        <Switch
                            style={{ marginLeft: '5px', alignSelf: 'center' }}
                            checked={showPublic}
                            onClick={(checked) => {
                                setShowPublic(checked)
                            }}
                        />
                    </Col>
                    <Col className='gutter-row' span={6}>
                        <div className='detect-card-footer__key'>
                            <SearchOutlined /> Search
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Search
                                placeholder='Search Items'
                                onSearch={(text) =>
                                    setFilterText(text.trim().toLowerCase())
                                }
                                enterButton
                            />
                        </div>
                    </Col>
                </Row>
                <Form.Item
                    name='enclosingGroupIds'
                    rules={[{ required: true, message: '' }]}
                    label='Project Collections'
                >
                    <CollectionSelectList
                        filterText={filterText}
                        orderBy={orderBy}
                        orderType={orderType}
                        itemType={itemType}
                        limit={limit}
                        offset={offset}
                        showPublic={showPublic}
                        refresh={refresh}
                    />
                </Form.Item>
                <Pagination
                    className='detect__footer'
                    showSizeChanger
                    showQuickJumper
                    total={total}
                    onChange={(page, pageSize) => {
                        setLimit(pageSize)
                        setOffset(pageSize * (page - 1))
                    }}
                />
            </div>
            <div hidden={current!==1} className='detect-item__body'>
                <Row gutter={12}>
                    <Col span={24}>
                        {current==1 && (
                            <div style={{ width: '100%' }}>
                                <Form.Item
                                    name='graphInfo'
                                    rules={[{ required: true, message: '' }]}
                                    label='Project Graph'
                                    initialValue={new Map<string, string[]>()}
                                >
                                    {/* <CreateCollectionPage
										kpiItemIds={Array.from(
											form.getFieldsValue(true)
												.enclosingGroupIds
										)}
									></CreateCollectionPage> */}
                                    <CreateCollectionGraph
                                        collectionItemIds={Array.from(form.getFieldsValue(true).enclosingGroupIds)}
                                    ></CreateCollectionGraph>
                                </Form.Item>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
            <div className='detect-item__footer'>
                <Row gutter={5}>
                    <Col span={12}>
                        {current!==0 && (
                            <Button
                                style={{ width: '100%' }}
                                className='detect-button--primary'
                                onClick={() => setCurrent(current - 1)}
                            >
                                Previous
                            </Button>
                        )}
                    </Col>

                    {current!==1 && (
                        <Col span={12}>
                            <Button
                                style={{ width: '100%' }}
                                className='detect-button--primary'
                                onClick={() => {
                                    setCurrent(current + 1)
                                }}
                            >
                                Next
                            </Button>
                        </Col>
                    )}

                    {current===1 && props.mode!==ItemMode.View && (
                        <Col span={12}>
                            <Button
                                style={{ width: '100%' }}
                                type='primary'
                                htmlType='submit'
                            >
                                {props.mode}
                            </Button>
                        </Col>
                    )}
                </Row>
            </div>
        </Form>
    )
}

export default ProjectConfig
