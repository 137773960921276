import { useQuery } from 'react-query'
import React, { useEffect, useState } from 'react'
import { getUseQueryOptions } from '../../Utils'
import KPIConfig, { ItemMode } from '../KPIConfig'
import {
    Button,
    Col,
    Empty,
    Input,
    Modal,
    Pagination,
    Row,
    Select,
    Spin,
    Switch,
    Checkbox,
} from 'antd'
import KPIList from './KPIList'
import { SearchOutlined, SortAscendingOutlined, SyncOutlined } from '@ant-design/icons'
import './index.css'
import { ItemStatus, MLP_DETECT_CREATE } from '../../../../lib/utils/common'
import {
    getDetectItem,
    getDetectUserItemsCount,
} from '../../../../lib/api/QueryApis/Common'
import {
    DataSourceType,
    OrderBy,
    OrderType,
} from '../../../../lib/api/RequestTypes/Common'
import useScopes from '../../../../hooks/useScopes'

const { Search } = Input
const { Option } = Select

const KPIDashboard = () => {
    const [itemType, setItemType] = useState<ItemStatus>(ItemStatus.APPROVED)
    const [dataSourceType, setDataSourceType] = useState<string>('ALL')
    const [showCreate, setShowCreate] = useState(false)
    const [showUpdate, setShowUpdate] = useState<(string | null)[]>([
        null,
        null,
    ])
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)
    const [total, setTotal] = useState(10)
    const [filterText, setFilterText] = useState('')
    const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.createdAt)
    const [orderType, setOrderType] = useState<OrderType>(OrderType.DESC)
    const [showPublic, setShowPublic] = useState<boolean>(false)
    const [showProblematic, setShowProblematic] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const userPermissions = useScopes()

    const itemQuery = useQuery(
        [
            {
                itemExtId: showUpdate[0]!!,
                configId: showUpdate[1]!!,
            },
        ],
        getDetectItem,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: 1000 * 60,
            retry: 1,
            enabled: showUpdate[0] !== null,
        }
    )

    useEffect(() => {
        setRefresh(true)
    }, [])

    useEffect(() => {
        if (refresh) { 
            setTimeout(() => { setRefresh(false) }, 1000); 
            itemCountQuery.refetch()  
        }
    }, [refresh])

    const itemCountQuery = useQuery(
        [
            {
                configStatus: itemType,
                filterText: filterText,
                orderBy: orderBy,
                orderType: orderType,
                showPublic: showPublic,
                dataSourceType: dataSourceType,
                showProblematic: showProblematic,
            },
        ],
        getDetectUserItemsCount,
        getUseQueryOptions(1)
    )
    useEffect(() => {
        if (itemCountQuery.isSuccess) setTotal(itemCountQuery.data.count)
    }, [itemCountQuery])

    return (
        <div>
            <Row gutter={4}>
                <Col span={3} style={{ textAlign: 'center' }}>
                    <Switch
                        style={{ marginLeft: '10px', marginTop: '20px' }}
                        checked={showPublic}
                        checkedChildren={'All'}
                        unCheckedChildren={'Yours'}
                        onClick={(checked) => {
                            setShowPublic(checked)
                        }}
                    />
                </Col>
                <Col span={3} style={{ textAlign: 'center' }}>
                    <Checkbox
                    style={{ marginTop: '20px' }}
                        onChange={(event: any) =>
                            setShowProblematic(event.target.checked)
                        }
                    >
                        Show Only Active Incidents
                    </Checkbox>
                </Col>

                <Col className="gutter-row" span={5}>
                    <div className="detect-card-footer__key">
                        <SearchOutlined /> Search
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <Search
                            placeholder="Search Items"
                            onSearch={(text) =>
                                setFilterText(text.trim().toLowerCase())
                            }
                            enterButton
                        />
                    </div>
                </Col>
                <Col className="gutter-row" span={3}>
                    <div className="detect-card-footer__key">Data Source:</div>
                    <Select
                        style={{ width: '100%', marginTop: '5px' }}
                        placeholder="Select Data Source Type"
                        defaultValue={'ALL'}
                        onSelect={(value: string) => {
                            setDataSourceType(value as string)
                        }}
                    >
                        <Option value={'ALL'} key={0}>
                            All
                        </Option>
                        <Option value={DataSourceType.DATA_PLATFORM} key={1}>
                            Data Platform
                        </Option>
                        <Option value={DataSourceType.PROMETHEUS} key={2}>
                            Prometheus
                        </Option>
                    </Select>
                </Col>
                <Col className="gutter-row" span={2}>
                    <div className="detect-card-footer__key">Item Type:</div>
                    <Select
                        style={{ width: '100%', marginTop: '5px' }}
                        defaultValue="APPROVED"
                        placeholder="Select Item Type"
                        onSelect={(value: string) => {
                            setItemType(value as ItemStatus)
                        }}
                    >
                        <Option value={ItemStatus.APPROVED} key={0}>
                            Approved
                        </Option>
                        <Option value={ItemStatus.REQUESTED} key={1}>
                            Requested
                        </Option>
                        <Option value={ItemStatus.REJECTED} key={2}>
                            Rejected
                        </Option>
                    </Select>
                </Col>
                <Col className="gutter-row" span={4}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                            <div className="detect-card-footer__key">
                                <SortAscendingOutlined /> Sort By
                            </div>
                            <Select
                                style={{
                                    width: '100%',
                                    margin: '5px 0px 5px 0px',
                                }}
                                defaultValue="createdAt"
                                placeholder="Order By"
                                onSelect={(value: string) => {
                                    setOrderBy(value as OrderBy)
                                }}
                                dropdownMatchSelectWidth={false}
                            >
                                <Option value={OrderBy.createdAt} key={0}>
                                    Created At
                                </Option>
                                <Option value={OrderBy.createdBy} key={1}>
                                    Created By
                                </Option>
                                <Option value={OrderBy.problemArea} key={2}>
                                    Problem Area
                                </Option>
                                <Option value={OrderBy.itemName} key={3}>
                                    Item Name
                                </Option>
                                <Option value={OrderBy.itemId} key={4}>
                                    Item Id
                                </Option>
                            </Select>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <div className="detect-card-footer__key">
                                <SortAscendingOutlined /> Sort Order
                            </div>
                            <Select
                                style={{
                                    width: '100%',
                                    margin: '5px 0px 5px 0px',
                                }}
                                defaultValue="DESC"
                                placeholder="Order Type"
                                onSelect={(value: string) => {
                                    setOrderType(value as OrderType)
                                }}
                            >
                                <Option value={OrderType.ASC} key={0}>
                                    Ascending
                                </Option>
                                <Option value={OrderType.DESC} key={1}>
                                    Descending
                                </Option>
                            </Select>
                        </Col>
                    </Row>
                </Col>
                {userPermissions?.data?.includes(MLP_DETECT_CREATE) && (
                    <Col
                        className="gutter-row"
                        span={3}
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            style={{ width: '100%' }}
                            type="primary"
                            onClick={() => setShowCreate(true)}
                        >
                            ＋ Add KPI
                        </Button>
                    </Col>
                )}
                <Col 
                    className="gutter-row"
                    span={1}
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Button 
                            style={{ width: '100%' }} 
                            onClick={() => { if (!refresh) setRefresh(true) } } 
                            type='primary' 
                            icon={<SyncOutlined 
                            spin={refresh} />} 
                        />
                </Col>
            </Row>
            <Row
                gutter={12}
                style={{
                    overflowY: 'scroll',
                    width: '100%',
                    height: '70vh',
                    paddingBottom: '10px',
                }}
            >
                <KPIList
                    filterText={filterText}
                    orderBy={orderBy}
                    orderType={orderType}
                    itemType={itemType}
                    dataSourceType={dataSourceType}
                    limit={limit}
                    offset={offset}
                    showPublic={showPublic}
                    showProblematic={showProblematic}
                    refresh={refresh}
                    openCallback={(itemExtId: string, configId: string) => {
                        setShowUpdate([itemExtId, configId])
                    }}
                />
            </Row>
            <Row style={{ marginTop: '5px' }} gutter={12}>
                <Pagination
                    className="detect__footer"
                    showSizeChanger
                    showQuickJumper
                    total={total}
                    onChange={(page, pageSize) => {
                        setLimit(pageSize)
                        setOffset(pageSize * (page - 1))
                    }}
                />
            </Row>
            {/* </div> */}
            <Modal
                title="Create Item"
                visible={showCreate}
                footer={null}
                width="fit-content"
                bodyStyle={{ padding: '0px' }}
                onCancel={() => setShowCreate(false)}
            >
                <KPIConfig
                    itemExtId={null}
                    mode={ItemMode.Create}
                    callback={() => setShowCreate(false)}
                    itemConfig={null}
                />
            </Modal>

            <Modal
                title="Modify Item"
                visible={showUpdate[0] !== null}
                footer={null}
                width="fit-content"
                style={{ minWidth: '30vw' }}
                bodyStyle={{ padding: '10px' }}
                onCancel={() => setShowUpdate([null, null])}
            >
                {showUpdate[0] !== null && itemQuery.isSuccess && (
                    <KPIConfig
                        itemExtId={showUpdate[0]}
                        mode={ItemMode.Update}
                        callback={() => setShowUpdate([null, null])}
                        itemConfig={itemQuery.data}
                    />
                )}
                {itemQuery.isLoading && (
                    <Spin className="detect-full-width-center" />
                )}
                {itemQuery.isError && (
                    <Empty
                        className="detect-full-width-center"
                        description={''}
                    />
                )}
            </Modal>
        </div>
    )
}

export default KPIDashboard
