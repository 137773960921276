import { useQuery } from 'react-query'
import {
    getUserDetectGroupItems,
    getUserDetectItems
} from '../../../../lib/api/QueryApis/Common'
import React, { useEffect } from 'react'
import { Empty, List, notification, Spin, Row, Col } from 'antd'
import { KPICard } from '../../KPI/KPICard'
import { ItemStatus, sendNotification } from '../../../../lib/utils/common'
import {
    ItemType,
    OrderBy,
    OrderType
} from '../../../../lib/api/RequestTypes/Common'
import { getUseQueryOptions } from '../../Utils'
import { CollectionsCard } from './CollectionsCard'

interface DetectCollectionListProps {
    filterText: string
    orderBy: OrderBy
    orderType: OrderType
    itemType: ItemStatus
    limit: number
    offset: number
    showPublic: boolean
    refresh?: boolean
}

const CollectionsList = (
    {
        filterText,
        orderBy,
        orderType,
        itemType,
        limit,
        offset,
        showPublic,
        refresh
    }: DetectCollectionListProps) => {
    const query = useQuery(
        [
            {
                configStatus: itemType,
                filterText: filterText,
                orderBy: orderBy,
                orderType: orderType,
                limit: limit,
                offset: offset,
                showPublic: showPublic,
                groupType: ItemType.COLLECTION
            }
        ],
        getUserDetectGroupItems,
        getUseQueryOptions(2)
    )
    useEffect(() => {
        if (refresh && query.isSuccess) {
            query.clear()
            query.refetch()
        }
    }, [refresh])

    if (query.isSuccess) {
        return (
            <Row className='detect-list-scroll'>
                {query.data.map((item, idx) => (
                    <Col key={idx} span={8}>
                        <CollectionsCard
                            itemSummary={item}
                            ignoreClick={
                                itemType===ItemStatus.REJECTED ||
                                itemType===ItemStatus.REQUESTED
                            }
                        />
                    </Col>
                ))}
            </Row>
        )
    } else if (query.isError) {
        sendNotification(notification, 'error', 'Can\'t fetch items', '')
        return (
            <div className='detect-full-center'>
                <Empty description={<span>Error Occurred. No Data</span>} />
            </div>
        )
    } else {
        return (
            <div className='detect-full-center'>
                <Spin tip='Loading' />
            </div>
        )
    }
}
export default CollectionsList
