import { Empty, List, notification, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import {
    getMultipleGroupItemSummaries,
    getMultipleItemSummaries
} from '../../../../lib/api/QueryApis/Common'
import { ItemType } from '../../../../lib/api/RequestTypes/Common'
import { DetectGroupItemSummary } from '../../../../lib/api/ResponseTypes/Common'
import { sendNotification } from '../../../../lib/utils/common'
import { getUseQueryOptions } from '../../Utils'
import { Card, Divider, Select, Typography } from 'antd'
import {
    IdcardOutlined,
    UserOutlined
} from '@ant-design/icons'
import { DefaultOptionType } from 'antd/lib/select'

const { Text, Title } = Typography
const { Option } = Select

interface DetectCreateCollectionGraphProps {
    value?: Map<string, string[]>
    onChange?: (parentMappings: Map<string, string[]>) => void
    collectionItemIds: Array<string>
}

export const CreateCollectionGraph = (
    {
        collectionItemIds,
        // getGraphInfo,
        value,
        onChange
    }: DetectCreateCollectionGraphProps) => {
    const [parentMappings, setParentMappings] = useState<Map<string, string[]>>(
        value || new Map<string, string[]>()
    )
    const [possibleParents, setPossibleParents] = useState<
        Map<string, string[]>
    >(new Map<string, string[]>())
    const [collectionItemSummaryList, setcollectionItemSummaryList] = useState<
        Map<string, DetectGroupItemSummary>
    >(new Map())

    const collectionSummaries = useQuery(
        [
            {
                itemExtIds: collectionItemIds,
                groupType: ItemType.COLLECTION
            }
        ],
        getMultipleGroupItemSummaries,
        getUseQueryOptions(1)
    )

    useEffect(() => {
        let itemSummaries = new Map<string, DetectGroupItemSummary>()
        collectionSummaries.data?.forEach((groupItemSummary) => {
            itemSummaries.set(groupItemSummary.itemExternalId, groupItemSummary)
        })
        setcollectionItemSummaryList(itemSummaries)
    }, [collectionSummaries.isSuccess])

    const onParentListUpdate = (groupItemId: string, parent: string[]) => {
        let tempparentMapping = parentMappings
        tempparentMapping.set(groupItemId, parent)
        setParentMappings(tempparentMapping)
        if (typeof onChange==='function') onChange(tempparentMapping)
        updatePossibleParents()
    }

    const updatePossibleParents = () => {
        if (parentMappings && collectionSummaries.isSuccess) {
            const tempPossibleParents = new Map<string, string[]>()
            collectionItemIds.forEach((itemId) => {
                tempPossibleParents.set(
                    itemId,
                    collectionItemIds.filter((tempItemId) => itemId!=tempItemId)
                )
            })
            tempPossibleParents.forEach(
                (possibleParentList, groupIdInContext) => {
                    tempPossibleParents.set(
                        groupIdInContext,
                        collectionItemIds.filter((itemId) => {
                            return (
                                itemId!=groupIdInContext &&
                                !parentMappings
                                    .get(itemId)
                                    ?.includes(groupIdInContext)
                            )
                        })
                    )
                }
            )
            setPossibleParents(tempPossibleParents)
        }
    }

    useEffect(() => {
        updatePossibleParents()
    }, [collectionSummaries.isSuccess, value])

    const getParentOptionsList = (collectionItemId: string): DefaultOptionType[] => {
        const groupSummaries = possibleParents.get(collectionItemId)?.map(
            (itemId) => collectionItemSummaryList.get(itemId)!
        ).filter((it) => {
            return it!==undefined
        })
        let options = groupSummaries?.map((value) => {
            return {
                value: value.itemExternalId,
                label: '[' + value.itemExternalId + '] ' + value.itemName
            }
        })
        return options ? options:[]
    }

    if (collectionSummaries.isSuccess) {
        return (
            <List className='detect-list-scroll'>
                {collectionItemIds.map((collectionItemId, idx) => (
                    <List.Item key={idx}>
                        {collectionItemSummaryList.has(collectionItemId) && (
                            <Card
                                hoverable
                                size='small'
                                className='detect-item-card'
                            >
                                <div
                                    className='detect-item-card__body'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className='detect-item-card__title'>
                                        <Title
                                            level={4}
                                            style={{ margin: 0, width: '100%' }}
                                        >
                                            {collectionItemSummaryList.get(collectionItemId)!.itemName}
                                        </Title>
                                        <Text type='secondary'>
                                            Problem Area:{' '}
                                            {collectionItemSummaryList.get(collectionItemId)!.problemArea}
                                        </Text>
                                    </div>
                                    <div style={{ minWidth: '280px' }}>
                                        <Select
                                            mode='multiple'
                                            allowClear
                                            showArrow
                                            dropdownMatchSelectWidth
                                            style={{ minWidth: '260px' }}
                                            placeholder='Select immediate parents'
                                            value={parentMappings.get(collectionItemId)!}
                                            onChange={(selectedValues: string[]) => {
                                                onParentListUpdate(collectionItemId, selectedValues)
                                            }}
                                            size='large'
                                            maxTagCount='responsive'
                                        >
                                            {getParentOptionsList(
                                                collectionItemId
                                            )!.map((value) => {
                                                return (
                                                    <Option
                                                        style={{ width: '100%' }}
                                                        value={value.value}
                                                        key={value.value}
                                                    >
                                                        {value.label}
                                                        {/* {'[' +
																value.itemExternalId +
																'] ' +
																value.itemName} */}
                                                    </Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <Divider
                                    style={{ margin: '0px 0px 5px 0px' }}
                                />
                                <div className='detect-item-card__footer'>
									<span>
										<span className='detect-card-footer__key'>
											<IdcardOutlined /> ID:
										</span>
										<span className='detect-card-footer__value'>
											{' '}
                                            {collectionItemSummaryList.get(collectionItemId)!.itemExternalId}
										</span>
									</span>
                                    <span>
										<span className='detect-card-footer__key'>
											<UserOutlined /> Created By:
										</span>
										<span className='detect-card-footer__value'>
											{' '}
                                            {collectionItemSummaryList.get(collectionItemId)!.createdBy}
										</span>
									</span>
                                </div>
                            </Card>
                        )}
                    </List.Item>
                ))}
            </List>
        )
    } else if (collectionSummaries.isError) {
        sendNotification(notification, 'error', 'Can\'t fetch items', '')
        return (
            <div className='detect-full-center'>
                <Empty description={<span>Error Occurred. No Data</span>} />
            </div>
        )
    } else {
        return (
            <div className='detect-full-center'>
                <Spin tip='Loading' />
            </div>
        )
    }
}
