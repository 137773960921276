import {
    AuthenticationResult,
    PublicClientApplication,
    Configuration,
    AuthError,
} from '@azure/msal-browser'

let msalInstance: any

export default function generateMsalInstance() {
    msalInstance = new PublicClientApplication(msalConfig)
    return msalInstance
}

export const msalConfig: Configuration = {
    auth: {
        clientId: '4591584b-2685-443c-abb5-984e10b99fe1',
        authority:
            'https://login.microsoftonline.com/d855c253-b234-4880-b9e6-d490d030a35d',
        redirectUri: '/',
    },
    cache: {
        storeAuthStateInCookie: true,
    },
}

export const acquireAccessToken = async () => {
    if (msalInstance) {
        const activeAccount = msalInstance.getActiveAccount()
        console.log(activeAccount)
        const accounts = msalInstance.getAllAccounts()
        console.log(accounts)
        const request = {
            scopes: ['User.Read'],
        }
        msalInstance
            .acquireTokenSilent(request)
            .then((authResult: AuthenticationResult) => {
                const reloadTimeInSec = getReloadTimeInSecs(
                    authResult.expiresOn
                )
                saveTokenInCookies(authResult)
                setTimeout(() => {
                    acquireAccessToken()
                }, 1000 * (reloadTimeInSec - 60))
                return authResult.idToken
            })
            .catch((err: AuthError) => {
                console.log('error while refreshing token', err)
            })
    } else {
        console.log('could not find msal token')
    }
}

function getReloadTimeInSecs(expiryTime: Date | null) {
    if (!expiryTime) {
        expiryTime = new Date()
        expiryTime.setTime(expiryTime.getTime() + 60 * 60 * 1000)
    }
    const date = new Date()
    return expiryTime.valueOf() / 1000 - date.getTime() / 1000 - 60
}

function saveTokenInCookies(response: AuthenticationResult): void {
    var now = new Date()
    var time = now.getTime()
    var expireTime = time + 1000 * 60 * 60 * 2
    now.setTime(expireTime)
    document.cookie =
        '_id_token=' +
        response.idToken +
        ';expires=' +
        now.toUTCString() +
        ';path=/'
}
