import { Col, Radio, Row, Spin, Table, Tabs, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { getTraceIds, getHttpRequestByTraceId, getExceptionList } from '../../../../../lib/api/MutationApis/Common';
import { getUdaanResourceList } from '../../../../../lib/api/QueryApis/Common';
import { HttpRequestNode, ExceptionCount, ExceptionTraces } from '../../../../../lib/api/ResponseTypes/Common';
import { buildCallTree } from './TraceUtils';
import { Empty } from 'antd';
import { Space, Select } from 'antd';
import { ColumnsType } from 'antd/es/table';
import useDidMountEffect from './useDidMountHook';
import ShowTraceID from './ShowTraceID';
import ShowRequestTrace from './ShowRequestTree';
import "./index.css"
const { TabPane } = Tabs
export interface TracerScreenProps {
    cluster: string,
    namespace: string,
    serviceName: string,
    resource: string | null,
    chartRangeRef: React.MutableRefObject<{
        start: string;
        end: string;
    }>
}

const TracerScreen = ({
    cluster,
    namespace,
    serviceName,
    resource,
    chartRangeRef
}: TracerScreenProps) => {
    const [selectedResource, setSelectedResource] = useState<string>(resource != null ? resource : 'None');
    const [resourceList, setResourceList] = useState<string[]>(['None']);
    const [resourceListStatus, setResourceListStatus] = useState<boolean>(false);
    const [resourceListIsLoading, setResourceListIsLoading] = useState<boolean>(false);
    const [showOnly5xx, setShowOnly5xx] = useState<boolean>(true)
    const [exceptionList, setExceptionList] = useState<ExceptionCount[]>([])
    const [exceptionListIsLoading, setExceptionListIsLoading] = useState<boolean>(true)
    const [selectedException, setSelectedException] = useState<ExceptionCount>()
    const [traceIds, setTraceIds] = useState<ExceptionTraces[]>([])
    const [traceIdListIsLoading, setTraceIdListIsLoading] = useState<boolean>(true)
    const [selectedTraceId, setSelectedTraceId] = useState<string>()
    const [requestTree, setRequestTree] = useState<HttpRequestNode[]>([]);
    const [requestTreeIsLoading, setRequestTreeIsLoading] = useState<boolean>(false);
    
    useEffect(() => {
        resourceListRequest({
            cluster: cluster,
            namespace: namespace,
            serviceName: serviceName,
        })
        setResourceListIsLoading(true)
        fetchExceptions()
    }, [])

    useEffect(() => {
        function handleResize(e:any) {
            fetchExceptions()
        }
        document.addEventListener('chart-slider-moved', handleResize)
      
        return function cleanupListener() {
            document.removeEventListener('chart-slider-moved', handleResize)
        }
    }, [])

    useEffect(() => {
        if(exceptionList.length > 0) {
            const firstException = exceptionList[0]
            setSelectedException(firstException)
        } else {
            setTraceIdListIsLoading(false)
        }
    }, [exceptionList])

    useEffect(() => {
        if(selectedException != undefined) {
            traceIdsRequest({
                cluster,
                namespace,
                serviceName,
                resourceName: selectedResource !='None' ? selectedResource : null,
                exceptionMessage: selectedException.exceptionMsg,
                only5xx: showOnly5xx,
                fromDt: chartRangeRef.current.start,
                toDt: chartRangeRef.current.end
            })
        }
    }, [selectedException])

    useEffect(() => {
        if(selectedTraceId != undefined) {
            setRequestTreeIsLoading(true)
            failedHttpRequests({
                cluster,
                traceId: selectedTraceId
            })            
        }
    }, [selectedTraceId])


    useDidMountEffect(() => {
        fetchExceptions()
    }, [selectedResource, showOnly5xx]);    


    const [resourceListRequest] = useMutation(getUdaanResourceList, {
        onSuccess: (resp) => {
            resp.push('None')
            setResourceList(resp)
            setResourceListIsLoading(false)
            setResourceListStatus(false)
        },
        onError: (error) => {
            setResourceList([])
            setResourceListStatus(true)
            setResourceListIsLoading(false)
        },
    })

    const [exceptionListRequest] = useMutation(getExceptionList, {
        onSuccess: (resp) => {
            resp.map(item => item["key"] = Math.round(Math.random()*1000000))
            setExceptionList(resp)
            setExceptionListIsLoading(false)
        },
        onError: (error) => {
            setExceptionList([])
            setExceptionListIsLoading(false)
        },
    })

    const [traceIdsRequest] = useMutation(getTraceIds, {
        onSuccess: (resp) => {
            setTraceIds(resp)
            setSelectedTraceId(resp[0].traceId)
            setTraceIdListIsLoading(false)
        },
        onError: (error) => {
            setTraceIds([])
            setTraceIdListIsLoading(false)
        },
    })

    const [failedHttpRequests] = useMutation(getHttpRequestByTraceId, {
        onSuccess: (resp) => {
            const callTree = buildCallTree(resp)
            setRequestTree(callTree)
            setRequestTreeIsLoading(false)
        },
        onError: (error) => {
            setRequestTree([])
            setRequestTreeIsLoading(false)
        },
    })

    const getDefaultSelectedKey = (): React.Key[] => {
        const selectedRowKeys: React.Key[] = []
        if(selectedException!=undefined && selectedException.key != undefined) {
            selectedRowKeys.push(selectedException.key)
        }
        return selectedRowKeys
    }

    const handleExceptionClick = (index: number) => {
        const exceptionObj = exceptionList[index]
        setSelectedException(exceptionObj)
    }

    const fetchExceptions = () => {
        setExceptionListIsLoading(true)
        let payload = {
            cluster,
            namespace,
            serviceName,
            fromDt: chartRangeRef.current.start,
            toDt: chartRangeRef.current.end,
            only5xx: showOnly5xx,
            resourceName: selectedResource !='None' ? selectedResource : null
        }
        exceptionListRequest(payload)
    }

    return (
        <div style={{ backgroundColor: 'white', marginTop: '-15px', minHeight: '50vh', padding: '10px' }}>
            <Space>
                <span>
                    Resource: 
                    <Select
                        showSearch
                        value={selectedResource}
                        onChange={(value) => setSelectedResource(value)}
                        status={resourceListStatus ? 'error' : ''}
                        loading={resourceListIsLoading}
                        style={{
                            minWidth: '200px',
                            marginLeft: '10px'
                        }}
                        placeholder="Select a resource name"
                        options={resourceList.map((it) => {
                            return { value: it, label: it }
                        })}
                        dropdownMatchSelectWidth={false}
                    />
                </span>
                <span>
                    Status Code: 
                    <Select
                        defaultValue={true}
                        onChange={(value) => setShowOnly5xx(value)}
                        style={{
                            minWidth: '100px',
                            marginLeft: '10px'
                        }}
                        options={statusCodeFilterOptions.map((it) => {
                            return { value: it.showOnly5xx, label: it.label }
                        })}
                    />
                </span>
            </Space>
            {exceptionListIsLoading ? (
                <Spin style={{ width: '100%', height: '100%' }} />
            ): (
                exceptionList.length == 0 ? (
                    <Empty description="No exceptions found in the selected time range"/>
                ) : (
                    <Row style={{paddingTop: '10px'}}>
                        <Col style={{ width:'30%', overflowX: 'scroll'}}>
                            <Tabs defaultActiveKey='0' onChange={(value:any)=> handleExceptionClick(value)} tabPosition="left">
                                {exceptionList.map((item:ExceptionCount, index) => (
                                    <TabPane
                                        tab={(
                                            <div style={{display: 'flex', justifyContent:'space-between'}}>
                                                
                                                <span className='exception-name' title={item.exceptionMsg}>
                                                    {item.exceptionMsg}
                                                </span>
                                                <span className='exception-count'>
                                                    ({item.count}) 
                                                </span>
                                                {item.statusCode < 500 ? (
                                                    <span className='client-exception-code'>
                                                        {item.statusCode}
                                                    </span>
                                                ): (
                                                    <span className='server-exception-code'>
                                                        {item.statusCode}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                        key={index}
                                    >
                                    </TabPane>
                                ))}
                            </Tabs>
                        </Col>
                        <Col style={{ width:'70%', overflowX: 'scroll' }}>
                            {!traceIdListIsLoading && traceIds.length == 0 && selectedException != undefined && 
                                <Empty description="No traceIDs found for the selected exception"/>
                            }
                            {traceIdListIsLoading && traceIds.length == 0 && 
                                <Spin style={{ width: '100%', height: '100%' }} />
                            }
                            {!traceIdListIsLoading && traceIds.length > 0 && (
                                <>
                                    <ShowTraceID
                                        selectedTraceId={selectedTraceId}
                                        traceIdListIsLoading={traceIdListIsLoading}
                                        setSelectedTraceId={setSelectedTraceId}
                                        traceIds={traceIds}
                                    />
                                    <ShowRequestTrace
                                        cluster={cluster}
                                        namespace={namespace}
                                        serviceName={serviceName}
                                        requestTree={requestTree}
                                        requestTreeIsLoading={requestTreeIsLoading}
                                        selectedTraceId={selectedTraceId}
                                    />
                                </>
                            )}
                        </Col>
                    </Row>
                )
            )}
        </div>
    )
}

export default TracerScreen

const columns: ColumnsType<ExceptionCount> = [
    {
        title: 'Exceptions',
        render: (_, record) => (
            <Space size="small">
                <Typography.Text>{record.exceptionMsg}</Typography.Text>
                <Typography.Text strong>({record.count})</Typography.Text>
                <Typography.Text type={record.statusCode < 500 ? "warning" : "danger"}>{record.statusCode}</Typography.Text>
            </Space>
        ),
    }
];
const statusCodeFilterOptions = [
    {
        showOnly5xx: true,
        label: 'Only 5xx'
    },
    {
        showOnly5xx: false,
        label: '5xx & 4xx'
    }
]