import {
    AnomalyMode,
    DataSourceType,
    DetectAlgorithmInput,
    SeverityLevel
} from '../../../../lib/api/RequestTypes/Common'
import { DetectAlgorithm } from '../../../../lib/api/ResponseTypes/Common'
import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Steps,
    Switch,
    Divider
} from 'antd'
import React, { useEffect, useState } from 'react'
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { getSensitivityLevel, preventEnterToSubmit } from '../../Utils'
import DynamicForm from '../../../Common/DynamicForm'
import TextArea from 'antd/lib/input/TextArea'

const { Option } = Select

export interface AlgorithmInputProp {
    algorithmInput: DetectAlgorithmInput
    disabled: boolean
    supportedAlgorithms: {
        [key: string]: DetectAlgorithm
    }
    callback: Function
    defaultValues: { [key: string]: any } | null

    squadcastSlugs: any[] | null

    datasourceType: DataSourceType
}

const AlgorithmInput = (
    {
        algorithmInput,
        disabled,
        supportedAlgorithms,
        callback,
        defaultValues,
        squadcastSlugs,
        datasourceType
    }: AlgorithmInputProp) => {
    const [form] = Form.useForm()
    const [hyperParameters] = Form.useForm()
    const [isAdvance, setIsAdvance] = useState<boolean>(
        datasourceType===DataSourceType.PROMETHEUS
    )
    const [metricQueryString, setMetricQueryString] = useState<string>()
    const algorithm = Form.useWatch('algorithm', form)

    const updateQueryString = () => {
        if (!supportedAlgorithms[algorithm]) return
        let queryString =
            supportedAlgorithms[algorithm].alertQueryTemplateString
        Object.keys(
            supportedAlgorithms[algorithm].hyperParameterDefinition
        ).forEach((value: string) => {
            queryString = queryString.replaceAll(
                `<${value}>`,
                hyperParameters.getFieldValue(value)
            )
        })
        setMetricQueryString(queryString)
    }

    const onChangeCallback = (deleteAlgo: Boolean = false) => {
        if (!deleteAlgo) {
            updateQueryString()
            callback(
                {
                    ...form.getFieldsValue(),
                    hyperParameters: hyperParameters.getFieldsValue(),
                    configAlgorithmId: algorithmInput.configAlgorithmId
                },
                algorithmInput.configAlgorithmId
            )
        } else {
            callback(null, algorithmInput.configAlgorithmId)
        }
    }

    useEffect(() => {
        form.setFieldsValue(algorithmInput)
        hyperParameters.setFieldsValue(algorithmInput.hyperParameters)
    }, [algorithmInput])

    useEffect(() => {
        if (defaultValues!==null) {
            form.setFieldsValue(defaultValues)
            hyperParameters.setFieldsValue(defaultValues)
            if (Object.keys(supportedAlgorithms).length!==0) {
                form.setFieldsValue({
                    sensitivity: getSensitivityLevel(
                        supportedAlgorithms[algorithm],
                        hyperParameters
                    )
                })
            }
            updateQueryString()
        }
    }, [defaultValues])

    return (
        <Form
            size='middle'
            style={{ width: '100%' }}
            form={form}
            layout='inline'
            disabled={disabled}
            initialValues={{ sensitivity: 5 }}
            onChange={() => onChangeCallback()}
        >
            <Row style={{ width: '100%' }}>
                <Col span={8}>
                    <Form.Item
                        name='algorithm'
                        rules={[{ required: true, message: '' }]}
                        label='Algorithm'
                        tooltip={{
                            title: (
                                <span>
									The algorithm used to detect anomalies. See
									<a href='https://www.notion.so/f42003836f144cc0a181d5f1c8d7e626'
                                       target='_blank'
                                    >
										{' '}
                                        this notion doc
									</a>
									. Leave unchanged if not sure
								</span>
                            ),
                            icon: <InfoCircleOutlined />
                        }}
                        style={{ marginBottom: '10px' }}
                    >
                        <Select
                            onChange={(value, option) => {
                                if (
                                    [null, undefined, ''].includes(value) ||
                                    !(value in supportedAlgorithms)
                                )
                                    return
                                let fields =
                                    supportedAlgorithms[value]
                                        .hyperParameterDefinition
                                let defaults = { ...fields }
                                Object.keys(defaults).forEach((key) => (defaults[key] = defaults[key].default))
                                hyperParameters.resetFields()
                                hyperParameters.setFieldsValue(defaults)
                                form.setFieldsValue({ sensitivity: 5 })
                                onChangeCallback()
                            }}
                            disabled={disabled}
                            dropdownMatchSelectWidth={false}
                        >
                            {Object.keys(supportedAlgorithms).map(
                                (it: string) => {
                                    return (
                                        <Option value={it} key={it}>
                                            {supportedAlgorithms[it].algorithmFullName}
                                        </Option>
                                    )
                                }
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name='severity'
                        label='Severity'
                        tooltip={{
                            title: 'Severity of the anomaly',
                            icon: <InfoCircleOutlined />
                        }}
                    >
                        <Select onChange={() => onChangeCallback()}>
                            <Option value={SeverityLevel.CRITICAL} key={0}>
                                CRITICAL
                            </Option>
                            <Option value={SeverityLevel.WARNING} key={1}>
                                WARNING
                            </Option>
                            <Option value={SeverityLevel.INFO} key={2}>
                                INFO
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name='squadcastSlug'
                        label='Squadcast Slug'
                        tooltip={{
                            title: 'Squadcast Slug For Alerting',
                            icon: <InfoCircleOutlined />
                        }}
                        initialValue={'detect-netra-service'}
                    >
                        <Select
                            // defaultValue="detect-netra-service"
                            showSearch
                            onKeyDown={preventEnterToSubmit}
                            onChange={() => onChangeCallback()}
                            disabled={disabled}
                            placeholder='Squadcast Slug'
                            options={
                                squadcastSlugs!==null ? squadcastSlugs:[]
                            }
                        ></Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ width: '100%' }}>
                {datasourceType!==DataSourceType.PROMETHEUS && (
                    <Col span={8}>
                        <Form.Item
                            name='sensitivity'
                            rules={[{ required: false }]}
                            style={{ paddingRight: '20px' }}
                            label='Sensitivity'
                            tooltip={{
                                title: 'Increase sensitivity to classify more points as anomalies and vise versa',
                                icon: <InfoCircleOutlined />
                            }}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0}
                                max={10}
                                disabled={disabled}
                                onChange={(num: number) => {
                                    if (supportedAlgorithms) {
                                        let sensitivityMapping = supportedAlgorithms[algorithm].sensitivityMapping
                                        if (sensitivityMapping===null) return

                                        let change = sensitivityMapping[num]
                                        let hyperparams = hyperParameters.getFieldsValue(true)

                                        hyperparams = { ...hyperparams, ...change }
                                        hyperParameters.setFieldsValue(hyperparams)
                                        onChangeCallback()
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                )}
                {datasourceType!==DataSourceType.PROMETHEUS && (
                    <Col span={10}>
                        <Form.Item
                            name='anomalyMode'
                            rules={[{ required: true }]}
                            style={{ paddingRight: '20px' }}
                            label='Alerts when'
                            initialValue={AnomalyMode.UPPER_AND_LOWER}
                        >
                            <Select
                                onChange={() => {
                                    onChangeCallback()
                                }}
                                style={{ width: '100%' }}
                                disabled={disabled}
                            >
                                <Option
                                    value={AnomalyMode.UPPER_AND_LOWER}
                                    key={0}
                                >
                                    KPI is unusually high or low
                                </Option>
                                <Option value={AnomalyMode.UPPER} key={1}>
                                    KPI is unusually high
                                </Option>
                                <Option value={AnomalyMode.LOWER} key={2}>
                                    KPI is unusually low
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                )}
                {datasourceType!==DataSourceType.PROMETHEUS && (
                    <Col span={4}>
                        <Form.Item
                            name='advancedMode'
                            label='Advance'
                            tooltip={{
                                title: 'Allows you to fine-tune the algorithms parameters',
                                icon: <InfoCircleOutlined />
                            }}
                        >
                            <Switch
                                checked={isAdvance}
                                onClick={(checked) => {
                                    setIsAdvance(checked)
                                }}
                            />
                        </Form.Item>
                    </Col>
                )}
                {datasourceType!==DataSourceType.PROMETHEUS && (
                    <Col
                        span={2}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left'
                        }}
                    >
                        <Button
                            onClick={() => {
                                onChangeCallback(true)
                            }}
                        >
                            <DeleteOutlined />
                        </Button>
                    </Col>
                )}
            </Row>
            <Row gutter={4}>
                <Col>
                    <div
                        style={{
                            fontSize: '10pt',
                            marginBottom: '10px',
                            ...(!isAdvance && { display: 'none' })
                        }}
                    >
                        <Divider style={{ marginBottom: '5px' }} />
                        {Object.keys(supportedAlgorithms).includes(
                            algorithm
                        ) && (
                            <DynamicForm
                                fields={
                                    [null, undefined, ''].includes(algorithm)
                                        ? supportedAlgorithms['prophet'].hyperParameterDefinition
                                        :supportedAlgorithms[algorithm].hyperParameterDefinition
                                }
                                form={hyperParameters}
                                disabled={disabled}
                                callback={() => onChangeCallback()}
                            />
                        )}
                    </div>
                </Col>
                {datasourceType==DataSourceType.PROMETHEUS && (
                    <Col
                        span={22}
                        style={{
                            marginBottom: '10px'
                        }}
                    >
                        <TextArea
                            placeholder='Metric PromQL Alert Query String'
                            contentEditable={false}
                            style={{ width: '100%' }}
                            value={metricQueryString}
                            autoSize={{ minRows: 2 }}
                        />
                    </Col>
                )}
                {datasourceType==DataSourceType.PROMETHEUS && (
                    <Col
                        span={2}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left'
                        }}
                    >
                        <Button
                            onClick={() => {
                                onChangeCallback(true)
                            }}
                        >
                            <DeleteOutlined />
                        </Button>
                    </Col>
                )}
            </Row>
        </Form>
    )
}

export default AlgorithmInput
