import { useQuery } from 'react-query'
import { getUserDetectGroupItems, getUserDetectItems } from '../../../../lib/api/QueryApis/Common'
import React, { useEffect, useState } from 'react'
import { Empty, List, notification, Spin } from 'antd'
import { ItemStatus, sendNotification } from '../../../../lib/utils/common'
import { ItemType, OrderBy, OrderType } from '../../../../lib/api/RequestTypes/Common'
import { getUseQueryOptions } from '../../Utils'
import { SelectCollectionCard } from './SelectCollectionCard'

interface DetectProjectListProps {
    value?: Set<string>
    filterText: string
    orderBy: OrderBy
    orderType: OrderType
    itemType: ItemStatus
    limit: number
    offset: number
    showPublic: boolean
    refresh?: boolean
    onChange?: (selectedKPIs: Set<string>) => void
}

const CollectionSelectList = (
    {
        value,
        filterText,
        orderBy,
        orderType,
        itemType,
        limit,
        offset,
        showPublic,
        refresh,
        onChange
    }: DetectProjectListProps) => {
    const query = useQuery(
        [
            {
                configStatus: itemType,
                filterText: filterText,
                orderBy: orderBy,
                orderType: orderType,
                limit: limit,
                offset: offset,
                showPublic: showPublic,
                groupType: ItemType.COLLECTION
            }
        ],
        getUserDetectGroupItems,
        getUseQueryOptions(2)
    )
    const [selectedCollections, setSelectedCollections] = useState<Set<string>>(
        new Set(value) || new Set()
    )
    const [isSelectedMapping, setIsSelectedMapping] = useState<
        Map<string, boolean>
    >(new Map())

    useEffect(() => {
        if (value) setSelectedCollections(new Set(value))
    }, [value])

    useEffect(() => {
        if (query.isSuccess) {
            query.refetch()
        }
    }, [refresh])

    const onCollectionSelect = (kpiId: string) => {
        setSelectedCollections(new Set<string>([kpiId, ...Array.from(selectedCollections)]))
        let tempSelectedMapping = isSelectedMapping
        tempSelectedMapping.set(kpiId, true)
        setIsSelectedMapping(tempSelectedMapping)
        if (typeof onChange==='function') {
            onChange(new Set<string>([kpiId, ...Array.from(selectedCollections)]))
        }
    }

    const onCollectionDeselect = (kpiId: string) => {
        let selKPIs = selectedCollections
        selKPIs.delete(kpiId)
        setSelectedCollections(selKPIs)
        if (typeof onChange==='function') {
            onChange(selKPIs)
        }
        let tempSelectedMapping = isSelectedMapping
        tempSelectedMapping.set(kpiId, false)
        setIsSelectedMapping(tempSelectedMapping)
    }

    useEffect(() => {
        let tempSelectedMapping = isSelectedMapping
        selectedCollections.forEach((kpiId) => {
            tempSelectedMapping.set(kpiId, true)
        })
        setIsSelectedMapping(tempSelectedMapping)
    }, [])

    if (query.isSuccess) {
        return (
            <List className='detect-list-scroll'>
                {query.data.map((item, idx) => (
                    <List.Item key={idx}>
                        <SelectCollectionCard
                            collectionSummary={item}
                            isSelected={selectedCollections.has(item.itemExternalId)}
                            ignoreClick={
                                itemType===ItemStatus.REJECTED ||
                                itemType===ItemStatus.REQUESTED
                            }
                            onCollectionSelect={onCollectionSelect}
                            onCollectionDeselect={onCollectionDeselect}
                        />
                    </List.Item>
                ))}
            </List>
        )
    } else if (query.isError) {
        sendNotification(notification, 'error', 'Can\'t fetch items', '')
        return (
            <div className='detect-full-center'>
                <Empty description={<span>Error Occurred. No Data</span>} />
            </div>
        )
    } else {
        return (
            <div className='detect-full-center'>
                <Spin tip='Loading' />
            </div>
        )
    }
}
export default CollectionSelectList
