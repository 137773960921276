import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
// import DetectChartCard from './DetectChartCard'
import {
	Avatar,
	Button,
	Empty,
	Modal,
	notification,
	Popconfirm,
	Space,
	Spin, Tooltip
} from 'antd'
import { useMutation, useQuery } from 'react-query'
import { getDetectGroupItem, getItemUserPermissions } from '../../../../lib/api/QueryApis/Common'
import ItemPermissions from '../../../Common/ItemPermissions'
import {
	deleteDetectGroupItem,
	deleteDetectItem,
} from '../../../../lib/api/MutationApis/Common'
import {
	AreaChartOutlined,
	DeleteOutlined,
	EditOutlined,
	ShareAltOutlined,
} from '@ant-design/icons'
import '../../index.css'
import {
	MLP_DETECT_CREATE,
	sendNotification,
} from '../../../../lib/utils/common'
import useScopes from '../../../../hooks/useScopes'
import NoMatch from '../../../Common/NoMatch'
import { DetectGroupItem } from '../../../../lib/api/RequestTypes/Common'
import { ItemMode } from '../../Collections/CollectionConfig'
import ProjectGraphComponent from './ProjectGraphComponent'
import ProjectConfig from '../ProjectConfig'
import { getUseQueryOptions } from '../../Utils'
import { ItemAccessLevel } from '../../../../types/DataPlatformTypes/CommonTypes'
import { CustomAvatarGroup } from '../../../Common/CustomAvatarGroup'

const ProjectPage = () => {
	const { itemExtId } = useParams<{ itemExtId: string }>()

	const [showItemPermissions, setShowItemPermissions] =
		useState<boolean>(false)
	const [showUpdateItem, setShowUpdateItem] = useState<boolean>(false)
	const [logModal, setLogModal] = useState([false, <span />, <span />])

	const history = useHistory()
	const userPermissions = useScopes()

	const permissionsQuery = useQuery(
		[{ itemExtId: itemExtId }],
		getItemUserPermissions,
		getUseQueryOptions(1)
	)

	const [deleteItemRequest] = useMutation(deleteDetectGroupItem, {
		onSuccess: () => {
			sendNotification(notification, 'success', `Item ${itemExtId} deleted`, '')
			history.push('/')
		},
		onError: () => {
			sendNotification(notification, 'error', `Failed to delete ${itemExtId}`, '')
		},
	})

	const itemQuery = useQuery(
		[
			{
				itemExtId: itemExtId,
				groupVersionId: 'latest',
			},
		],
		getDetectGroupItem,
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			staleTime: 1000 * 60,
			retry: 1,
		}
	)

	let updateItemComponent = <></>
	let projectGraphComponent = <></>

	if (itemQuery.isError) {
		updateItemComponent = (
			<div className="detect-full-width-center">
				<Empty />
			</div>
		)
	} else if (itemQuery.isLoading) {
		updateItemComponent = (
			<div className="detect-full-width-center">
				<Spin tip="loading"></Spin>
			</div>
		)
	} else if (itemQuery.isSuccess) {
		updateItemComponent = (
			<ProjectConfig
				itemConfig={itemQuery.data}
				itemExtId={itemExtId}
				mode={ItemMode.Update}
				callback={() => setShowUpdateItem(false)}
			/>
		)
	}

	projectGraphComponent = (
		<div className="detect-item-dashboard-card" style={{ width: '100%', height:'100%' }}>
			{itemQuery.isSuccess && (
				<ProjectGraphComponent
					collectionItemId={itemExtId}
					collectionItemInfo={itemQuery.data as DetectGroupItem}
				></ProjectGraphComponent>
			)}
		</div>
	)

	if (itemQuery.isError && itemQuery.error.message === 'Request Error') {
		return <NoMatch />
	} else if (itemQuery.isLoading) {
		return (
			<div
				className="detect-full-width-center"
				style={{ height: '100%' }}
			>
				<Spin tip="loading"></Spin>
			</div>
		)
	}

	return (
		<>
			<div className="detect-item-dashboard">
				<div
					className="detect-item-dashboard-card"
					style={{
						padding: '5px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					{itemQuery.isSuccess && (
						<span style={{ float: 'left' }}>
							<span>
								<AreaChartOutlined
									style={{
										color: '#722ED1',
										fontSize: '30px',
										marginLeft: '10px',
										marginRight: '5px',
									}}
								/>
							</span>
							<span className="detect-item-name">
								{itemQuery.data.itemName}{' '}
							</span>
						</span>
					)}
					{userPermissions?.data?.includes(MLP_DETECT_CREATE) && (
						<span style={{ float: 'right' }}>
							<Space>
								<CustomAvatarGroup
									permissions={null}
									itemExternalId={itemExtId}
									isGroupItem={true}
								/>
								<Button
									className="detect-button--primary"
									onClick={() => setShowItemPermissions(true)}
								>
									<ShareAltOutlined />
									Share
								</Button>
								<Button
									className="detect-button--primary"
									onClick={() => {
										setShowUpdateItem(true)
									}}
								>
									<EditOutlined /> Edit
								</Button>
								<Popconfirm
									title="Are you sure to delete this item?"
									onConfirm={() => {
										deleteItemRequest({
											itemExtId: itemExtId,
										})
									}}
									placement="bottom"
									okText="Yes"
									cancelText="No"
								>
									<Button
										className="detect-button--danger"
										danger
									>
										<DeleteOutlined /> Delete
									</Button>
								</Popconfirm>
							</Space>
						</span>
					)}
				</div>
				<div
					className="detect-item-dashboard-card"
					style={{
						height:'100%',
						padding: '5px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					{projectGraphComponent}
				</div>
			</div>

			<Modal
				title="Item Permissions"
				visible={showItemPermissions}
				width="fit-content"
				footer={null}
				bodyStyle={{ padding: '0px' }}
				onCancel={() => setShowItemPermissions(false)}
			>
				<ItemPermissions itemExtId={itemExtId} isGroupItem={true} />
			</Modal>

			<Modal
				title="Update Item"
				visible={showUpdateItem}
				footer={null}
				width="fit-content"
				onCancel={() => setShowUpdateItem(false)}
				bodyStyle={{ padding: '0px' }}
			>
				{updateItemComponent}
			</Modal>
			<Modal
				onOk={() => setLogModal([false, <span />, <span />])}
				visible={logModal[0] as boolean}
				cancelButtonProps={{ style: { display: 'none' } }}
				onCancel={() => setLogModal([false, <span />, <span />])}
				title={logModal[1]}
			>
				{logModal[2]}
			</Modal>
		</>
	)
}

export default ProjectPage
