import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import KPIChartCard from './KPIChartCard'
import {
    Button,
    Col,
    Empty,
    Form,
    InputNumber,
    List,
    Modal,
    notification,
    Pagination,
    Popconfirm,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    Table,
    Tabs,
    Tag,
    Tooltip,
    Typography,
} from 'antd'
import { useMutation, useQuery } from 'react-query'
import {
    checkPendingRequests,
    getDataSourceQuery,
    getDataSourceSummary,
    getDetectExecutionLog,
    getDetectExecutionLogCount,
    getDetectItem,
    getDetectResult,
    getUserDetectGroupItems
} from '../../../../lib/api/QueryApis/Common'
import ItemPermissions from '../../../Common/ItemPermissions'
import KPIConfig, { ItemMode } from '../KPIConfig'
import {
    addKPIToCollection,
    deleteDetectItem,
} from '../../../../lib/api/MutationApis/Common'
import {
    AreaChartOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleFilled,
    InfoCircleFilled,
    RightOutlined,
    ShareAltOutlined,
} from '@ant-design/icons'
import {
    configParametersList,
    getFormattedDate,
    getRunStatusTag,
    getUseQueryOptions,
    granularityString,
    humanize,
    humanizeCamelCase,
    wrapString,
} from '../../Utils'
import StyledDivider from '../../../Common/StyledDivider'
import '../../index.css'
import {
    ItemStatus,
    MLP_DETECT_CREATE,
    sendNotification,
} from '../../../../lib/utils/common'
import useScopes from '../../../../hooks/useScopes'
import NoMatch from '../../../Common/NoMatch'
import { ColumnsType } from 'antd/es/table'
import {
    DataSourceQuery,
    DetectExecutionLog,
    ExecutionStatus,
} from '../../../../lib/api/ResponseTypes/Common'
import {
    ComputeType,
    DataSourceType,
    DetectAlgorithmInput,
    ItemType,
    OrderBy,
    OrderType,
} from '../../../../lib/api/RequestTypes/Common'
import { CustomAvatarGroup } from '../../../Common/CustomAvatarGroup'
import ResourceDependencyGraph from './ResourceDependenciesGraph'
import PromQLChart, { FullPromQLChart } from '../../../Common/PromQLChart'
import type { TabsProps } from 'antd';
import TracerScreen from './Tracer/TracerScreen'
import { KPIHealthTag } from '../../../Common/KPIHealthTag'
import { Plot, PlotEvent } from '@ant-design/charts'
import { LineOptions } from '@antv/g2plot';
import dayjs from "dayjs"

const { Text } = Typography
const { Option } = Select
const { TabPane } = Tabs

interface AddToCollectionProps {
    itemExtId: string
    callback: Function
}

const AddToCollection = ({ itemExtId, callback }: AddToCollectionProps) => {
    const [form] = Form.useForm()
    const groupId = Form.useWatch('groupId', form)
    const parents = Form.useWatch('parents', form)
    const children = Form.useWatch('children', form)
    const [usableKPIs, setUsableKPIs] = useState<any[]>([])
    const [collections, setCollections] = useState<any[]>([])
    const [kpis, setKPIs] = useState<any[]>([])

    const collectionQuery = useQuery(
        [
            {
                configStatus: ItemStatus.APPROVED,
                filterText: '',
                orderBy: OrderBy.itemName,
                orderType: OrderType.ASC,
                limit: 100,
                offset: 0,
                showPublic: true,
                groupType: ItemType.COLLECTION,
            },
        ],
        getUserDetectGroupItems,
        getUseQueryOptions(2)
    )
    useEffect(() => {
        if (collectionQuery.isSuccess) {
            setCollections(
                collectionQuery.data.map((it) => {
                    return { label: it.itemName, value: it.itemExternalId }
                })
            )
        }
    }, [collectionQuery.isSuccess])

    useEffect(() => {
        setUsableKPIs(
            kpis.filter((it: any) => {
                return (
                    (children !== undefined
                        ? !children.includes(it.value)
                        : true) &&
                    (parents !== undefined
                        ? !parents.includes(it.value)
                        : true) &&
                    it.value !== itemExtId
                )
            })
        )
    }, [children, parents, kpis])

    useEffect(() => {
        if (collectionQuery.isSuccess) {
            let collection = collectionQuery.data.filter((it) => {
                return it.itemExternalId === groupId
            })[0]

            if (itemExtId in collection.graph) {
                form.setFieldsValue({
                    parents: collection.graph[itemExtId].parents,
                })
                form.setFieldsValue({
                    children: collection.graph[itemExtId].children,
                })
            }

            setKPIs(
                collection.enclosingGroupNames!!.map((it) => {
                    return { value: it.id, label: it.name }
                })
            )
        }
    }, [groupId])

    const [addKPIToCollectionRequest] = useMutation(addKPIToCollection, {
        onSuccess: () => {
            sendNotification(
                notification,
                'success',
                `Added Item To Collection`,
                ''
            )
            callback()
        },
        onError: () => {
            sendNotification(
                notification,
                'error',
                `Failed Added Item To Collection`,
                ''
            )
        },
    })

    const onFinish = (values: any) => {
        addKPIToCollectionRequest({ ...values, kpiId: itemExtId })
    }

    return (
        <div>
            <Form
                initialValues={{
                    parents: [],
                    children: [],
                }}
                layout="vertical"
                form={form}
                style={{ minWidth: '23vw' }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="groupId"
                    label="Collection"
                    rules={[{ required: true }]}
                >
                    <Select
                        loading={collectionQuery.isLoading}
                        options={collections}
                    />
                </Form.Item>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item name="parents" label="Parents">
                            <Select
                                disabled={groupId === undefined}
                                mode="multiple"
                                options={usableKPIs}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="children" label="Children">
                            <Select
                                disabled={groupId === undefined}
                                mode="multiple"
                                options={usableKPIs}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Button type="primary" htmlType="submit">
                    Done
                </Button>
            </Form>
        </div>
    )
}

const KPIPage = () => {
    const { itemExtId } = useParams<{ itemExtId: string }>()
    const [latestAnomalyId, setLatestAnomalyId] = useState<string | null>(null)
    const [anomalyId, setAnomalyId] = useState<string | null>(null)
    const [dataSourceType, setDataSourceType] = useState(DataSourceType.DATA_PLATFORM)
    const [promQLString, setPromQLString] = useState<string[] | null>(null)
    const [mainDimen, setMainDimen] = useState('')
    const [drilldownDimen, setDrilldownDimen] = useState('')

    const [showItemPermissions, setShowItemPermissions] =
        useState<boolean>(false)
    const [showAddToCollection, setShowAddToCollection] =
        useState<boolean>(false)
    const [showUpdateItem, setShowUpdateItem] = useState<boolean>(false)
    const [logModal, setLogModal] = useState([false, <span />, <span />])
    const [historyCount, setHistoryCount] = useState(10)
    const [historyLimit, setHistoryLimit] = useState(10)
    const [historyOffset, setHistoryOffset] = useState(0)
    const [dataSourceQueryInfo, setDataSourceQueryInfo] =
        useState<DataSourceQuery | null>(null)
    const [range, setRange] = useState(60 * 60)
    const [step, setStep] = useState(15)
    const [isAutoRefreshOn, setIsAutoRefreshOn] = useState(false)
    const today = dayjs()
    const now = today.format("YYYY-MM-DD HH:mm")
    const nowMinus15min = today.subtract(15,'minute').format("YYYY-MM-DD HH:mm")
    const chartRangeRef = useRef<{start: string, end: string}>({start:nowMinus15min, end: now })

    const history = useHistory()
    const userPermissions = useScopes()


    const [deleteItemRequest] = useMutation(deleteDetectItem, {
        onSuccess: () => {
            sendNotification(
                notification,
                'success',
                `Item ${itemExtId} deleted`,
                ''
            )
            history.push('/kpi')
        },
        onError: () => {
            sendNotification(
                notification,
                'error',
                `Failed to delete ${itemExtId}`,
                ''
            )
        },
    })

    const [dataSourceQueryRequest] = useMutation(getDataSourceQuery, {
        onSuccess: (resp) => {
            setDataSourceQueryInfo(resp)
        },
        onError: (e) => {
            console.log('Unable to fetch metrics for a data source', e)
        },
    })

    const itemQuery = useQuery(
        [
            {
                itemExtId: itemExtId,
                configId: null,
            },
        ],
        getDetectItem,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: 1000 * 60,
            retry: 1,
        }
    )

    useEffect(() => {
        if (itemQuery.isSuccess) {
            setDataSourceType(itemQuery.data.dataSourceType!!)
            if (itemQuery.data.dataSourceType===DataSourceType.PROMETHEUS) {
                setPromQLString(itemQuery.data.configParameters.get('promQLQuery'))
            }
        }
    }, [itemQuery])

    const dataSourceQuery = useQuery(
        [
            {
                itemExtId: itemExtId,
                configId: null,
                k: 'dataSourceQuery',
            },
        ],
        getDataSourceSummary,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: 1,
        }
    )

    const dataQuery = useQuery(
        [{ itemExtId: itemExtId!!, anomalyId: anomalyId }],
        getDetectResult,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: 1000 * 60,
            retry: 1,
        }
    )

    useEffect(() => {
        if (dataQuery.isSuccess) {
            let keys = Object.keys(dataQuery.data.drilldown)
            if ('All' in keys) {
                setMainDimen('All')
            } else {
                setMainDimen(keys[0])
            }
            setDrilldownDimen(dataQuery.data.categoricalDimensionColumns[0])
        }
    }, [dataQuery.data])

    const executionLogQuery = useQuery(
        [
            {
                item_external_id: itemExtId,
                limit: historyLimit,
                offset: historyOffset,
            },
        ],
        getDetectExecutionLog,
        getUseQueryOptions(1)
    )

    useEffect(() => {
        if (
            dataSourceQuery.isSuccess &&
            dataSourceQuery.data.dataSourceType == DataSourceType.PROMETHEUS
        ) {
            const queryId =
                dataSourceQuery.data.dataSourceParameters['query_id']
            if (!queryId || queryId == '') {
                sendNotification(
                    notification,
                    'warning',
                    `Query ID not found for ${itemExtId}. Please reach out to Netra Admins`,
                    ''
                )
            }

            dataSourceQueryRequest({
                dataSourceType: dataSourceQuery.data.dataSourceType,
                queryId: dataSourceQuery.data.dataSourceParameters['query_id'],
            })
        }
    }, [dataSourceQuery.isSuccess])

    useEffect(() => {
        if (executionLogQuery.isSuccess) {
            let validLogs = executionLogQuery.data.filter(
                (it) =>
                    it.executionStatus === ExecutionStatus.RUN_SUCCESSFUL &&
                    it.anomalyId !== null
            )
            if (validLogs.length > 0) {
                setLatestAnomalyId(validLogs[0].anomalyId)
            }
        }
    }, [executionLogQuery])

    const executionLogCountQuery = useQuery(
        [
            {
                endpoint: 'logs-count',
                item_external_id: itemExtId,
            },
        ],
        getDetectExecutionLogCount,
        getUseQueryOptions(1)
    )

    useEffect(() => {
        if (executionLogCountQuery.isSuccess) {
            setHistoryCount(executionLogCountQuery.data.count)
        }
    }, [executionLogCountQuery])

    const checkContainsFields = (
        dict: { [key: string]: any },
        checkFields: string[]
    ): boolean => {
        let checkContainsFields = true
        const keys = Object.keys(dict)
        checkFields.forEach((fieldName: string) => {
            if (!keys.includes(fieldName)) {
                checkContainsFields = false
                return false
            }
        })
        return checkContainsFields
    }

    let updateItemComponent = <></>
    let resultComponent = <></>
    let mainChartComponent = <></>
    let overviewComponent = <></>

    let pendingRequestsWarning = <span></span>
    const pendingRequestsQuery = useQuery(
        [
            {
                item_external_id: itemExtId,
            },
        ],
        checkPendingRequests,
        getUseQueryOptions(1)
    )

    if (
        pendingRequestsQuery.isSuccess &&
        pendingRequestsQuery.data.pendingRequests
    ) {
        pendingRequestsWarning = (
            <span style={{ height: '100%', justifyItems: 'center' }}>
                <Space />
                <Tag
                    style={{
                        margin: '0px',
                        fontSize: '10pt',
                        padding: '5px 10px 5px 10px',
                    }}
                    color="orange"
                >
                    <span style={{ color: 'black' }}>
                        Latest configuration has not been approved yet
                    </span>
                </Tag>
            </span>
        )
    }

    if (itemQuery.isError) {
        updateItemComponent = (
            <div className="detect-full-width-center">
                <Empty />
            </div>
        )
    } else if (itemQuery.isLoading) {
        updateItemComponent = (
            <div className="detect-full-width-center">
                <Spin tip="loading"></Spin>
            </div>
        )
    } else if (itemQuery.isSuccess) {
        updateItemComponent = (
            <KPIConfig
                itemConfig={itemQuery.data}
                itemExtId={itemExtId}
                mode={ItemMode.Update}
                callback={() => {
                    //window.location.reload();
                    setShowUpdateItem(false)
                }}
            />
        )
    }

    const anomalyDefinitionColumns: ColumnsType<DetectAlgorithmInput> = [
        {
            title: 'Algorithm',
            dataIndex: 'algorithm',
            key: 'algorithm',
            render: (dt) => dt,
            fixed: 'left',
        },
        Table.EXPAND_COLUMN,
        {
            title: 'Mode',
            dataIndex: 'anomalyMode',
            key: 'anomalyMode',
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
        },
        {
            title: 'ID',
            dataIndex: 'configAlgorithmId',
            key: 'configAlgorithmId',
            render: (dt) => dt.substring(0, 5),
        },
        {
            title: 'Squadcast Slug',
            dataIndex: 'squadcastSlug',
            key: 'squadcastSlug',
        },
    ]

    const runLogColumns: ColumnsType<DetectExecutionLog> = [
        {
            title: 'Run Id',
            dataIndex: 'runId',
            key: 'runId',
            width: '25%',
            render: (dt) => dt.substring(0, 5),
        },
        {
            title: 'Time',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '35%',
            render: (dt) => getFormattedDate(dt / 1000, false),
        },
        {
            title: 'Status',
            dataIndex: 'executionStatus',
            key: 'executionStatus',
            width: '30%',
            render: (executionStatus) => getRunStatusTag(executionStatus),
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (_, log) => (
                <RightOutlined
                    onClick={() => {
                        switch (log.executionStatus) {
                            case ExecutionStatus.RUN_FAILURE: {
                                setLogModal([
                                    true,
                                    <span>
                                        <ExclamationCircleFilled
                                            style={{ color: 'red' }}
                                        />{' '}
                                        Run Failed
                                    </span>,
                                    <span>
                                        Error Message:
                                        <br />
                                        <Text code> {log.errorMessage}</Text>
                                    </span>,
                                ])
                                break
                            }
                            case ExecutionStatus.RUN_SUBMIT_FAILED: {
                                setLogModal([
                                    true,
                                    <span>
                                        <ExclamationCircleFilled
                                            style={{ color: 'red' }}
                                        />{' '}
                                        Run Failed
                                    </span>,
                                    <span>Failed to trigger a run</span>,
                                ])
                                break
                            }
                            case ExecutionStatus.RUN_SUCCESSFUL: {
                                if (
                                    log.anomalyId === null ||
                                    log.anomalyId === ''
                                ) {
                                    setLogModal([
                                        true,
                                        <span>
                                            <ExclamationCircleFilled
                                                style={{ color: 'red' }}
                                            />{' '}
                                            Results Not Found
                                        </span>,
                                        <span>
                                            Results for this run were not found
                                        </span>,
                                    ])
                                } else {
                                    setAnomalyId(log.anomalyId)
                                }
                                break
                            }
                            default: {
                            }
                        }
                    }}
                />
            ),
        },
    ]

    overviewComponent = (
        <div className="detect-item-dashboard-card" style={{ width: '350px' }}>
            <Tabs size="small">
                <TabPane tab="Overview" key={0}>
                    <ul>
                        {itemQuery.isSuccess && (
                            <>
                                <StyledDivider dividerText="Item Details" />
                                <li>
                                    <span className="detect-card-footer__key">
                                        Item Id:
                                    </span>
                                    <span className="detect-card-footer__value">
                                        {' '}
                                        {itemQuery.data.itemExternalId}
                                    </span>
                                </li>
                                <li>
                                    <span className="detect-card-footer__key">
                                        Data Source:
                                    </span>
                                    <span className="detect-card-footer__value">
                                        {' '}
                                        {humanize(
                                            String(
                                                itemQuery.data.dataSourceType
                                            )
                                        )}
                                    </span>
                                </li>
                                {itemQuery.data?.computeType ===
                                    ComputeType.NATIVE &&
                                    Array.from(itemQuery.data.configParameters)
                                        .filter((value: any) => {
                                            return configParametersList(
                                                itemQuery.data
                                                    .dataSourceType as DataSourceType
                                            ).includes(value[0])
                                        })
                                        .map((value: any) => {
                                            return (
                                                <li key={value[0]}>
                                                    <span className="detect-card-footer__key">
                                                        {humanizeCamelCase(
                                                            value[0]
                                                        )}
                                                        :{' '}
                                                    </span>
                                                    <span className="detect-card-footer__value">
                                                        {' '}
                                                        {String(value[1])}
                                                    </span>
                                                </li>
                                            )
                                        })}
                                <li>
                                    <span className="detect-card-footer__key">
                                        Area:
                                    </span>
                                    <span className="detect-card-footer__value">
                                        {' '}
                                        {itemQuery.data.problemAreaName}
                                    </span>
                                </li>
                                {itemQuery.data?.computeType !==
                                    ComputeType.NATIVE && (
                                    <>
                                        <StyledDivider dividerText="Data Details" />
                                        {itemQuery.data.dataSourceItemPath && (
                                            <li>
                                                <span className="detect-card-footer__key">
                                                    Source:{' '}
                                                </span>
                                                <span
                                                    title={
                                                        itemQuery.data
                                                            .dataSourceItemPath!!
                                                    }
                                                    className="detect-card-footer__value"
                                                >
                                                    {wrapString(
                                                        itemQuery.data
                                                            .dataSourceItemPath!!,
                                                        30
                                                    )}
                                                </span>
                                            </li>
                                        )}
                                        {itemQuery.data.granularity && (
                                            <li>
                                                <span className="detect-card-footer__key">
                                                    Granularity:
                                                </span>
                                                <span className="detect-card-footer__value">
                                                    {' '}
                                                    {granularityString(
                                                        itemQuery.data
                                                            .granularity!!
                                                    )}
                                                </span>
                                            </li>
                                        )}

                                        {itemQuery.data.measureColumn && (
                                            <li>
                                                <span className="detect-card-footer__key">
                                                    Measure Col:
                                                </span>
                                                <span className="detect-card-footer__value">
                                                    {' '}
                                                    {
                                                        itemQuery.data
                                                            .measureColumn
                                                    }
                                                </span>
                                            </li>
                                        )}

                                        {itemQuery.data.mainDimensionColumn && (
                                            <li>
                                                <span className="detect-card-footer__key">
                                                    Main Dimension:
                                                </span>
                                                <span className="detect-card-footer__value">
                                                    {' '}
                                                    {
                                                        itemQuery.data
                                                            .mainDimensionColumn
                                                    }
                                                </span>
                                            </li>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {dataSourceQuery.isSuccess &&
                            dataSourceQuery.data.dataSourceType ==
                                DataSourceType.PROMETHEUS && (
                                <>
                                    <StyledDivider dividerText="Metric Details" />
                                    {dataSourceQueryInfo && (
                                        <li>
                                            <span className="detect-card-footer__key">
                                                Metric:
                                            </span>
                                            <span className="detect-card-footer__value">
                                                {' '}
                                                {
                                                    dataSourceQueryInfo?.queryFullName
                                                }
                                            </span>
                                        </li>
                                    )}
                                    {Object.keys(
                                        dataSourceQuery.data
                                            .dataSourceParameters
                                    )
                                        .filter(
                                            (value: string) =>
                                                value != 'query_id'
                                        )
                                        .map((fieldName: string) => (
                                            <>
                                                <li>
                                                    <span className="detect-card-footer__key">
                                                        {humanize(fieldName)}:
                                                    </span>
                                                    <span className="detect-card-footer__value">
                                                        {' '}
                                                        {
                                                            dataSourceQuery.data
                                                                .dataSourceParameters[
                                                                fieldName
                                                            ]
                                                        }
                                                    </span>
                                                </li>
                                            </>
                                        ))}
                                </>
                            )}
                        {dataQuery.isSuccess && (
                            <>
                                <StyledDivider dividerText="Result Details" />
                                <li>
                                    <span className="detect-card-footer__key">
                                        {' '}
                                        Computed at:
                                    </span>
                                    <span className="detect-card-footer__value">
                                        {' '}
                                        <Text strong>
                                            {' '}
                                            {getFormattedDate(
                                                dataQuery.data.createdAt / 1000,
                                                false
                                            )}
                                        </Text>
                                    </span>
                                </li>
                                <li>
                                    <span className="detect-card-footer__key">
                                        {' '}
                                        Time Period:
                                    </span>
                                    <span className="detect-card-footer__value">
                                        <Text strong>
                                            {' '}
                                            {getFormattedDate(
                                                dataQuery.data.summary.startDate
                                            )}{' '}
                                            to
                                            {' ' +
                                                getFormattedDate(
                                                    dataQuery.data.summary
                                                        .endDate
                                                )}
                                        </Text>
                                    </span>
                                </li>
                            </>
                        )}
                    </ul>
                </TabPane>
                {itemQuery.data?.computeType !== ComputeType.NATIVE && (
                    <TabPane tab="Run Logs" key={1}>
                        <Table
                            className="detect-run-log-table"
                            style={{ marginTop: '10px' }}
                            size={'small'}
                            pagination={false}
                            scroll={{ y: 270 }}
                            loading={executionLogQuery.isLoading}
                            dataSource={executionLogQuery.data}
                            columns={runLogColumns}
                        />
                        <Pagination
                            size="small"
                            simple
                            defaultCurrent={1}
                            total={historyCount}
                            onChange={(page, pageSize) => {
                                setHistoryLimit(pageSize)
                                setHistoryOffset((page - 1) * pageSize)
                            }}
                        />
                    </TabPane>
                )}
                <TabPane tab="Anomaly Definitions" key={2}>
                    <Table
                        className="detect-anomaly-definition-table"
                        style={{ marginTop: '10px' }}
                        size={'small'}
                        pagination={false}
                        scroll={{ y: 270 }}
                        loading={itemQuery.isLoading}
                        columns={anomalyDefinitionColumns}
                        dataSource={itemQuery.data?.algorithms}
                        expandable={{
                            expandedRowRender: (anomaly) => (
                                <p style={{ margin: 0 }}>
                                    <ul style={{ display: 'inline-block' }}>
                                        {Object.entries(
                                            anomaly.hyperParameters
                                        ).map((value: any, i: number) => (
                                            <li
                                                style={{
                                                    display: 'inline-block',
                                                }}
                                                key={i}
                                            >
                                                &nbsp;&nbsp;&nbsp;&nbsp;•
                                                {value[0]}: {String(value[1])}
                                            </li>
                                        ))}
                                    </ul>
                                </p>
                            ),
                            rowExpandable: (anomaly) => anomaly.hyperParameters,
                        }}
                    />
                </TabPane>
            </Tabs>
        </div>
    )

    if (dataSourceType === DataSourceType.DATA_PLATFORM && dataQuery.isError) {
        mainChartComponent = (
            <div className="detect-full-width-center">
                <Empty />
            </div>
        )
    } else if (dataQuery.isSuccess && mainDimen !== '') {
        let drilldownComponent: JSX.Element
        //let covariateComponent: JSX.Element
        let data = dataQuery.data!!.drilldown!!
        let item = dataQuery.data!!

        if (item.categoricalDimensionColumns.length > 0) {
            let currDrilldownCol = drilldownDimen
            if (drilldownDimen === '') {
                currDrilldownCol = item.categoricalDimensionColumns[0]
            }

            drilldownComponent = (
                <div>
                    <Tabs
                        style={{ width: '100%', paddingLeft: '20px' }}
                        type="line"
                        onTabClick={(key: string) => {
                            setDrilldownDimen(key)
                        }}
                        defaultActiveKey="1"
                        tabPosition="top"
                    >
                        {item.categoricalDimensionColumns.map(
                            (dimen: string) => (
                                <TabPane tab={dimen.trim()} key={dimen} />
                            )
                        )}
                    </Tabs>
                    `
                    <List className="detect-drilldown-graphs">
                        {Object.keys(
                            data[mainDimen].drilldown[currDrilldownCol]
                        ).map((dimenValue, idx) => {
                            return (
                                <List.Item key={idx}>
                                    <KPIChartCard
                                        height="150px"
                                        title={
                                            <span>
                                                <Text strong>
                                                    {drilldownDimen}
                                                </Text>{' '}
                                                = <Text code>{dimenValue}</Text>
                                            </span>
                                        }
                                        filepath={
                                            data[mainDimen].drilldown[
                                                currDrilldownCol
                                            ][dimenValue]
                                        }
                                        tsCol={item.timestampColumn}
                                        measureCol={item.measureColumn}
                                        granularity={item.granularity}
                                        algorithms={item.algorithms}
                                    />
                                </List.Item>
                            )
                        })}
                    </List>
                </div>
            )
        } else {
            drilldownComponent = (
                <div className="detect-center-min-height">
                    <Empty style={{ margin: 'auto' }} />
                </div>
            )
        }

        resultComponent = (
            <div className="detect-item-dashboard-card">
                {drilldownComponent}
            </div>
        )

        mainChartComponent = (
            <div className="detect-item-dashboard-card">
                {item.mainDimensionColumn && (
                    <Space>
                        <span>
                            Drilldown by{' '}
                            <Text code>{item.mainDimensionColumn}</Text>:
                        </span>
                        <Select
                            showSearch
                            style={{ width: 'fit-content', minWidth: '150px' }}
                            value={mainDimen}
                            onChange={(value) => setMainDimen(value)}
                        >
                            {Object.keys(data).map((value) => {
                                return (
                                    <Option value={value} key={value}>
                                        {value}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Space>
                )}
                <KPIChartCard
                    height="250px"
                    title={
                        <span style={{ fontSize: '14pt' }}>
                            <Text strong>{item.mainDimensionColumn}</Text> ={' '}
                            <Text code>{mainDimen}</Text>
                        </span>
                    }
                    statsOnRight={true}
                    tsCol={item.timestampColumn}
                    measureCol={item.measureColumn}
                    filepath={data[mainDimen].main}
                    granularity={item.granularity}
                    algorithms={item.algorithms}
                />
            </div>
        )
    } else if (dataSourceType===DataSourceType.DATA_PLATFORM && dataQuery.isLoading){
        mainChartComponent = (
            <div className="detect-full-width-center">
                <Spin tip="loading"></Spin>
            </div>
        )
    }
    else if (dataSourceType===DataSourceType.PROMETHEUS ){
        const handleOnClickEventOnSlider = (chart: Plot<LineOptions>) => {
            chart.on("slider:click", (evt: PlotEvent) => {
                const data = evt.view["filteredData"]
                const dateRangeStart = data[0]["date"]
                const dateRangeEnd = data[data.length - 1]["date"]
                chartRangeRef.current = {start: dateRangeStart,  end: dateRangeEnd}
                let event = new CustomEvent("chart-slider-moved", { "detail": {start: dateRangeStart,  end: dateRangeEnd} })
                document.dispatchEvent(event)
            });
        }
        mainChartComponent = (
            <div style={{padding:'0 10px',width:'100%',height:'100%',backgroundColor:'white'}}>
                <FullPromQLChart
                    query={promQLString}
                    autoRefresh={isAutoRefreshOn}
                    range={range}
                    step={step}
                    onReady={handleOnClickEventOnSlider}
                />
            </div>
        )
    }


    if (itemQuery.isError && itemQuery.error.message === 'Request Error') {
        return <NoMatch />
    } else if (itemQuery.isLoading) {
        return (
            <div
                className="detect-full-width-center"
                style={{ height: '100%' }}
            >
                <Spin tip="loading"></Spin>
            </div>
        )
    }

    let oldResultsWaring = <span></span>
    if (dataQuery.isSuccess && executionLogQuery.isSuccess) {
        if (
            latestAnomalyId !== null &&
            dataQuery.data.anomalyId !== latestAnomalyId
        ) {
            oldResultsWaring = (
                <span style={{ height: '100%', justifyItems: 'center' }}>
                    <Space />
                    <Tag
                        style={{
                            margin: '0px',
                            fontSize: '10pt',
                            padding: '5px 10px 5px 10px',
                        }}
                        color="orange"
                    >
                        <span style={{ color: 'black' }}>
                            You are viewing an old run
                        </span>
                        <a
                            style={{ color: 'orange', paddingLeft: '50px' }}
                            onClick={() => setAnomalyId(latestAnomalyId)}
                        >
                            View latest run
                        </a>
                    </Tag>
                </span>
            )
        }
    }

    // if (itemQuery.data?.dataSourceType === DataSourceType.PROMETHEUS) {
    // 	return <div style={{ textAlign: 'center' }}>Under Construction</div>
    // }

    return (
        <div>
            <div className="detect-item-dashboard">
                <div
                    className="detect-item-dashboard-card"
                    style={{
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    {itemQuery.isSuccess && (
                        <span style={{ float: 'left' }}>
                            <Space>
                                <span>
                                    <AreaChartOutlined
                                        style={{
                                            color: '#722ED1',
                                            fontSize: '30px',
                                            marginLeft: '10px',
                                        }}
                                    />
                                </span>
                                <span className="detect-item-name">
                                    {itemQuery.data.itemName}{' '}
                                </span>
                                <Tooltip
                                    title={overviewComponent}
                                    trigger={['click']}
                                    placement="bottom"
                                    color="white"
                                    style={{
                                        minWidth: '400px',
                                        minHeight: '240px',
                                        cursor: 'pointer',
                                        padding: 0,
                                    }}
                                >
                                    <InfoCircleFilled />
                                </Tooltip>
                                <CustomAvatarGroup
                                    permissions={null}
                                    itemExternalId={itemExtId}
                                    isGroupItem={false}
                                />
                                {itemQuery.data.dataSourceType == DataSourceType.PROMETHEUS &&
                                <KPIHealthTag itemExtId={itemQuery.data.itemExternalId} dataSourceType={itemQuery.data.dataSourceType!} />}
                                <span>
                                    Time Span:
                                    <Select
                                        defaultValue={60*60}
                                        style={{ marginLeft: '10px' }}
                                        onChange={(value, option) => {
                                            setRange(value)
                                        }}
                                        options={[
                                            { value: 1, label: '1s' },
                                            { value: 5, label: '5s' },
                                            { value: 30, label: '30s' },
                                            { value: 60, label: '1m' },
                                            { value: 5 * 60, label: '5m' },
                                            { value: 15 * 60, label: '15m' },
                                            { value: 30 * 60, label: '30m' },
                                            { value: 60 * 60, label: '1h' },
                                            { value: 2 * 60 * 60, label: '2h' },
                                            { value: 6 * 60 * 60, label: '6h' },
                                            { value: 12 * 60 * 60, label: '12h' },
                                            { value: 24 * 60 * 60, label: '1d' },
                                            { value: 2 * 24 * 60 * 60, label: '2d' },
                                            { value: 7 * 24 * 60 * 60, label: '1w' }
                                        ]}
                                    />
                                </span>
                                <span>
                                    Resolution (In Seconds):
                                    <InputNumber
                                        style={{ marginLeft: '10px' }}
                                        value={step}
                                        defaultValue={15}
                                        onChange={(value) => {
                                            setStep(value)
                                        }} />
                                </span>
                                <span>
                                    Auto Refresh {isAutoRefreshOn ? "On ": "Off "}
                                    <Switch checked={isAutoRefreshOn} onChange={() => setIsAutoRefreshOn(!isAutoRefreshOn)}/>
                                </span>
                            </Space>
                        </span>
                    )}
                    {oldResultsWaring}
                    {pendingRequestsWarning}
                    {userPermissions?.data?.includes(MLP_DETECT_CREATE) && (
                        <span style={{ float: 'right' }}>
                            <Space>
                                <Button
                                    className="detect-button--primary"
                                    onClick={() => setShowAddToCollection(true)}
                                >
                                    <ShareAltOutlined />
                                    Add to Collection
                                </Button>

                                <Button
                                    className="detect-button--primary"
                                    onClick={() => setShowItemPermissions(true)}
                                >
                                    <ShareAltOutlined />
                                    Share
                                </Button>
                                <Button
                                    className="detect-button--primary"
                                    onClick={() => {
                                        setShowUpdateItem(true)
                                    }}
                                >
                                    <EditOutlined /> Edit
                                </Button>
                                <Popconfirm
                                    title="Are you sure to delete this item?"
                                    onConfirm={() => {
                                        deleteItemRequest({
                                            itemExtId: itemExtId,
                                        })
                                    }}
                                    placement="bottom"
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        className="detect-button--danger"
                                        danger
                                    >
                                        <DeleteOutlined /> Delete
                                    </Button>
                                </Popconfirm>
                            </Space>
                        </span>
                    )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {mainChartComponent}
                    </div>
                <div>
                    {resultComponent}
                    {itemQuery.data?.dataSourceType === DataSourceType.PROMETHEUS && dataSourceQuery.isSuccess &&
                        checkContainsFields(
                            dataSourceQuery.data.dataSourceParameters,
                            ['cluster', 'namespace', 'service_name']
                        ) && (
                            <Tabs defaultActiveKey='1'>
                                <TabPane
                                    tab='Tracer'
                                    key='1'
                                >
                                    <TracerScreen
                                        cluster={
                                            dataSourceQuery.data.dataSourceParameters[
                                                'cluster'
                                            ]
                                        }
                                        namespace={
                                            dataSourceQuery.data.dataSourceParameters[
                                                'namespace'
                                            ]
                                        }
                                        serviceName={
                                            dataSourceQuery.data.dataSourceParameters[
                                                'service_name'
                                            ]
                                        }
                                        resource={
                                            dataSourceQuery.data.dataSourceParameters[
                                                'resource_name'
                                            ]
                                        }
                                        chartRangeRef={chartRangeRef}
                                    />
                                </TabPane>
                                <TabPane
                                    tab='Graph'
                                    key='2'
                                >
                                    <ResourceDependencyGraph
                                        cluster={
                                            dataSourceQuery.data.dataSourceParameters[
                                                'cluster'
                                            ]
                                        }
                                        namespace={
                                            dataSourceQuery.data.dataSourceParameters[
                                                'namespace'
                                            ]
                                        }
                                        serviceName={
                                            dataSourceQuery.data.dataSourceParameters[
                                                'service_name'
                                            ]
                                        }
                                        resourceName={
                                            dataSourceQuery.data.dataSourceParameters[
                                                'resource_name'
                                            ]
                                        }
                                    />
                                </TabPane>
                            </Tabs>
                    )}
                </div>

            </div>

            <Modal
                title="Add To Collection"
                visible={showAddToCollection}
                width="fit-content"
                footer={null}
                onCancel={() => setShowAddToCollection(false)}
            >
                <AddToCollection
                    callback={() => {
                        setShowAddToCollection(false)
                    }}
                    itemExtId={itemExtId}
                />
            </Modal>

            <Modal
                title="Item Permissions"
                visible={showItemPermissions}
                width="fit-content"
                footer={null}
                bodyStyle={{ padding: '0px' }}
                onCancel={() => setShowItemPermissions(false)}
            >
                <ItemPermissions itemExtId={itemExtId} isGroupItem={false} />
            </Modal>

            <Modal
                title="Update Item"
                visible={showUpdateItem}
                footer={null}
                width="fit-content"
                onCancel={() => setShowUpdateItem(false)}
                bodyStyle={{ padding: '0px' }}
            >
                {updateItemComponent}
            </Modal>
            <Modal
                onOk={() => setLogModal([false, <span />, <span />])}
                visible={logModal[0] as boolean}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={() => setLogModal([false, <span />, <span />])}
                title={logModal[1]}
            >
                {logModal[2]}
            </Modal>
        </div>
    )
}

export default KPIPage
