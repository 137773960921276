import React, { useEffect, useState } from 'react'
import { Card, Divider, Row, Tag, Tooltip, Typography } from 'antd'
import { useHistory } from 'react-router'
import {
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    WarningOutlined,
} from '@ant-design/icons'
import {
    DetectGroupItemSummary,
    ProjectHealthResponse,
} from '../../../../lib/api/ResponseTypes/Common'
import { ItemStatus } from '../../../../lib/utils/common'
import { CustomAvatarGroup } from '../../../Common/CustomAvatarGroup'
import { getProjectHealth } from '../../../../lib/api/QueryApis/Common'
import { useMutation } from 'react-query'
import { SeverityLevel } from '../../../../lib/api/RequestTypes/Common'

const { Text, Title } = Typography

interface AlertTableProp {
    data: ProjectHealthResponse
}

export const AlertTable = ({ data }: AlertTableProp) => {
    return (
        <>
            <div style={{ color: 'black', backgroundColor: 'white' }}>
                <Text strong>Non-Functional - </Text>
                Critical:{' '}
                <Text code>{data.criticalNonFunctionalRulesCount}</Text> |
                Warning: <Text code>{data.warningNonFunctionalRulesCount}</Text>{' '}
                | Info: <Text code>{data.infoNonFunctionalRulesCount}</Text>
            </div>
            <div style={{ color: 'black', backgroundColor: 'white' }}>
                <Text strong>Functional - </Text>
                Critical:{' '}
                <Text code>{data.criticalFunctionalIncidentsCount}</Text> |
                Warning:{' '}
                <Text code>{data.warningFunctionalIncidentsCount}</Text> | Info:{' '}
                <Text code>{data.infoFunctionalIncidentsCount}</Text>
            </div>
        </>
    )
}

export interface DetectProjectCardProps {
    itemSummary: DetectGroupItemSummary
    ignoreClick?: boolean
    showItemStatus?: boolean
    hideUpdatedAt?: boolean
    onClickCallback?: Function
}

export const ProjectsCard = ({
    itemSummary,
    ignoreClick,
    showItemStatus,
    onClickCallback,
}: DetectProjectCardProps) => {
    const history = useHistory()

    const { Meta } = Card

    const [incident, setIncident] = useState<any | null>(null)

    const [health] = useMutation(getProjectHealth, {
        onSuccess: (resp) => {
            if (resp.severity != SeverityLevel.OK)
                setIncident({
                    level: resp.severity,
                    projectHealth: resp,
                })
        },
        onError: (error) => {
            console.log('Project Health Error Response: ', error)
        },
    })

    useEffect(() => {
        if (itemSummary) {
            health({
                itemExtId: itemSummary.itemExternalId,
                groupVersionId: itemSummary.groupVersionId,
            })
            setInterval(() => {
                health({
                    itemExtId: itemSummary.itemExternalId,
                    groupVersionId: itemSummary.groupVersionId,
                })
            }, 60000)
        }
    }, [itemSummary])

    const handleOnclick = () => {
        if (ignoreClick) return
        if (onClickCallback) {
            onClickCallback(
                itemSummary.itemExternalId,
                itemSummary.groupVersionId,
                itemSummary.itemStatus
            )
        } else {
            history.push({
                pathname: `/project/${itemSummary.itemExternalId}`,
                state: itemSummary,
            })
        }
    }

    return (
        <Card
            hoverable
            onClick={handleOnclick}
            className="detect-project-card"
            title={
                <Row
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div>
                        {itemSummary.itemName}
                        {incident !== null && (
                            <Tooltip
                                color={'white'}
                                overlayStyle={{ maxWidth: '500px' }}
                                title={
                                    <AlertTable data={incident.projectHealth} />
                                }
                            >
                                <Tag
                                    style={{
                                        borderRadius: '10px',
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}
                                    color={
                                        incident.level ===
                                        SeverityLevel.CRITICAL
                                            ? 'red'
                                            : incident.level ===
                                              SeverityLevel.WARNING
                                            ? 'yellow'
                                            : 'blue'
                                    }
                                    icon={
                                        incident.level ===
                                        SeverityLevel.CRITICAL ? (
                                            <ExclamationCircleOutlined />
                                        ) : incident.level ===
                                          SeverityLevel.WARNING ? (
                                            <WarningOutlined />
                                        ) : (
                                            <InfoCircleOutlined />
                                        )
                                    }
                                >
                                    {'Needs Attention: '}
                                    {incident.level}
                                </Tag>
                            </Tooltip>
                        )}
                    </div>
                    <CustomAvatarGroup
                        permissions={itemSummary.permissions}
                        itemExternalId={itemSummary.itemExternalId}
                        isGroupItem={true}
                    />
                </Row>
            }
        >
            <div className="detect-item-card__body">
                <Meta description={itemSummary.description} />
            </div>
            <Divider style={{ marginBottom: '5px' }} />
            <Tag
                style={{ borderRadius: '10px', marginLeft: '5px' }}
                color={'blue'}
            >
                {itemSummary.itemExternalId}
            </Tag>
            {showItemStatus && (
                <Tag
                    color="blue"
                    style={{ borderRadius: '10px', marginLeft: '5px' }}
                    icon={<InfoCircleOutlined />}
                >
                    {itemSummary.itemStatus === ItemStatus.APPROVED
                        ? 'Update'
                        : 'New'}
                </Tag>
            )}
        </Card>
    )
}
