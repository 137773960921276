import ApiClient from './client'

import { APIEndpoints } from './Endpoints'
import { EmptyRequestParams } from './RequestTypes/Common'

let qs = require('querystringify')

export const DataPlatformService = new ApiClient('/api/dps')

export const MLPlatformService = new ApiClient('/api/ml')

export const fetchAPIResponse = (
    url: APIEndpoints,
    params: EmptyRequestParams = {},
    method = 'POST',
    API: ApiClient = MLPlatformService
) => {
    return method==='POST' ||
    method==='PATCH' ||
    method==='PUT' ||
    method==='DELETE'
        ? API.createRequest({
            path: url,
            request: {
                method: method,
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                }
            },
            response: (r: any) => {
                return typeof r==='string' ? JSON.parse(r):r
            }
        })({})
        :API.createRequest({
            path: url,
            request: {
                method: 'GET'
            },
            response: (r: any) => {
                return typeof r==='string' ? JSON.parse(r):r
            }
        })({})
}

function isObjLiteral(_obj: any) {
    let _test = _obj
    return typeof _obj!=='object' || _obj===null
        ? false
        :(function() {
            while (true) {
                if (
                    Object.getPrototypeOf(
                        (_test = Object.getPrototypeOf(_test))
                    )===null
                ) {
                    break
                }
            }
            return Object.getPrototypeOf(_obj)===_test
        })()
}

/*export const fetchEndpoint = async (
  url: APIEndpoints,
  requestParams: EmptyRequestParams = {},
  method = 'POST',
  API: ApiClient = MLPlatformService
) => {
  if (method === 'GET') {
    Object.values(requestParams).forEach((value: string, index) => {
      url = url.replace(`{${index}}`, value) as any
    })
  }
  // console.log("URL:", url, JSON.stringify(requestParams))
  // console.log('REQUEST: ', requestParams, Array.from(params.entries()));
  const data = await fetchAPIResponse(url, requestParams, method, API)
  if (((data as unknown) as any).code === 403) {
    throw new Error(((data as unknown) as any).message)
  }
  // console.log('RESPONSE: ', data);
  return data
}
*/

export const fetchEndpoint = async (
    url: APIEndpoints,
    pathParams: any = {},
    queryParams: any = {},
    bodyParams: any = {},
    method = 'POST',
    API: ApiClient = MLPlatformService
) => {
    Object.keys(pathParams).forEach((key: string, index) => {
        url = url.replace(`{${key}}`, pathParams[key]) as any
    })
    let queryString = qs.stringify(queryParams, true)

    url = (url + queryString) as any

    const data = await fetchAPIResponse(url, bodyParams, method, API)
    if ((data as unknown as any).code===403) {
        throw new Error((data as unknown as any).message)
    }
    // console.log('RESPONSE: ', data);
    return data
}

export const nextKey = () => {
    return Math.ceil(Math.random() * 100000).toString()
}
