import React from 'react'
import { Button, Card, Divider, Typography } from 'antd'
import { useHistory } from 'react-router'
import './index.css'
import {
    DatabaseOutlined,
    IdcardOutlined,
    InfoCircleOutlined,
    UserOutlined
} from '@ant-design/icons'
import { DetectGroupItemSummary } from '../../../../lib/api/ResponseTypes/Common'

const { Text, Title } = Typography

export interface SelectCollectionCardProps {
    collectionSummary: DetectGroupItemSummary
    ignoreClick?: boolean
    showItemStatus?: boolean
    hideUpdatedAt?: boolean
    onClickCallback?: Function
    isSelected: boolean
    onCollectionSelect: (collectionId: string) => void
    onCollectionDeselect: (collectionId: string) => void
}

export const SelectCollectionCard = (
    {
        collectionSummary,
        showItemStatus,
        isSelected,
        onCollectionSelect,
        onCollectionDeselect
    }: SelectCollectionCardProps) => {
    const history = useHistory()

    return (
        <Card hoverable size='small' className='detect-item-card'>
            <div className='detect-item-card__body'>
                <div className='detect-item-card__title'>
                    <Title level={4} style={{ margin: 0 }}>
                        {collectionSummary.itemName}
                    </Title>
                    <Text type='secondary'>
                        Problem Area: {collectionSummary.problemArea}
                    </Text>
                </div>

                <div className='detect-item-card__summary'>
                    {!isSelected && (
                        <Button
                            style={{ width: '100%' }}
                            className='detect-button--primary'
                            onClick={() => onCollectionSelect(collectionSummary.itemExternalId)}
                        >
                            Select
                        </Button>
                    )}
                    {isSelected && (
                        <Button
                            style={{ width: '100%' }}
                            className='detect-button--selected'
                            onClick={() => onCollectionDeselect(collectionSummary.itemExternalId)}
                        >
                            Selected ✔
                        </Button>
                    )}
                </div>
            </div>
            <Divider style={{ margin: '0px 0px 5px 0px' }} />
            <div className='detect-item-card__footer'>
				<span>
					<span className='detect-card-footer__key'>
						<IdcardOutlined /> ID:
					</span>
					<span className='detect-card-footer__value'>
						{' '}
                        {collectionSummary.itemExternalId}
					</span>
				</span>
                <span>
					<span className='detect-card-footer__key'>
						<UserOutlined /> Created By:
					</span>
					<span className='detect-card-footer__value'>
						{' '}
                        {collectionSummary.createdBy}
					</span>
				</span>
                {showItemStatus && (
                    <span>
						<span className='detect-card-footer__key'>
							<InfoCircleOutlined /> Item Status:
						</span>
						<span className='detect-card-footer__value'>
							{' '}
                            {collectionSummary.itemStatus}
						</span>
					</span>
                )}
            </div>
        </Card>
    )
}
